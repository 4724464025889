import React, { useContext, useState } from 'react';
import ProductContext from 'context/ProductContext';
import { CategoryFilterItem } from './CategoryFilterItem';
import { FiltersWrapper, FilterBtn, FilterIcon } from './style';
import { FaFilter } from 'react-icons/fa';

export function Filters({ clearFilters }) {
  const [activeFilter, setActiveFilter] = useState(false);
  const { collections, currentTranslate } = useContext(ProductContext);
  const excludedItems = [
    'Promoción',
    'El Salvador',
    'Honduras',
    'Guatemala',
    'Banners Internos',
    'Banners Celulares',
    'Página de inicio'
  ];
  const filters = collections.filter(function (e) {
    return this.indexOf(e.title) < 0;
  }, excludedItems);

  const changeFilterState = () => {
    setActiveFilter(!activeFilter);
  };

  return (
    <FiltersWrapper activeFilter={activeFilter} id="filters">
      <FilterIcon onClick={changeFilterState}>
        <FaFilter color="#322665" /> {currentTranslate.filters.title}
      </FilterIcon>
      <div className="filter-icon-div"></div>
      <div className="filter-icon-div"></div>
      {filters.map(collection => (
        <CategoryFilterItem
          key={collection.shopifyId}
          title={currentTranslate.filters[collection.title] || collection.title}
          id={collection.shopifyId}
        />
      ))}

      <FilterBtn onClick={clearFilters}>
        {currentTranslate.filters.btn}
      </FilterBtn>
    </FiltersWrapper>
  );
}
