import React, { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import ProductContext from 'context/ProductContext';
import { BannerWrapper, TextWrapper } from './style';
import { StyleLink } from '../StyleLink';
import { Button } from '../Button';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function iOS() {
  if (typeof window === 'undefined') {
    return false;
  }

  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function Banner({ id, interno }) {
  const { collections, currentTranslate, selectedShop } = useContext(ProductContext);
  let images = collections.filter(item => {
    return item.shopifyId === id;
  });

  return (
    <BannerWrapper interno={interno}>
      <Carousel autoPlay showThumbs={false} infiniteLoop={true}>
        {images[0].products.map((pic, index) => {
          let fluid = pic?.images[0]?.localFile?.childImageSharp.fluid;
          let src = iOS() ? fluid?.src : fluid?.srcWebp;
          return (
            <div key={index}>
              <img
                alt=""
                src={src}
              />
              <TextWrapper interno={interno}>
                {pic.title.length > 2 && (
                  <div className="legend">
                    {pic.title}
                    <span>{pic.description}</span>
                    <StyleLink to={selectedShop ? 'moduloRecargas' : '/recargas'}>
                      <Button>{currentTranslate.home.RechargeNow}</Button>
                    </StyleLink>
                  </div>
                )}
              </TextWrapper>
            </div>
          );
        })}
      </Carousel>
    </BannerWrapper>
  );
}

Banner.defaultProps = {
  interno: false,
  id: '',
};
