import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

export function ProductosPdf({ info, logo }) {
  Font.register({
    family: "Roboto",
    fonts: [
      {
        src:
          "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-medium-webfont.ttf",
      },
      {
        src:
          "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#FFFFFF",
      alignItems: "center",
      padding: 30,
      fontSize: 10,
      color: "#656363",
      //fontFamily: 'Roboto',
    },
    section: {
      textAlign: "left",
      width: "100%",
    },
    imageWrapper: {
      display: "flex",
      marginBottom: 20,
      alignItems: "center",
      width: "100%",
    },
    imageHeader: {
      width: 80,
      height: 80,
      margin: "0 auto",
    },
    header: {
      color: "#cb01d7",
      paddingTop: 30,
      paddingBottom: 10,
      fontSize: 22,
      fontWeight: "bold",
    },
    subTitle: {
      fontWeight: "bold",
      paddingBottom: 15,
      color: "black",
    },
    footerText: {
      padding: 5,
      paddingRight: 10,
      color: "#8423ed",
      textAlign: "right",
    },
    totalText: {
      padding: 10,
      textAlign: "right",
      fontSize: 14,
      color: "#cb01d7",
      backgroundColor: "white",
      fontWeight: "bold",
      marginBottom: 30,
    },
    infoContainer: {
      backgroundColor: "#f3f3f3",
      padding: "0 40px",
      width: "100%",
    },
    table: {
      display: "table",
      width: "100%",
      textAlign: "left",
      backgroundColor: "white",
      borderStyle: "solid",
      borderColor: "lightgray",
      borderWidth: 0,
      borderBottomWidth: 1,
    },
    tableHeader: {
      margin: "auto",
      flexDirection: "row",
      color: "black",
      borderStyle: "solid",
      borderColor: "lightgray",
      borderWidth: 0,
      borderBottomWidth: 2,
      padding: 4,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
      padding: 4,
    },
    tableCol: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 0,
    },
    tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
  });
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.imageWrapper}>
            <Image style={styles.imageHeader} src={logo} />
          </View>
          <View style={styles.infoContainer}>
            <Text style={styles.header}>Confirmación de pedido</Text>
            <Text>Código de pedido</Text>
            <Text style={styles.subTitle}>{info?.id}</Text>
            <Text>Nombre del Comprador</Text>
            <Text style={styles.subTitle}>
              {`${info?.customer?.first_name} ${info?.customer?.last_name} `}
            </Text>
            <Text>Email del Comprador</Text>
            <Text style={styles.subTitle}>{info?.customer?.email}</Text>
            <Text>Teléfono del Comprador</Text>
            <Text style={styles.subTitle}>{info?.customer?.phone}</Text>
            <Text>Beneficiario</Text>
            <Text
              style={styles.subTitle}
            >{`${info?.shipping_address?.first_name} ${info?.shipping_address?.last_name} `}</Text>
            <Text>Teléfono del Beneficiario</Text>
            <Text style={styles.subTitle}>{info?.shipping_address?.phone}</Text>
            <Text>Email del Beneficiario</Text>
            <Text style={styles.subTitle}>{info?.shipping_address?.email}</Text>
            <Text>Forma de Entrega</Text>
            <Text style={styles.subTitle}>--</Text>
            <Text>Direción Entrega</Text>
            <Text
              style={styles.subTitle}
            >{`${info?.shipping_address?.address1} ${info?.shipping_address?.address2}, ${info?.shipping_address?.city}, ${info?.shipping_address?.country}`}</Text>
          </View>
        </View>
        <View style={styles.infoContainer}>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Cant.</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Producto</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Precio</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Subtotal</Text>
              </View>
            </View>
            {info.line_items.map((value) => (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{value.quantity}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{value.title} </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>${value.price}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    ${(value.price * value.quantity).toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View style={styles.section}>
            <Text
              style={styles.footerText}
            >{`COSTO DE ENVÍO: $${info?.total_shipping_price_set?.presentment_money?.amount}`}</Text>
            <Text
              style={styles.footerText}
            >{`AHORRO TOTAL: $${info?.total_discounts}`}</Text>
            <Text
              style={styles.totalText}
            >{`TOTAL A PAGAR: $${info?.total_price}`}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
