import React, { useContext, useState } from 'react';
import ProductContext from 'context/ProductContext';
import { FirestoreCollection } from '@react-firebase/firestore';
import { ProductosPdf, ExcelGenerator } from 'components';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Filtros } from './Filtros';
import PrintIcon from '../../../images/PrintIcon.png';
import SendItIcon from '../../../images/logo2v.png';

import { TableTh, TableTd, HistorialProductosContainer, ImprimirContainer, FiltrosWrapper } from './style';

import { getDate } from '../../utils/dates';
import { ORDER_STATUS } from '../../Consts';
import { ColoredTableTd } from '../../AdminPanel/Inputs';
import { BtnSecondary } from '../../Button';
import { Loading } from '../../Loading';

export function HistorialProductos() {
  const { usuario, currentTranslate } = useContext(ProductContext);
  const [filter, setFilters] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let excelData = [];

  const printHistorial = () => {
    var tab = document.getElementById('printTable');
    var win = window.open('', '', 'height=700,width=700');
    win.document.write(tab.outerHTML);
    win.document.close();
    win.print();
  };
  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const filterByCountry = data => {
    if (filter.pais) {
      return (
        data?.billing_address?.country
          .toLocaleLowerCase()
          .indexOf(filter.pais.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByDays = data => {
    if (startDate && endDate) {
      let date = data?.created_at.toDate();
      date.setHours(0, 0, 0, 0);
      return date <= endDate && date >= startDate;
    }
    return true;
  };

  const headers = [
    { label: 'Código', key: 'id' },
    {
      label: 'País',
      key: 'billing_address.country',
    },
    {
      label: 'Destinatario',
      key: 'customer.first_name',
    },
    { label: 'Fecha de Ingreso', key: 'fecha' },
    { label: 'Cantidad de Productos', key: 'totalProducto' },
    { label: 'Total de Venta', key: 'total_price' },
    { label: 'Estado de Pago', key: 'estadoDePago' },
    { label: 'Estado de Entrega', key: 'estadoEntrega' },
  ];

  return (
    <FirestoreCollection
      path="/orders/"
      where={{
        field: 'shop_id',
        operator: '==',
        value: usuario.uid,
      }}
      // orderBy={{
      //   field: 'created_at',
      // }}
    >
      {data => {
        let filteredData = data?.value
          ?.filter(filterByCountry)
          ?.filter(filterByDays);

        // order by date
        if (filteredData) {
          filteredData.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
        }

        excelData = filteredData?.map(info => {
          return {
            ...info,
            fecha: getDate(info.created_at),
            totalProducto: info.line_items.length,
            estadoDePago: ORDER_STATUS[info.financial_status]?.message,
            estadoEntrega:
              ORDER_STATUS[info.fulfillment_status]?.message || 'No preparado',
          };
        });

        return data.isLoading ? (
          <Loading />
        ) : (
          <>
          <FiltrosWrapper>
            <Filtros
              setFilters={setFilters}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              printHistorial={printHistorial}
              name="Products"
              headers={headers}
              csvData={excelData}
            />

            <ImprimirContainer>
              {data?.value && (
                <ExcelGenerator
                  name={'Products'}
                  headers={headers}
                  csvData={excelData}
                  text={currentTranslate.history.excel}
                />
              )}
              <BtnSecondary onClick={printHistorial}>
                {currentTranslate.history.print}
              </BtnSecondary>
            </ImprimirContainer>
            </FiltrosWrapper>
            <HistorialProductosContainer>
              <table id="printTable">
                <tr>
                  <TableTh long={true}>
                    {' '}
                    {currentTranslate.history.code}
                  </TableTh>

                  <TableTh long={true}>
                    {' '}
                    {currentTranslate.history.country}
                  </TableTh>

                  <TableTh long={true}>
                    {' '}
                    {currentTranslate.history.Addressee}
                  </TableTh>
                  <TableTh>
                    {currentTranslate.history.dateOf}
                    <br></br> {currentTranslate.history.entry}
                  </TableTh>
                  <TableTh>
                    {currentTranslate.history.amountOf}
                    <br></br> {currentTranslate.history.products}
                  </TableTh>
                  <TableTh>
                    {currentTranslate.history.totalOf}
                    <br></br> {currentTranslate.history.sale}
                  </TableTh>
                  <TableTh>{currentTranslate.history.statusPay}</TableTh>
                  <TableTh>{currentTranslate.history.deliveryStatus}</TableTh>
                  <TableTh></TableTh>
                </tr>
                {filteredData?.map(value => (
                  <tr>
                    <TableTd>{value.id}</TableTd>

                    <TableTd>{`${value.billing_address.address1}, ${value.billing_address.country}`}</TableTd>

                    <TableTd>{`${value.customer.first_name} ${value.customer.last_name} `}</TableTd>
                    <TableTd>{getDate(value.created_at)}</TableTd>
                    <TableTd>{value.line_items.length}</TableTd>
                    <TableTd>${value.total_price}</TableTd>
                    <ColoredTableTd
                      color={ORDER_STATUS[value.financial_status]?.color}
                      border={true}
                    >
                      {currentTranslate.orderStatus[value.financial_status]}
                    </ColoredTableTd>
                    <ColoredTableTd
                      color={ORDER_STATUS[value.fulfillment_status]?.color}
                      border={true}
                    >
                      {currentTranslate.orderStatus[value.fulfillment_status] ||
                        currentTranslate.orderStatus.unfulfilled}
                    </ColoredTableTd>
                    <TableTd>
                      <PDFDownloadLink
                        document={
                          <ProductosPdf info={value} logo={SendItIcon} />
                        }
                        fileName={`Ticket-${getDate(value?.created_at)}`}
                      >
                        <img src={PrintIcon} />
                      </PDFDownloadLink>
                    </TableTd>
                  </tr>
                ))}
              </table>
            </HistorialProductosContainer>
          </>
        );
      }}
    </FirestoreCollection>
  );
}
