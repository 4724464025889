import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  padding: 30px;
  border-bottom: 1px solid #c4c4c4;
`;

export const InputContainer = styled.div`
  width: 200px;
  background: #f3f3f3;
  padding: 10px;
  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #656363;
    position: absolute;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 70%;
  margin-bottom: 92px;
`;

export const CustomForm = styled.form`
  height: 400px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
