import React, { useState, useContext } from 'react';
import ProductContext from '../../../../../../context/ProductContext';

import { useForm, Controller } from 'react-hook-form';
import { FaChevronLeft } from 'react-icons/fa';
import { createComission } from '../../../../Requets';
import {
  GobackBtn,
  SelectInputRecargas,
  FormInput,
  FormBtn,
  ErrorElement,
} from '../../../../Inputs';
import {
  Header,
  InputContainer,
  Footer,
  InputsContainer,
  CustomForm,
} from './style';
import { OPERATORS, COUNTRIES } from '../../../../../Consts';

export function NuevaOperadora({ display, id, comissionsCreated }) {
  const { selectedShop, setSelectedShop } = useContext(ProductContext);
  const [loading, setLoading] = useState(false);
  const { control, register, handleSubmit, errors } = useForm();

  const onSubmit = async data => {
    setLoading(true);
    let comissions = {
      uid: id,
      commissions: [
        {
          country_code: data.pais,
          operator: data.operador,
          commission: data.comision,
        },
        ...comissionsCreated,
      ],
    };

    await createComission(comissions);
    let tempData = selectedShop;
    tempData.commissions = comissions.commissions;
    setSelectedShop(tempData);
    setLoading(false);
  };

  return (
    <div>
      <Header>
        <GobackBtn onClick={() => display(false)}>
          <FaChevronLeft size="1.0em" />
          Volver
        </GobackBtn>
      </Header>
      <CustomForm onSubmit={handleSubmit(onSubmit)}>
        <InputsContainer>
          <InputContainer>
            <p>Selecionar País</p>
            <SelectInputRecargas name="pais" ref={register()}>
              {Object.keys(COUNTRIES).map(code => {
                return (
                  <option value={code}>{COUNTRIES[code]?.name}</option>
                )
              })}
            </SelectInputRecargas>
          </InputContainer>
          <InputContainer>
            <p>Asignar Nueva Operadora</p>
            <SelectInputRecargas name="operador" ref={register()}>
              {OPERATORS.map(op => (
                <option value={op}>{op}</option>
              ))}
            </SelectInputRecargas>
          </InputContainer>
          <div>
            <Controller
              as={
                <FormInput
                  width="223"
                  placeholder="Porcentaje de comisión"
                  type="number"
                />
              }
              name="comision"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
            />
            {errors.comision && <ErrorElement>*Campo Obligatorio</ErrorElement>}
          </div>
        </InputsContainer>
        <Footer>
          <FormBtn disabled={loading}>Crear Operadora</FormBtn>
        </Footer>
      </CustomForm>
    </div>
  );
}
