import React, { useContext } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import ProductContext from "../../../context/ProductContext";

export function Invoice({ info }) {
  const { usuario } = useContext(ProductContext);

  const getDate = (str) => {
    const dateObj = new Date(str);
    const opts = { hour: "2-digit", minute: "2-digit", hour12: false };
    const date = dateObj.toLocaleDateString("en-US");
    const time = dateObj.toLocaleTimeString([], opts);
    return `${date} ${time}`;
  };

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#FFFFFF",
      alignItems: "center",
    },
    section: {
      margin: 10,
      padding: 10,
      width: "70%",
    },
    sectionFlex: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      flexDirection: "row",
      paddingTop: 5,
      paddingBottom: 5,
    },
    sectionCenter: {
      textAlign: "center",
      paddingTop: 10,
      paddingBottom: 10,
    },
    sectionDivider: {
      textAlign: "center",
      paddingTop: 20,
      paddingBottom: 20,
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.sectionCenter}>{usuario?.displayName}</Text>
          <Text style={styles.sectionCenter}>
            {getDate(info?.createdAt?._seconds * 1000)}
          </Text>
          <Text style={styles.sectionDivider}>
            ------------------------------------------------------
          </Text>
          <Text style={styles.sectionCenter}>TICKET DE VENTA</Text>
          <Text style={styles.sectionDivider}>
            ------------------------------------------------------
          </Text>
          <Text style={styles.sectionCenter}>
            Recarga electrónica
            {" " + info?.product?.operator?.name}
          </Text>
          <Text style={styles.sectionCenter}>
            Total
            {" $" +
              parseFloat(info?.prices?.retail?.amount)?.toFixed(2) +
              " USD"}
          </Text>
          <View style={styles.sectionFlex}>
            <Text>PROVEEDOR:</Text>
            <Text> {info?.product?.operator?.name}</Text>
          </View>
          <View style={styles.sectionFlex}>
            <Text>TELÉFONO A RECARGAR:</Text>
            <Text>{info?.credit_party_identifier?.mobile_number}</Text>
          </View>
          <View style={styles.sectionFlex}>
            <Text>TRANSACCIÓN:</Text>
            <Text>0554513235454</Text>
          </View>
          <View style={styles.sectionFlex}>
            <Text>REFERENCIA SENDIT:</Text>
            <Text>{info?.id}</Text>
          </View>
          <View style={styles.sectionFlex}>
            <Text>PRODUCTO: </Text>
            <Text>{info?.product?.name}</Text>
          </View>
          <View style={styles.sectionFlex}>
            <Text>MONTO: </Text>
            <Text>
              {"$" + parseFloat(info?.prices?.retail?.amount)?.toFixed(2)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
