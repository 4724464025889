import styled from 'styled-components';

export const NumberInput = styled.input.attrs({ type: 'number' })`
  width: 171px;
  height: 57px;
  font-size: 14px;
  line-height: 16px;
  background: #f3f3f3;
  border-radius: 5px;
  padding: 15px;
  border: none;
  ::placeholder {
    font-weight: bold;
    color: #656363;
  }
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 171px 171px 171px 171px;
  grid-gap: 10px;
  @media (max-width: 1000px) {
    grid-template-columns: 171px 171px 171px;
  }
  @media (max-width: 800px) {
    grid-template-columns: 130px 130px;
    input,
    select,
    button,
    div {
      width: 130px;
    }
    .react-datepicker__input-container {
      input {
        width: 130px;
      }
    }
  }
`;

export const SelectInput = styled.select`
  width: 171px;
  height: 57px;
  font-size: 14px;
  line-height: 16px;
  background: #f3f3f3;
  border-radius: 5px;
  padding: 15px;
  border: none;
  font-weight: bold;
  color: #656363;
`;

export const BtnWrapper = styled.div`
  margin-top: 15px;
  display: flex;
`;
export const DatePickerWrapper = styled.div`
  input {
    width: 171px;
    height: 57px;
    font-size: 14px;
    line-height: 16px;
    background: #f3f3f3;
    border-radius: 5px;
    padding: 15px;
    border: none;
    font-weight: bold;
    color: #656363;
  }
`;
export const ImprimirContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  @media (max-width: 500px) {
    display: none;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  padding: 50px 0px 50px 0px;
  justify-content: space-between;
`;
