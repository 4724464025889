import React, { useState } from 'react';
import { Loading } from '../../../../Loading';
import { FirestoreCollection } from '@react-firebase/firestore';
import { useStaticQuery, graphql } from 'gatsby';
import { NuevaPlantilla } from './NuevaPlantilla';
import Img from 'gatsby-image';
import { MainTable, TableTh, TableTd, TableWrapper } from '../../../Inputs';
import { PlantillaWrapper, IconContainer } from './style';

export function Plantillas() {
  const [newUserView, setNewUserView] = useState(false);
  const [selectedComision, setSelectedComision] = useState({});
  const icons = useStaticQuery(
    graphql`
      {
        editIcon: file(relativePath: { eq: "editIcon.png" }) {
          childImageSharp {
            fixed(height: 27, width: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const editCommission = value => {
    setNewUserView(true);
    setSelectedComision(value);
  };

  return (
    <PlantillaWrapper>
      <FirestoreCollection path="/templates/">
        {data => {
          let mainData = data?.value;
          return data.isLoading ? (
            <Loading />
          ) : (
            <div>
              {newUserView && (
                <NuevaPlantilla
                  display={setNewUserView}
                  info={selectedComision}
                />
              )}
              {!newUserView && (
                <div>
                  <TableWrapper>
                    <MainTable>
                      <thead>
                        <tr>
                          <TableTh>Nombre</TableTh>
                          <TableTh>Editar</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        {mainData?.map(value => (
                          <tr key={value.name}>
                            <TableTd>{value.name}</TableTd>
                            <TableTd>
                              <IconContainer
                                onClick={() => editCommission(value)}
                              >
                                <Img
                                  fixed={icons.editIcon?.childImageSharp.fixed}
                                  loading="auto"
                                />
                              </IconContainer>
                            </TableTd>
                          </tr>
                        ))}
                      </tbody>
                    </MainTable>
                  </TableWrapper>
                </div>
              )}
            </div>
          );
        }}
      </FirestoreCollection>
    </PlantillaWrapper>
  );
}
