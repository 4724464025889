import styled from 'styled-components';

export const TimelineContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 50px;
`;

export const Step = styled.div`
  width: 71px;
  height: 71px;
  background: ${props =>
    props.status ? 'rgba(183, 63, 127, 0.7)' : '#e2513a'};
  font-size: 36px;
  text-align: center;
  color: #ffffff;
  border-radius: 50px;
  line-height: 60px;
  cursor: ${props => props.status ? 'normal' : 'pointer'};
  @media (max-width: 600px) {
    width: 36px;
    height: 36px;
    font-size: 15px;
    line-height: 32px;
  }
`;

export const Line = styled.div`
  width: 23%;
  height: 5px;
  background-color: ${props =>
    props.status ? 'rgba(183, 63, 127, 0.7)' : '#e2513a'};
`;
