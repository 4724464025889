import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { Tooltip } from "../../../Tooltip";
import { CSVLink } from "react-csv";
import { FirestoreCollection } from "@react-firebase/firestore";
import { Loading } from "../../../Loading";
import { sendSms } from "../../../Recargas/requests";
import {
  SelectInputRecargas,
  SearchRecargas,
  FormBtn,
  DatePickerWrapper,
  Divider,
  TableWrapper,
  MainTable,
  TableTh,
  TableTd,
  FormBtnPagination,
} from "../../Inputs";
import {
  InputContainer,
  InputsContainer,
  ContainerFlex,
  DividerContainer,
  VentaSaldoContainer,
  TooltipContent,
  ColoredTableTd,
} from "./style";
import "react-datepicker/dist/react-datepicker.css";
import { getDate } from "../../../utils/dates";
import { COUNTRIES, OPERATORS, TOPUP_STATUS } from "../../../Consts";
import { getShopCommissions } from "../../Requets";
import { isCartaCorp } from "../../../Recargas/utils";
import ProductContext from "../../../../context/ProductContext";

export function VentaDeSaldo() {
  const [filter, setFilters] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [commissions, setCommissions] = useState({});
  const { currentTranslate } = useContext(ProductContext);
  const [limit, setLimit] = useState(6000);

  let listOfProducts = [];
  let listOfShops = [];
  let listOfAmounts = [];
  let excelData = [];

  const headers = [
    { label: " Transacción Send It", key: "id" },
    {
      label: "Referencia",
      key: "external_id",
    },
    {
      label: "Operadora",
      key: "product.operator.name",
    },
    { label: "País", key: "product.operator.country.name" },
    { label: " Tipo de Paquete", key: "product.name" },
    { label: "porcentaje de comision", key: "comision" },
    { label: "monto", key: "monto" },
    { label: "Vendedor", key: "shop.name" },
    { label: "Fecha", key: "fecha" },
    {
      label: "Teléfono del Comprador",
      key: "sender.mobile_number",
    },
    { label: "Teléfono del Destinatario", key: "beneficiary.mobile_number" },
    { label: "Nombre del Destinatario", key: "beneficiary.first_name" },
    { label: "Estado", key: "status.message" },
  ];

  const { control, register, handleSubmit, errors } = useForm();

  useEffect(() => {
    getShopCommissions().then((resp) => {
      setCommissions(resp);
      return;
    });
  }, []);

  const getCommission = (shopId, operator) => {
    const { name, country } = operator;
    let obj = commissions[shopId]?.find(
      (c) => c.country_code === country.iso_code && name.includes(c.operator)
    );
    return obj?.commission;
  };

  const filterByDays = (data) => {
    if (startDate && endDate) {
      let date = data?.createdAt.toDate();
      date.setHours(0, 0, 0, 0);
      return date <= endDate && date >= startDate;
    }
    return true;
  };

  const filterByNumber = (data) => {
    if (filter.telefono) {
      let sender = data?.sender?.mobile_number || "";
      return (
        sender
          .toLocaleLowerCase()
          .indexOf(filter.telefono.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const setNewLimit = () => {
    setLimit(limit + 4000);
  };
  const filterByCountry = (data) => {
    if (filter.pais) {
      return (
        data?.product?.operator?.country.name
          .toLocaleLowerCase()
          .indexOf(filter.pais.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByOperador = (data) => {
    if (filter.operador) {
      return data?.product?.operator?.name.includes(filter.operador);
    }
    return true;
  };

  const filterByPaquete = (data) => {
    if (filter.paquetes) {
      if (filter.paquetes === "saldo") {
        if (
          isCartaCorp(
            data?.product?.operator?.id,
            data?.product?.operator?.country?.iso_code,
            data?.product?.operator?.name
          )
        ) {
          return data?.product?.type === "Airtime";
        } else {
          return data?.product?.name.includes("USD");
        }
      } else {
        if (
          isCartaCorp(
            data?.product?.operator?.id,
            data?.product?.operator?.country?.iso_code,
            data?.product?.operator?.name
          )
        ) {
          return data?.product?.type !== "Airtime";
        } else {
          return !data?.product?.name.includes("USD");
        }
      }
    }
    return true;
  };

  const filterByAmount = (data) => {
    if (filter.monto) {
      return data?.prices?.retail?.amount == filter.monto;
    }
    return true;
  };

  const filterByShops = (data) => {
    if (filter.tiendas) {
      return (
        data?.shop?.name
          ?.toLocaleLowerCase()
          .indexOf(filter.tiendas?.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByState = (data) => {
    if (filter.estado) {
      return (
        data?.status?.message
          .toLocaleLowerCase()
          .indexOf(filter.estado.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const onSubmit = (data) => {
    setFilters(data);
  };

  const senNewdSms = async (sender, number, shop) => {
    let completeMessage = `Gracias por tu compra en tienda ${shop} la recarga para el numero ${number} se realizo de forma exitosa.Gracias por utilizar el servicio de Sendit`;
    let body = {
      to: sender,
      body: completeMessage,
    };
    let result = await sendSms(body);
    if (result.toString().includes("Error")) {
      alert(
        `No pudo enviarse el sms para la recarga correspondiente al numero ${number}`
      );
    } else {
      alert("se ha enviado el sms");
    }
  };

  return (
    <>
      <FirestoreCollection
        path="/topUps/"
        //orderBy={{
        //field: "creation_date",
        //type: "desc",
        //}}
        limit={limit}
      >
        {(data) => {
          let mainData = data?.value
            ?.filter(filterByNumber)
            ?.filter(filterByCountry)
            ?.filter(filterByOperador)
            ?.filter(filterByPaquete)
            ?.filter(filterByAmount)
            ?.filter(filterByShops)
            ?.filter(filterByDays)
            ?.filter(filterByState);

          // order by date

          if (mainData) {
            mainData.sort(
              (a, b) => b.createdAt.toDate() - a.createdAt.toDate()
            );
          }

          listOfProducts = data?.value
            ?.map((value) => {
              if (
                isCartaCorp(
                  value?.product?.operator?.id,
                  value?.product?.operator?.country?.iso_code,
                  value?.product?.operator?.name
                )
              ) {
                return value.product?.type;
              } else {
                if (!value.product?.name.includes("USD")) {
                  return value.product?.name;
                }
              }
            })
            .filter((value) => value);

          excelData = mainData?.map((info) => {
            return {
              ...info,
              fecha: getDate(info?.createdAt?.toDate()),
              comision: commissions[info?.shop?.id]
                ? `${getCommission(info?.shop?.id, info?.product.operator)}%`
                : "n/a",
              monto: `$${info?.prices?.retail?.amount}`,
            };
          });

          listOfAmounts = data?.value
            ?.filter((value) => value?.prices?.retail?.amount)
            .map((value) => value?.prices?.retail?.amount);

          listOfShops = data?.value
            ?.filter((value) => value.shop?.name)
            .map((value) => value.shop?.name);

          listOfProducts = [...new Set(listOfProducts)];
          listOfShops = [...new Set(listOfShops)];
          listOfAmounts = [...new Set(listOfAmounts)];
          return data.isLoading ? (
            <Loading />
          ) : (
            <>
              <VentaSaldoContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <InputsContainer>
                    <InputContainer>
                      <p>Desde</p>
                      <DatePickerWrapper>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          isClearable
                        />
                      </DatePickerWrapper>
                    </InputContainer>
                    <InputContainer>
                      <p>Hasta</p>
                      <DatePickerWrapper>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          isClearable
                        />
                      </DatePickerWrapper>
                    </InputContainer>
                    <InputContainer>
                      <p>Paises</p>
                      <SelectInputRecargas name="pais" ref={register()}>
                        <option value="">[TODOS]</option>
                        {Object.keys(COUNTRIES).map((code) => {
                          return (
                            <option value={COUNTRIES[code]?.name}>
                              {COUNTRIES[code]?.name}
                            </option>
                          );
                        })}
                      </SelectInputRecargas>
                    </InputContainer>
                    <InputContainer>
                      <p>Operadoras</p>
                      <SelectInputRecargas name="operador" ref={register()}>
                        <option value="">[TODOS]</option>
                        {OPERATORS.map((op) => (
                          <option value={op}>{op}</option>
                        ))}
                      </SelectInputRecargas>
                    </InputContainer>
                    <InputContainer>
                      <p>Tipos de paquetes</p>
                      <SelectInputRecargas name="paquetes" ref={register()}>
                        <option value="">
                          {currentTranslate.history.productType}
                        </option>
                        <option value="saldo">
                          {currentTranslate.filterOptions.Airtime}
                        </option>
                        <option value="paquetes">
                          {currentTranslate.filterOptions.Bundle}
                        </option>
                      </SelectInputRecargas>
                    </InputContainer>
                    <InputContainer>
                      <p>Monto</p>
                      <SelectInputRecargas name="monto" ref={register()}>
                        <option value="">[TODOS]</option>
                        {listOfAmounts.map((value) => (
                          <option value={value}>${value}</option>
                        ))}
                      </SelectInputRecargas>
                    </InputContainer>
                    <InputContainer>
                      <p>Tiendas</p>
                      <SelectInputRecargas name="tiendas" ref={register()}>
                        <option value="">[TODOS]</option>
                        {listOfShops.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </SelectInputRecargas>
                    </InputContainer>
                  </InputsContainer>
                  <InputsContainer>
                    <ContainerFlex>
                      <Controller
                        as={
                          <SearchRecargas
                            placeholder="Télefono de Comprador"
                            type="number"
                            min="0"
                          />
                        }
                        name="telefono"
                        control={control}
                        defaultValue=""
                      />

                      <InputContainer>
                        <p>Estado de recarga</p>
                        <SelectInputRecargas name="estado" ref={register()}>
                          <option value="">[TODOS]</option>
                          {Object.keys(TOPUP_STATUS).map((status) => (
                            <option value={status}>
                              {TOPUP_STATUS[status].message}
                            </option>
                          ))}
                        </SelectInputRecargas>
                      </InputContainer>
                      <FormBtn height="57px">Filtrar</FormBtn>
                      {mainData.length !== 10000 && (
                        <FormBtnPagination height="57px" onClick={setNewLimit}>
                          {mainData?.length === limit
                            ? "Cargar Más"
                            : "Cargando"}
                          {console.log("mainData?.length", mainData?.length)}
                        </FormBtnPagination>
                      )}
                    </ContainerFlex>
                    {mainData && (
                      <FormBtn height="57px">
                        <CSVLink
                          data={excelData}
                          headers={headers}
                          filename={`Historial de Recargas- ${new Date().toLocaleDateString()}.csv`}
                        >
                          Descargar Excel
                        </CSVLink>
                      </FormBtn>
                    )}
                  </InputsContainer>
                </form>
                <DividerContainer>
                  <Divider width="90%" />
                </DividerContainer>
                <TableWrapper>
                  <MainTable>
                    <thead>
                      <tr>
                        <TableTh borderRight={true}>
                          Transacción <br />
                          Send It
                        </TableTh>
                        <TableTh borderRight={true}>
                          Referencia
                          <br />
                          Operadora
                        </TableTh>
                        <TableTh borderRight={true}>País</TableTh>
                        <TableTh borderRight={true}>Operadora</TableTh>
                        <TableTh borderRight={true}>
                          Tipo de <br />
                          Paquete
                        </TableTh>
                        <TableTh borderRight={true}>Monto</TableTh>
                        <TableTh borderRight={true}>Vendedor</TableTh>
                        <TableTh borderRight={true}>
                          Porcentaje
                          <br />
                          Comisión
                        </TableTh>
                        <TableTh borderRight={true}>Fecha</TableTh>

                        <TableTh borderRight={true}>
                          Telefono del
                          <br /> Comprador
                        </TableTh>
                        <TableTh borderRight={true}>
                          Telefono del
                          <br /> Destinatario
                        </TableTh>
                        <TableTh borderRight={true}>
                          Nombre del <br />
                          Destinatario
                        </TableTh>
                        <TableTh borderRight={true}>Estado</TableTh>
                        <TableTh>Reenviar SMS</TableTh>
                      </tr>
                    </thead>
                    <tbody>
                      {mainData?.map((value, index) => {
                        return (
                          <tr key={value.external_id}>
                            <TableTd borderRight={true}>
                              {data?.ids[index].substring(0, 8)}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {value.id || "--"}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {value?.product?.operator?.country.name || "--"}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {value?.product?.operator?.name || "--"}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {isCartaCorp(
                                value.product?.operator?.id,
                                value.product?.operator?.country?.iso_code,
                                value.product?.operator?.name
                              ) ? (
                                <>
                                  {value?.product?.type === "Airtime" ? (
                                    <>{value.product.name}</>
                                  ) : (
                                    <>{value.product.type}</>
                                  )}
                                </>
                              ) : (
                                <>{value.product?.name}</>
                              )}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {value?.prices?.retail?.amount ? (
                                <>${value?.prices?.retail?.amount}</>
                              ) : (
                                <>--</>
                              )}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {value.shop?.name ? value.shop.name : "n/a"}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {commissions[value?.shop?.id]
                                ? `${getCommission(
                                    value?.shop?.id,
                                    value?.product.operator
                                  )}%`
                                : "n/a"}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {getDate(value?.createdAt?.toDate())}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {value?.sender?.mobile_number || "--"}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {value?.beneficiary?.mobile_number || "--"}
                            </TableTd>
                            <TableTd borderRight={true}>
                              {value?.beneficiary?.first_name
                                ? value.beneficiary.first_name
                                : ""}
                              {value?.beneficiary?.last_name
                                ? value.beneficiary.last_name
                                : ""}
                            </TableTd>
                            <ColoredTableTd
                              borderRight={true}
                              color={
                                TOPUP_STATUS[
                                  value?.status?.message.toLocaleLowerCase()
                                ]?.color
                              }
                            >
                              {value?.errors ? (
                                <Tooltip
                                  content={
                                    <TooltipContent>
                                      <div>
                                        Código de error:{" "}
                                        {value?.errors[0]?.code}
                                      </div>
                                      <div>
                                        Mensaje: {value?.errors[0]?.message}
                                      </div>
                                    </TooltipContent>
                                  }
                                  direction="left"
                                >
                                  <span>
                                    <u>
                                      {
                                        TOPUP_STATUS[
                                          value?.status?.message.toLocaleLowerCase()
                                        ]?.message
                                      }
                                    </u>
                                  </span>
                                </Tooltip>
                              ) : (
                                <span>
                                  {
                                    TOPUP_STATUS[
                                      value?.status?.message.toLocaleLowerCase()
                                    ]?.message
                                  }
                                </span>
                              )}
                            </ColoredTableTd>
                            <TableTd>
                              <button
                                onClick={() =>
                                  senNewdSms(
                                    value?.sender?.mobile_number,
                                    value?.beneficiary?.mobile_number,
                                    value.shop?.name
                                  )
                                }
                              >
                                Reenviar
                              </button>
                            </TableTd>
                          </tr>
                        );
                      })}
                    </tbody>
                  </MainTable>
                </TableWrapper>
              </VentaSaldoContainer>
            </>
          );
        }}
      </FirestoreCollection>
    </>
  );
}
