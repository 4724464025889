import React, { useState, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { FirestoreCollection } from "@react-firebase/firestore";
import { Loading } from "../../../Loading";
import { getDate } from "../../../utils/dates";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import ProductContext from "context/ProductContext";

import {
  SelectInputRecargas,
  FormBtn,
  DatePickerWrapper,
  Divider,
  MainTable,
  TableTh,
  TableTd,
  TableWrapper,
  SearchRecargas,
} from "../../Inputs";
import {
  InputContainer,
  InputsContainer,
  ContainerFlex,
  DividerContainer,
  ColoredTableTd,
  CompraDeSaldoContainer,
} from "./style";
import "react-datepicker/dist/react-datepicker.css";
import { CREDIT_STATUS } from "../../../Consts/index";
import { BtnFiltro } from "../../../Button";

export function CompraDeSaldo() {
  const [filter, setFilters] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { control, register, handleSubmit, reset, errors } = useForm();

  const { usuario } = useContext(ProductContext);

  const onChangeCliente = (e) => {
    let clientes = { clientes: e.target.value };
    setFilters({ ...clientes });
  };

  const onChangeStatus = (e) => {
    let status = { status: e.target.value };
    setFilters({ ...status });
  };

  const onChangePayment = (e) => {
    let payment = { payment: e.target.value };
    setFilters({ ...payment });
  };

  const onChangeLocation = (e) => {
    let location = { location: e.target.value };
    setFilters({ ...location });
  };

  const filterByDays = (data) => {
    if (startDate && endDate) {
      let date = data?.created_at.toDate();
      date.setHours(0, 0, 0, 0);
      return date <= endDate && date >= startDate;
    }
    return true;
  };

  const filterByClients = (data) => {
    if (filter.clientes) {
      return (
        data?.shop?.name
          .toLocaleLowerCase()
          .indexOf(filter.clientes.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByStatus = (data) => {
    if (filter.status) {
      return (
        data?.payment?.status
          .toLocaleLowerCase()
          .indexOf(filter.status.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByPayment = (data) => {
    if (filter.payment) {
      return (
        data?.type
          .toLocaleLowerCase()
          .indexOf(filter.payment.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByLocation = (data) => {
    if (filter.location) {
      return (
        data?.shop?.location
          .toLocaleLowerCase()
          .indexOf(filter.location.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByAmount = (data) => {
    let mainValue = data.amount;

    if (filter.monto_maximo && filter.monto_minimo) {
      return (
        mainValue <= filter.monto_maximo && mainValue >= filter.monto_minimo
      );
    } else if (filter.monto_minimo) {
      return mainValue >= filter.monto_minimo;
    } else if (filter.monto_maximo) {
      return mainValue <= filter.monto_maximo;
    }
    return true;
  };

  const onSubmit = (data) => {
    const { monto_minimo, monto_maximo } = data;
    let newFilter = {
      ...filter,
      monto_minimo,
      monto_maximo,
    };
    setFilters(newFilter);
  };

  const headers = [
    {
      label: "Referencia",
      key: "payment.reference",
    },
    {
      label: "Tienda",
      key: "shop.name",
    },
    { label: "Fecha", key: "fecha" },
    { label: "Monto", key: "amount" },
    { label: "Ubicación", key: "shop.location" },
    { label: "Tipo", key: "type" },
    { label: "Banco", key: "payment.bank" },
    { label: "Descripción", key: "description" },
    { label: "Estado", key: "payment.status" },
  ];

  return (
    <CompraDeSaldoContainer>
      <FirestoreCollection
        path="/credits"
        // orderBy={{
        //   field: 'created_at',
        // }}
      >
        {(data) => {
          let mainData = data?.value
            ?.filter(filterByClients)
            ?.filter(filterByDays)
            ?.filter(filterByStatus)
            ?.filter(filterByPayment)
            ?.filter(filterByAmount)
            ?.filter(filterByLocation);

          // order by date
          if (mainData) {
            mainData.sort(
              (a, b) => b.created_at.toDate() - a.created_at.toDate()
            );
          }

          let excelData = mainData?.map((value) => {
            return { ...value, fecha: getDate(value?.created_at?.toDate()) };
          });

          let listOfShops = data?.value?.map((value) => {
            return value?.shop?.name;
          });

          let listOfLocations = data?.value?.map((value) => {
            return value?.shop?.location;
          });

          listOfShops = [...new Set(listOfShops)];
          listOfLocations = [...new Set(listOfLocations)];

          return data.isLoading ? (
            <Loading />
          ) : (
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputsContainer>
                  <InputContainer>
                    <p>Desde</p>
                    <DatePickerWrapper>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        isClearable
                      />
                    </DatePickerWrapper>
                  </InputContainer>
                  <InputContainer>
                    <p>Hasta</p>
                    <DatePickerWrapper>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        isClearable
                      />
                    </DatePickerWrapper>
                  </InputContainer>
                  <InputContainer>
                    <p>Tiendas</p>
                    <SelectInputRecargas
                      name="clientes"
                      onChange={(e) => onChangeCliente(e)}
                    >
                      <option value="">[TODOS]</option>
                      {listOfShops.map((value, index) => (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      ))}
                    </SelectInputRecargas>
                  </InputContainer>
                  <InputContainer>
                    <p>Estado</p>
                    <SelectInputRecargas
                      name="status"
                      onChange={(e) => onChangeStatus(e)}
                    >
                      <option value="">[TODOS]</option>
                      {Object.keys(CREDIT_STATUS).map((status) => (
                        <option value={status}>
                          {CREDIT_STATUS[status].message}
                        </option>
                      ))}
                    </SelectInputRecargas>
                  </InputContainer>
                  <InputContainer>
                    <p>Forma de pago</p>
                    <SelectInputRecargas
                      name="payment"
                      onChange={(e) => onChangePayment(e)}
                    >
                      <option value="">[TODOS]</option>
                      <option value="efectivo">Efectivo</option>
                      <option value="tdc">Tarjeta de Crédito</option>
                    </SelectInputRecargas>
                  </InputContainer>
                  <InputContainer>
                    <p>Ubicación</p>
                    <SelectInputRecargas
                      name="location"
                      onChange={(e) => onChangeLocation(e)}
                    >
                      <option value="">[TODOS]</option>
                      {listOfLocations.map((value, index) => (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      ))}
                    </SelectInputRecargas>
                  </InputContainer>
                </InputsContainer>
                <InputsContainer>
                  <ContainerFlex>
                    <Controller
                      as={
                        <SearchRecargas
                          placeholder="Monto mínimo"
                          type="number"
                        />
                      }
                      name="monto_minimo"
                      control={control}
                      defaultValue=""
                    />
                    <Controller
                      as={
                        <SearchRecargas
                          placeholder="Monto máximo"
                          type="number"
                        />
                      }
                      name="monto_maximo"
                      control={control}
                      defaultValue=""
                    />
                    <BtnFiltro>Filtrar</BtnFiltro>
                  </ContainerFlex>
                  {mainData && (
                    <FormBtn height="57px">
                      <CSVLink
                        data={excelData}
                        headers={headers}
                        filename={`Historial Compra de Saldo- ${new Date().toLocaleDateString()}.csv`}
                      >
                        Descargar Excel
                      </CSVLink>
                    </FormBtn>
                  )}
                </InputsContainer>
              </form>
              <DividerContainer>
                <Divider width="90%" />
              </DividerContainer>
              <TableWrapper>
                <MainTable>
                  <thead>
                    <tr>
                      <TableTh>Tienda</TableTh>
                      <TableTh>Fecha</TableTh>
                      <TableTh>Monto</TableTh>
                      <TableTh>Ubicación</TableTh>
                      <TableTh>Tipo</TableTh>
                      <TableTh>
                        Número de
                        <br />
                        Referencia
                      </TableTh>
                      <TableTh>Descripción</TableTh>
                      <TableTh>Estado</TableTh>
                    </tr>
                  </thead>
                  <tbody>
                    {mainData
                      ?.filter(filterByClients)
                      ?.filter(filterByDays)
                      .map((value) => (
                        <tr>
                          <TableTd width={"10%"}>{value?.shop?.name}</TableTd>
                          <TableTd width={"10%"}>
                            {getDate(value?.created_at?.toDate())}
                          </TableTd>
                          <TableTd width={"10%"}>${value?.amount}</TableTd>
                          <TableTd width={"10%"}>
                            {value?.shop?.location || "No especificado"}
                          </TableTd>
                          <TableTd width={"10%"}>{value?.type}</TableTd>
                          <TableTd width={"10%"}>
                            {value?.payment?.reference}
                          </TableTd>
                          <TableTd width={"10%"}>{value?.description}</TableTd>
                          <ColoredTableTd
                            color={CREDIT_STATUS[value?.payment?.status]?.color}
                            width={"10%"}
                          >
                            {CREDIT_STATUS[value?.payment?.status]?.message}
                          </ColoredTableTd>
                        </tr>
                      ))}
                  </tbody>
                </MainTable>
              </TableWrapper>
            </div>
          );
        }}
      </FirestoreCollection>
    </CompraDeSaldoContainer>
  );
}
