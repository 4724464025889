import styled from 'styled-components';

export const CrearTiendaWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  form {
    width: 489px;
    padding-top: 60px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
`;

export const GridContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Legend = styled.p`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 16px;
  color: ${props => (props.status ? '#7DE59A' : 'red')};
`;
