import React, { useState } from 'react';
import { TooltipWrapper, TooltipTip } from './style';

export function Tooltip({ delay, children, content, direction, table }) {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <TooltipWrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && (
        <TooltipTip direction={direction} table={table}>
          {content}
        </TooltipTip>
      )}
    </TooltipWrapper>
  );
}
