import React, { useContext, useEffect } from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import ProductContext from "context/ProductContext";
import {
  PresSelectionWrapper,
  SectionHeader,
  CountriesContainer,
  FlagContainer,
  BtnOmitir,
} from "./style";
import { setLocalStorage } from "../utils/storage";

export function PreHomePage() {
  const { countryList, setSelectCountry } = useContext(ProductContext);

  useEffect(() => {
    setSelectCountry("");
  }, []);

  const onChangeCountry = (value) => {
    setLocalStorage("country", value);
    setSelectCountry(value);
  };

  const dimiss = () => {
    setSelectCountry(countryList[0].node.shopifyId);
    setLocalStorage("country", countryList[0].node.shopifyId);
  };

  const data = useStaticQuery(
    graphql`
      {
        logo2v: file(relativePath: { eq: "logo2v.png" }) {
          childImageSharp {
            fixed(height: 108, width: 108) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        Guatemala: file(relativePath: { eq: "guatemalaflagv2.png" }) {
          childImageSharp {
            fixed(height: 166, width: 166) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        Honduras: file(relativePath: { eq: "hondurasflagv2.png" }) {
          childImageSharp {
            fixed(height: 166, width: 166) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        ElSalvador: file(relativePath: { eq: "elsalvadorflagv2.png" }) {
          childImageSharp {
            fixed(height: 166, width: 166) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );
  return (
    <PresSelectionWrapper>
      <BtnOmitir onClick={dimiss}>Omitir</BtnOmitir>
      <SectionHeader>
        <Img fixed={data.logo2v?.childImageSharp.fixed} loading="auto" />
        <p>
          Estás a punto de entrar a <span>Send It</span>, tu tienda on-line para
          enviar
          <br />
          artículos electrónicos a Centroamérica, escoge el país de destino:
        </p>
      </SectionHeader>
      <CountriesContainer>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          {countryList.map((pais) => (
            <FlagContainer onClick={() => onChangeCountry(pais.node.shopifyId)}>
              <Img
                fixed={
                  data?.[pais.node.title.replace(/\s/g, "")]?.childImageSharp
                    .fixed
                }
                loading="auto"
              />

              <p> {pais.node.title}</p>
            </FlagContainer>
          ))}
        </div>
      </CountriesContainer>
    </PresSelectionWrapper>
  );
}
