import styled from 'styled-components';

export const OperadorasWrapper = styled.div`
  width: 100%;
  form {
    height: calc(100vh - 168px);
  }
`;

export const BtnWrapper = styled.div`
  padding: 30px;
`;

export const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
  svg {
    height: 100%;
  }
`;

export const ComisionInput = styled.input`
  width: 118px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #322665;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  padding: 15px;
  color: #656363;
`;

export const EditContainer = styled.div`
  width: 46px;
  height: 40px;
  background: ${props => (props.check ? '#7DE59A' : '#e2513a')};
  border-radius: 10px;
  margin-left: -46px;
  padding: 7px 0px 0px 7px;
  align-items: baseline;
`;

export const ComisionContainer = styled.div`
  display: flex;
  justify-content: center;
`;
