import styled, { css } from 'styled-components';
import { StyleLink } from '../StyleLink';

const internoStyle = ({ interno }) => {
  if (interno) {
    return css`
      @media (max-width: 384px) {
        height: 100%;
        grid-template-rows: 100px auto;
      }
    `;
  }
};

export const ProductTitleWrapper = styled.div`
  border: 1px solid rgba(190, 66, 118, 0.5);
  display: grid;
  grid-template-rows: 178px 136px;
  overflow: hidden;
  justify-content: center;
  min-height: 360px;
  flex-direction: column;
  position: relative;
  padding: 20px;
  ${internoStyle}
  > ${StyleLink} {
    text-decoration: none;
    display: block;
    padding: 10px;
  }
  .gatsby-image-wrapper {
    img {
      object-fit: contain !important;
    }
  }

  &:hover {
    cursor: pointer;
    height: 100%;
    background: linear-gradient(0deg, #e2513a, #e2513a);
    mix-blend-mode: multiply;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    div {
      color: white;
    }
    img {
      filter: linear-gradient(0deg, #e2513a, #e2513a);
      mix-blend-mode: multiply;
    }
    #overlay-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 384px) {
    min-height: 174px;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 50%;
  bottom: 50%;
  width: 100%;
  z-index: 1;
  display: none;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  z-index: 1;
  align-content: center;
  @media (max-width: 500px) {
    button {
      display: none;
    }
  }
`;

export const Button = styled.button`
  width: 77px;
  height: 49px;
  font-weight: bold;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: none;
  font-size: 16px;
  text-align: center;
  color: #e2513a !important;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    border-color: #999;
    cursor: not-allowed;
    color: #999;
  }
`;

export const Title = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: #e2513a;
  @media (max-width: 384px) {
    font-size: 14px;
  }
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  width: 232px;
  height: 37px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 500px) {
    width: 100%;
    margin-top: 10px;
  }
  @media (max-width: 384px) {
    display: none;
  }
`;

export const Price = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  @media (max-width: 384px) {
    font-size: 20px;
  }
`;

export const InfoContainer = styled.div`
  margin-top: 20px;
  align-self: center;
  width: 232px;
  text-align: initial;
  padding: 0 10px;
  @media (max-width: 500px) {
    text-align: center;
    width: 100%;
  }
  @media (max-width: 384px) {
    margin-top: 0;
    padding: 0;
  }
`;
