import styled from 'styled-components';

export const CompraDeSaldoContainer = styled.div`
  height: calc(100vh - 177px);
  > div:first-child {
    height: 100%;
  }
`;

export const InputContainer = styled.div`
  width: 171px;
  background: #f3f3f3;
  padding: 10px;
  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #656363;
    margin-bottom: -5px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 60px 20px 60px;
`;

export const ContainerFlex = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
`;

export const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ColoredTableTd = styled.td`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  color: ${props => (props.color ? props.color : '#656363')};
  white-space: pre-line;
  padding: 10px;
  img {
    cursor: pointer;
  }
  span {
    font-weight: bold;
    text-transform: capitalize;
  }
  u {
    cursor: help;
  }
`;
