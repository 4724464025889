import styled from 'styled-components';

export const QuantityAdjusterWrapper = styled.div`
  display: flex;
  #quantity {
    width: 74px;
    height: 59px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const AdjusterButton = styled.div`
  cursor: pointer;
  width: 57px;
  height: 59px;
  background: #b3abff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: black;
`;
