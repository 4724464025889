import styled from 'styled-components';

export const SaldoContainer = styled.div`
  width: 264px;
  height: 124px;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  padding: 25px;
  text-align: center;
  color: #322665;
  background: #6fc7da;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const FormContainer = styled.div`
  width: 625px;
  height: 466;
  background-color: white;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  @media (max-width: 800px) {
    width: 100%;
    box-shadow: none;
    border: none;
  }
`;

export const FormHeader = styled.div`
  width: 625px;
  height: 55px;
  background: #6fc7da;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 26px;
  color: #322665;
  padding: 10px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const FormLabel = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: #322665;
`;

export const InputText = styled.input`
  width: ${props => (props.width ? props.width : '385px')};
  height: 51px;
  padding-left: 10px;
  background: #f3f3f3;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  color: #656363;
  border: none;
  margin-bottom: 10px;
  ::placeholder {
    color: #656363;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  padding: 36px;
`;

export const InputFlex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    button {
      align-self: center;
    }
  }
`;

export const ComprarSaldoFormContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 60px 0px 60px 0px;

  flex-wrap: wrap;
`;

export const CardInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FormInputContainer = styled.div`
  width: ${props => (props.width ? props.width : '385px')};

  .StripeElement {
    width: ${props => (props.width ? props.width : '100%')};
    background-color: #f3f3f3;
    padding-left: 10px;
    padding-top: 15px;
    height: 51px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 16px;
    color: #656363;
    border: none;
    margin-bottom: 10px;
    cursor: text;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
`;