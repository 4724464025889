import styled, { css } from 'styled-components';

const displayAllMenu = ({ allMenu }) => {
  if (allMenu) {
    return css`
      max-width: 167px !important;
      transition: max-width 0.25s ease-in !important;
    `;
  }
};

export const MenuWrapper = styled.div`
  z-index: 3;
  min-height: 100vh;
  background: #5c1565;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.15);
  position: absolute;
  margin-top: -56px;
  max-width: 80px;
  transition: max-width 0.15s ease-out;
  ${displayAllMenu};
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.allMenu ? '0px' : '10px')};
  justify-content: center;
  flex-wrap: wrap;
  width: ${props => (props.allMenu ? '167px' : 'auto')};
`;

export const MenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoContainer = styled.div`
  padding: ${props =>
    props.allMenu ? '12px 0px 56px 10px;' : '12px 0px 56px 0px;'};
`;

export const Option = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  border-radius: ${props => (props.allMenu ? '0px' : '5px')};
  justify-content: center;
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #ffffff;

  &:hover {
    background-color: ${props =>
      props.selected ? 'none' : ' rgba(0, 0, 0, 0.14)'};
    cursor: pointer;
  }

  background: ${props =>
    props.selected ? '#B3ABFF' : props.subMenuSelected ? '#BA00D1' : 'none'};
`;
export const Divider = styled.div`
  border-top: 1px solid #ffffff;
  width: 80%;
  margin-left: 10px;
`;

export const ArrowContainer = styled.div`
  width: 29px;
  height: 31px;
  position: absolute;
  left: ${props => (props.allMenu ? '167px' : '74px')};
  transition: left 0.15s ease-out;
  background: #5c1565;
  top: 12px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  cursor: pointer;
`;

export const OptionContent = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.allMenu ? ' 54px 100px' : '54px')};
  .gatsby-image-wrapper {
    margin: auto;
  }
  p {
    margin: auto 5px;
    display: ${props => (props.allMenu ? 'block' : 'none')};
  }
`;
