import React, { useState, useContext } from 'react';
import ProductContext from 'context/ProductContext';
import { getDate } from '../../utils/dates';
import { FirestoreCollection } from '@react-firebase/firestore';
import { Loading } from '../../Loading';
import { Filtros } from './Filtros';
import { Invoice } from './Invoice';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PrintIcon from '../../../images/PrintIcon.png';
import PayIcon from '../../../images/credit-card.png';
import { navigate } from '@reach/router';
import {
  Table,
  TableTh,
  TableTd,
  BtnPagar,
  SaldoContainer,
  ContentContainer,
} from './style';
import { CREDIT_STATUS } from '../../Consts';

export function HistorialCredito() {
  const [filter, setFilters] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { usuario, setSelectedCredit, currentTranslate } = useContext(
    ProductContext
  );

  const headers = [
    { label: 'Fecha de pago', key: 'date' },
    {
      label: 'Monto',
      key: 'amount',
    },
    { label: 'Estado', key: 'payment.status' },
  ];

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const printHistorial = () => {
    var tab = document.getElementById('printTable');
    var win = window.open('', '', 'height=700,width=800');
    win.document.write(tab.outerHTML);
    win.document.close();
    win.print();
  };

  const filterByDays = data => {
    if (startDate && endDate) {
      let date = data?.created_at.toDate();
      date.setHours(0, 0, 0, 0);
      return date <= endDate && date >= startDate;
    }
    return true;
  };

  const filterByAmount = data => {
    let mainValue = data.amount;

    if (filter.monto_maximo && filter.monto_minimo) {
      return (
        mainValue <= filter.monto_maximo && mainValue >= filter.monto_minimo
      );
    } else if (filter.monto_minimo) {
      return mainValue >= filter.monto_minimo;
    } else if (filter.monto_maximo) {
      return mainValue <= filter.monto_maximo;
    }
    return true;
  };

  const filterByStatus = data => {
    if (filter.status) {
      return (
        data?.payment?.status
          .toLocaleLowerCase()
          .indexOf(filter.status.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByPaymentForm = data => {
    if (filter.paymentForm) {
      return (
        data?.type
          .toLocaleLowerCase()
          .indexOf(filter.paymentForm.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const handlePayment = credit => {
    setSelectedCredit(credit);
    navigate('/pagaSaldo');
  };

  return (
    <div>
      <FirestoreCollection
        path="/credits"
        where={{
          field: 'shop.id',
          operator: '==',
          value: usuario.uid,
        }}
        // orderBy={{
        //   field: 'created_at',
        // }}
      >
        {data => {
          let mainData = data?.value
            ?.filter(filterByDays)
            ?.filter(filterByAmount)
            ?.filter(filterByStatus)
            ?.filter(filterByPaymentForm);

          // order by date
          if (mainData) {
            mainData.sort(
              (a, b) => b.created_at.toDate() - a.created_at.toDate()
            );
          }

          return data.isLoading ? (
            <Loading />
          ) : (
            <div>
              <Filtros
                setFilters={setFilters}
                onChange={onChange}
                setStartDate={setStartDate}
                startDate={startDate}
                setEndDate={setEndDate}
                endDate={endDate}
                printHistorial={printHistorial}
                name="Reporte Historial de Crédito"
                headers={headers}
                csvData={mainData}
                printText={currentTranslate.history.print}
                excelText={currentTranslate.history.excel}
              />
              <ContentContainer>
                <SaldoContainer>
                  <p>{currentTranslate.history.actualBalance}</p>
                  <br />
                  <p>${usuario.balance?.current.toFixed(2)}</p>
                </SaldoContainer>
                <Table id="printTable">
                  <tr>
                    <TableTh long={true}>
                      {currentTranslate.history.assignmentDate}
                    </TableTh>
                    <TableTh long={true}>
                      {currentTranslate.history.paymentDate}
                    </TableTh>

                    <TableTh long={true}>
                      {currentTranslate.history.amount} <br></br>
                    </TableTh>

                    <TableTh long={true}>
                      {currentTranslate.history.status}
                    </TableTh>
                    <TableTh long={true}></TableTh>
                    <TableTh long={true}></TableTh>
                  </tr>
                  <tbody>
                    {mainData?.map((value, index) => (
                      <tr key={index}>
                        <TableTd>{getDate(value.created_at.toDate())}</TableTd>
                        <TableTd>
                          {value.payment.date ? (
                            getDate(value.payment.date?.toDate())
                          ) : (
                            <span>--</span>
                          )}
                        </TableTd>
                        <TableTd>${value.amount.toFixed(2)}</TableTd>
                        <TableTd
                          color={CREDIT_STATUS[value.payment.status]?.color}
                        >
                          {currentTranslate.creditStatus[value.payment.status]}
                        </TableTd>
                        <TableTd>
                          {value.payment.status === 'validating' ? (
                            <BtnPagar
                              onClick={() =>
                                handlePayment({
                                  id: data.ids[index],
                                  amount: value.amount,
                                  reference: value.payment.reference,
                                  mainTab: 2,
                                  mode: true,
                                })
                              }
                            >
                              <img src={PayIcon} alt="pay-icon" />
                              <span>Editar</span>
                            </BtnPagar>
                          ) : (
                            <BtnPagar
                              disabled={
                                value.payment.status === 'pending'
                                  ? false
                                  : true
                              }
                              onClick={() =>
                                handlePayment({
                                  id: data.ids[index],
                                  amount: value.amount,
                                  reference: '',
                                })
                              }
                            >
                              <img src={PayIcon} alt="pay-icon" />
                              <span>{currentTranslate.history.pay}</span>
                            </BtnPagar>
                          )}
                        </TableTd>
                        <TableTd>
                          <PDFDownloadLink
                            document={<Invoice info={value} />}
                            fileName={`Ticket-${getDate(
                              value.created_at?.toDate()
                            )}`}
                          >
                            <img src={PrintIcon} alt="print-icon" />
                          </PDFDownloadLink>
                        </TableTd>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ContentContainer>
            </div>
          );
        }}
      </FirestoreCollection>
    </div>
  );
}
