import styled from 'styled-components';

export const ExcelWrapper = styled.div`
  button {
    width: 171px;
    height: 57px;
    background: linear-gradient(269.91deg, #7a27f0 0%, #cb01d7 99.96%);
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    border: none;
    color: #ffffff;
    cursor: pointer;
  }
`;
