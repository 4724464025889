import styled from 'styled-components';

export const Resumen = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #322665;
`;

export const ResumenInfo = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #322665;
  padding: 10px 0px 10px 0px;
  .gatsby-image-wrapper {
    margin-right: 10px;
  }
`;
