import celulares from "../../images/cellcategorias.png";
import recarga from "../../images/recarga.png";
import tablets from "../../images/tabletcategorias.png";
import regalo from "../../images/regalo.png";
import accesorios from "../../images/accesorios.png";
import promos from "../../images/promos.png";
import celularesHover from "../../images/Cellhover.png";
import tabletsHover from "../../images/TabletHover.png";
import SLVZipCodes from "../../documents/SLV_zip_codes.pdf";
import HNDZipCodes from "../../documents/HND_zip_codes.pdf";

export const HOME_SECTIONS = [
  {
    id: "1",
    name: "TopUps",
    urlHandler: "",
    base: "recargas",
    image: recarga,
    hoverImage: null,
  },
  {
    id: "2",
    name: "CellPhones",
    urlHandler: "&c=Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzIzNTI5NzE0NTAxNw==",
    base: "productos",
    image: celulares,
    hoverImage: celularesHover,
  },
  {
    id: "3",
    name: "Tablets",
    urlHandler: "&c=Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzIzNTI5NzI0MzMyMQ==",
    base: "productos",
    image: tablets,
    hoverImage: tabletsHover,
  },
  {
    id: "4",
    name: "Gadgets",
    urlHandler: "&c=Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI3MTYzNzU3NzkxMw%3D%3D",
    base: "productos",
    image: accesorios,
    hoverImage: null,
  },
  {
    id: "5",
    name: "presents",
    urlHandler: "&c=Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI2Njg2NzA4MTQwMQ%3D%3D",
    base: "productos",
    image: regalo,
    hoverImage: null,
  },
  {
    id: "6",
    name: "promotions",
    urlHandler: "&c=Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzIzOTYyMDEyODk1Mw==",
    base: "productos",
    hoverImage: null,
  },
];
export const HOME_INFORMATION = [
  {
    id: "1",
    sections: [
      {
        translationTitleKey: "whatIsSenditTitle",
        translationDescKey: "whatIsSenditDesc",
      },
      {
        translationTitleKey: "whatSenditOffersTitle",
        translationDescKey: "whatSenditOffersDesc",
      },
    ],
  },
  {
    id: "2",
    sections: [
      {
        translationTitleKey: "howSenditWorksTitle",
        translationDescKey: "howSenditWorksDesc",
      },
      {
        translationTitleKey: "senditClientServiceTitle",
        translationDescKey: "senditClientServiceDesc",
      },
    ],
  },
];

export const COUNTRIES = {
  SLV: {
    name: "El Salvador",
    dialCode: 503,
    zipcodesFile: SLVZipCodes,
  },
  GTM: {
    name: "Guatemala",
    dialCode: 502,
  },
  HND: {
    name: "Honduras",
    dialCode: 504,
    zipcodesFile: HNDZipCodes,
  },
  COL: {
    name: "Colombia",
    dialCode: 57,
    zipcodesFile: null,
  },
  MEX: {
    name: "Mexico",
    dialCode: 52,
    zipcodesFile: null,
  },
  NIC: {
    name: "Nicaragua",
    dialCode: 505,
    zipcodesFile: null,
  },
  BOL: {
    name: "Bolivia",
    dialCode: 591,
    zipcodesFile: null,
  },
  ECU: {
    name: "Ecuador",
    dialCode: 593,
    zipcodesFile: null,
  },
};

export const OPERATORS = [
  "Tigo",
  "Digicel",
  "Movistar",
  "Claro",
  "Avantel",
  "Une",
  "Virgin Mobile",
  "ATT",
  "Telcel",
  "Unefon",
  "Viva",
  "Entel",
  "CNT",
  "Tuenti",
];

const RED_COLOR = "rgba(226, 81, 58, 1)";
const ORANGE_COLOR = "orange";
const GREEN_COLOR = "rgba(125, 229, 154, 1)";

export const CREDIT_STATUS = {
  pending: {
    message: "Acreditado",
    color: ORANGE_COLOR,
  },
  completed: {
    message: "Pagado",
    color: GREEN_COLOR,
  },
};

export const ORDER_STATUS = {
  pending: {
    message: "Pendiente",
    color: ORANGE_COLOR,
  },
  paid: {
    message: "Pagado",
    color: GREEN_COLOR,
  },
  cancelled: {
    message: "Cancelado",
    color: RED_COLOR,
  },
  failed: {
    message: "Fallido",
    color: RED_COLOR,
  },
  validating: {
    message: "Validando",
    color: ORANGE_COLOR,
  },
};

export const TOPUP_STATUS = {
  pending: {
    message: "Pendiente",
    color: ORANGE_COLOR,
  },
  completed: {
    message: "Completada",
    color: GREEN_COLOR,
  },
  cancelled: {
    message: "Cancelada",
    color: RED_COLOR,
  },
  failed: {
    message: "Fallida",
    color: RED_COLOR,
  },
  created: {
    message: "Creada",
    color: ORANGE_COLOR,
  },
  confirmed: {
    message: "Confirmada",
    color: GREEN_COLOR,
  },
  validating: {
    message: "Validando",
    color: ORANGE_COLOR,
  },
  REJECTED: {
    message: "Rechazada",
    color: RED_COLOR,
  },
  [`rejected-insufficient-balance`]: {
    message: "Rechazada Saldo Insuficiente",
    color: RED_COLOR,
  },
};
