import styled from 'styled-components';

export const FiltrosContainer = styled.div`
  height: 100px;
  background-color: white;
  display: flex;
  justify-content: center;
  form {
    margin: auto;
    button,
    input,
    select {
      margin: 0px 10px 0px 10px;
    }
  }
`;
