import React from 'react';
import { VentaDeSaldo } from './VentaDeSaldo';
import { CompraDeSaldo } from './CompraDeSaldo';

export function Historial({ subMenuSelected }) {
  return (
    <div>
      {subMenuSelected === 1 && <VentaDeSaldo />}
      {subMenuSelected === 2 && <CompraDeSaldo />}
    </div>
  );
}
