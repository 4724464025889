import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FaChevronLeft } from 'react-icons/fa';
import { updateEmployee } from '../../../../Requets';
import {
  GobackBtn,
  FormInput,
  FormBtn,
  ErrorElement,
  Message,
} from '../../../../Inputs';
import {
  Header,
  Content,
  Label,
  RowTemplate,
  Footer,
  FormContainer,
  Sumary,
  SumaryContent,
} from './style';

export function EditarVendedor({ details, setDetailView }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { control, register, handleSubmit, errors } = useForm();

  const onSubmit = async data => {
    setLoading(true);
    setMessage('');
    setError('');
    const { nombre, apellidos, password, passwordConfirm } = data;
    let requestBody = {
      uid: details.uid,
      email: details.email,
      password,
      name: `${nombre} ${apellidos}`,
      username: details.username,
      active: true,
    };
    if (password === passwordConfirm) {
      setError('');
      await updateEmployee(requestBody);
      setLoading(false);
      setMessage('Información actualizada exitosamente');
    } else {
      setError('Las contraseñas no coinciden');
      setLoading(false);
    }
  };

  return (
    <div>
      <Header>
        <GobackBtn onClick={() => setDetailView(false)}>
          <FaChevronLeft size="1.0em" />
          Volver
        </GobackBtn>
      </Header>
      <Content>
        <Sumary>
          <SumaryContent>
            <Label>Nombre de Usuario</Label>
            <div>
              <p>{details.username}</p>
            </div>
          </SumaryContent>
          <SumaryContent>
            <Label>Email</Label>
            <div>
              <p>{details.email}</p>
            </div>
          </SumaryContent>
        </Sumary>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer>
            <RowTemplate>
              <div>
                <Label> Nombres</Label>
                <Controller
                  as={<FormInput width="300" placeholder="Editar Nombres" />}
                  name="nombre"
                  control={control}
                  defaultValue={details.name}
                  rules={{
                    required: true,
                  }}
                />
                {errors.nombre && (
                  <ErrorElement>*Campo Obligatorio</ErrorElement>
                )}
              </div>
              <div>
                <Label>Contraseña</Label>
                <Controller
                  as={
                    <FormInput
                      width="300"
                      placeholder="contraseña"
                      type="password"
                    />
                  }
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                />
                {errors.password && (
                  <ErrorElement>*Campo Obligatorio</ErrorElement>
                )}
              </div>
            </RowTemplate>
            <RowTemplate>
              <div>
                <Label>Apellidos</Label>
                <Controller
                  as={<FormInput width="300" placeholder="Editar apellidos" />}
                  name="apellidos"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                />
                {errors.apellidos && (
                  <ErrorElement>*Campo Obligatorio</ErrorElement>
                )}
              </div>
              <div>
                <Label>Confirmar Contraseña</Label>
                <Controller
                  as={
                    <FormInput
                      width="300"
                      placeholder="confirmar contraseña"
                      type="password"
                    />
                  }
                  name="passwordConfirm"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                />
                {errors.passwordConfirm && (
                  <ErrorElement>*Campo Obligatorio</ErrorElement>
                )}
              </div>
            </RowTemplate>
          </FormContainer>
          {error && <ErrorElement>{error}</ErrorElement>}
          {message && <Message>{message}</Message>}
          <Footer>
            <FormBtn disabled={loading}>Guardar Cambios</FormBtn>
          </Footer>
        </form>
      </Content>
    </div>
  );
}
