import React, { useState, useEffect } from "react";
import WaIcon from "../../images/whatsapp.svg";
import { WaStyledButton } from "./style";

export function WaButton() {
  const number = "50360627014";

  const sendMessage = () => {
    window.open(`https://api.whatsapp.com/send?phone=${number}`, "_blank");
  };

  return (
    <WaStyledButton onClick={sendMessage}>
      <img src={WaIcon} />
    </WaStyledButton>
  );
}
