import styled from 'styled-components';

export const TabsContainer = styled.div`
  margin: 0 auto;
  padding: 35px 0px 0px 0px;
  display: flex;
  width: 250px;
  justify-content: space-evenly;
`;

export const TabLabel = styled.div`
  background: white;
  border-bottom: ${props => (props.active ? '1px solid red' : 'none')};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  padding: 10px;
  z-index: 2;
  width: 120px;
  cursor: pointer;
`;
