import React, { useContext, useState } from 'react';
import CartContext from 'context/CartContext';
import { QuantityAdjuster } from '../QuantityAdjuster';
import { RemoveItem } from '../RemoveItem';
import { Button } from '../Button';
import { navigate } from '@reach/router';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { COUNTRIES } from '../Consts';

import {
  CartItem,
  CartHeader,
  CartFooter,
  Footer,
  ImgContainer,
  ProductContainer,
  ProductDetails,
  Title,
  ProductTitle,
  ProductVariant,
  Price,
  QuantityContainer,
  PriceMobile,
  ZipCodeContainer,
  FooterBtnContainer,
} from './style';
import ProductContext from '../../context/ProductContext';
import { createCheckout } from './requests';

export function CartContent() {
  const icon = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "informativeIcon.PNG" }) {
        childImageSharp {
          fixed(width: 36, height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const { checkout, updateLineItem, updateLineItemAddres } = useContext(
    CartContext
  );
  const { usuario, countryList, selectCountry, currentTranslate } = useContext(
    ProductContext
  );
  const [envio, setEnvio] = useState(false);
  const [loading, setLoading] = useState(false);

  let currentCountry = countryList.filter(
    value => value.node.shopifyId === selectCountry
  );
  let countryCode = currentCountry[0]?.node?.description || '';

  const handleAdjunstQuantity = ({ quantity, variantId }) => {
    updateLineItem({ quantity, variantId });
  };

  const handleInputChange = event => {
    const target = event.target;
    setEnvio(target.checked);
    if (target.checked) {
      updateLineItemAddres();
    }
  };

  const handleCheckout = async () => {
    function getToken(url) {
      const tmp1 = url.split('/checkouts/');
      const tmp2 = tmp1[1];
      const tmp3 = tmp2.split('?');
      const token = tmp3[0];
      return token;
    }

    setLoading(true);
    if (usuario) {
      const { webUrl } = checkout;
      const data = {
        checkout_token: getToken(webUrl),
        uid: usuario.uid,
      };
      if (usuario.shop_id) {
        data['shop_id'] = usuario.shop_id;
      }
      await createCheckout(data);
    }

    setLoading(false);
    window.open(checkout.webUrl, '_blank');
  };

  return (
    <section>
      <Title>{currentTranslate.cart.title}</Title>
      {checkout?.lineItems && (
        <CartHeader>
          <div></div>
          <div>{currentTranslate.cart.product}</div>
          <div>{currentTranslate.cart.unitPrice}</div>
          <div>{currentTranslate.cart.amount}</div>
          <div>SUBTOTAL</div>
        </CartHeader>
      )}
      {checkout?.lineItems?.map(lineItem => (
        <CartItem key={lineItem.variant.id}>
          <Price>
            <RemoveItem lineItemId={lineItem.id} />
          </Price>
          <ProductContainer>
            <ImgContainer>
              <img src={lineItem.variant.image.src} />
            </ImgContainer>
            <ProductDetails>
              <ProductTitle>{lineItem.title}</ProductTitle>
              <ProductVariant>
                {currentTranslate.cart.variant}:<br></br>
                {lineItem.variant.title === 'Default Title'
                  ? currentTranslate.cart.notSelected
                  : lineItem.variant.title}
              </ProductVariant>
              <PriceMobile>
                {currentTranslate.cart.price}:<br></br> $
                {lineItem.variant.price}
              </PriceMobile>

              <QuantityContainer id="quantity-mobile">
                <QuantityAdjuster
                  item={lineItem}
                  onAdjust={handleAdjunstQuantity}
                />
              </QuantityContainer>
              <p id="subtotal-mobile">
                SUBTOTAL: <br></br>$
                {(lineItem.quantity * lineItem.variant.price).toFixed(2)}{' '}
              </p>
            </ProductDetails>
          </ProductContainer>

          <Price id="price-desktop">$ {lineItem.variant.price}</Price>
          <QuantityContainer id="quantity-desktop">
            <QuantityAdjuster
              item={lineItem}
              onAdjust={handleAdjunstQuantity}
            />
          </QuantityContainer>
          <Price id="subtotal-desktop">
            $ {(lineItem.quantity * lineItem.variant.price).toFixed(2)}{' '}
          </Price>
        </CartItem>
      ))}
      {checkout?.lineItems && (
        <CartFooter>
          <div>
            <strong>Total</strong>
          </div>
          <div>
            <span>${checkout?.totalPrice}</span>
          </div>
        </CartFooter>
      )}
      {!checkout?.lineItems && <h4>{currentTranslate.cart.empty}</h4>}
      <Footer>
        <div>
          <ZipCodeContainer>
            <Img fixed={icon.file.childImageSharp.fixed} loading="auto" />
            <div>
              {currentTranslate.cart.zip} <br />
              <a href={COUNTRIES[countryCode]?.zipcodesFile} target="_blank">
                {currentTranslate.cart.here}
              </a>
            </div>
          </ZipCodeContainer>
          <FooterBtnContainer>
            {!!checkout?.webUrl && (
              <Button onClick={handleCheckout} disabled={loading}>
                {currentTranslate.cart.pay}
              </Button>
            )}
            <Button onClick={() => navigate('/productos')}>
              {currentTranslate.cart.continue}
            </Button>
          </FooterBtnContainer>
        </div>
      </Footer>
    </section>
  );
}
