import React, { useState, useEffect } from "react";
import Image from "gatsby-image";
import ImageThumbails from "./ImageThumbails";
import { ImageGalleryWrapper, ImageWrapper } from "./styles";

export function ImageGallery({ selectedVariantImageId, images }) {
  const [activeImageThumbail, setActiveImageThumbail] = useState(
    images.find(({ id }) => id === selectedVariantImageId) || images[0]
  );
  const localFile = activeImageThumbail ? activeImageThumbail.localFile : null;

  useEffect(() => {
    setActiveImageThumbail(
      images.find(({ id }) => id === selectedVariantImageId) || images[0]
    );
  }, [selectedVariantImageId, images, setActiveImageThumbail]);

  const handleClick = (image) => {
    setActiveImageThumbail(image);
  };

  return (
    <ImageGalleryWrapper id="gallery-wrapper">
      {activeImageThumbail && localFile && (
        <ImageWrapper>
          <Image fluid={localFile.childImageSharp.fluid} loading="auto" />
        </ImageWrapper>
      )}
      <div>
        {images.map((image) => {
          return (
            <ImageThumbails
              key={image.id}
              image={image}
              isActive={activeImageThumbail.id === image.id}
              onClick={handleClick}
            />
          );
        })}
      </div>
    </ImageGalleryWrapper>
  );
}
