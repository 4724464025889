import React from 'react';
import {
  SubMenuWrapper,
  TitleContainer,
  OptionsContent,
  LiContainer,
  ListItem,
} from './style';

export function SubMenu({ title, user, menuOptions, activeTab, setActiveTab }) {
  return (
    <SubMenuWrapper>
      <TitleContainer>
        <p>{title}</p> <p>{user}</p>
      </TitleContainer>
      <OptionsContent>
        {menuOptions.map(value => (
          <LiContainer
            active={activeTab === value.id ? true : false}
            onClick={() => {
              setActiveTab(value.id);
            }}
          >
            <ListItem active={activeTab === value.id ? true : false}>
              <div>{value.title}</div>
            </ListItem>
          </LiContainer>
        ))}
      </OptionsContent>
    </SubMenuWrapper>
  );
}
