import styled from 'styled-components';

export const TiendaWrapper = styled.div`
  display: flex;
`;
export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  // max-height: 568px;
`;
