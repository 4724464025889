import React, { useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ExcelGenerator } from 'components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getDate } from '../../../utils/dates';

import {
  NumberInput,
  Form,
  SelectInput,
  DatePickerWrapper,
  ImprimirContainer,
  FilterWrapper,
} from './style';

import { BtnFiltro, BtnSecondary } from '../../../Button';
import { CREDIT_STATUS } from '../../../Consts';
import ProductContext from '../../../../context/ProductContext';

export function Filtros({
  setFilters,
  onChange,
  setEndDate,
  startDate,
  endDate,
  setStartDate,
  printHistorial,
  name,
  headers,
  csvData,
  printText,
  excelText,
}) {
  const { currentTranslate } = useContext(ProductContext);
  const { control, register, handleSubmit, reset, errors } = useForm();
  let cleanCsvData = csvData?.map(item => {
    var temp = Object.assign({}, item);
    temp.date = getDate(item.payment.date?.toDate());
    return temp;
  });

  const onSubmit = data => {
    setFilters(data);
  };
  return (
    <FilterWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DatePickerWrapper>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            startDate={startDate}
            endDate={endDate}
            selectsStart
            showDisabledMonthNavigation
            placeholderText={currentTranslate.history.dateFrom}
          />
        </DatePickerWrapper>

        <DatePickerWrapper>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            startDate={startDate}
            endDate={endDate}
            selectsEnd
            showDisabledMonthNavigation
            placeholderText={currentTranslate.history.dateTo}
          />
        </DatePickerWrapper>

        <Controller
          as={<NumberInput placeholder={currentTranslate.history.minimumAmount} />}
          name="monto_minimo"
          control={control}
          defaultValue=""
        />
        <Controller
          as={<NumberInput placeholder={currentTranslate.history.maximumAmount} />}
          name="monto_maximo"
          control={control}
          defaultValue=""
        />
        <SelectInput name="status" ref={register()}>
          <option value="">{currentTranslate.history.status}</option>
          {Object.keys(CREDIT_STATUS).map(status => (
            <option value={status}>{currentTranslate.creditStatus[status]}</option>
          ))}
        </SelectInput>
        <SelectInput name="paymentForm" ref={register()}>
          <option value="">{currentTranslate.history.paymentForm}</option>
          <option value="efectivo">{currentTranslate.filterOptions.cash}</option>
          <option value="tdc">{currentTranslate.filterOptions.tdc}</option>
        </SelectInput>
        <BtnFiltro>{currentTranslate.history.filter}</BtnFiltro>
      </Form>
      <ImprimirContainer>
        {csvData && (
          <ExcelGenerator
            name={name}
            headers={headers}
            csvData={cleanCsvData}
            text={excelText}
          />
        )}
        <BtnSecondary onClick={printHistorial}>{printText}</BtnSecondary>
      </ImprimirContainer>
    </FilterWrapper>
  );
}
