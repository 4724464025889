import styled from "styled-components";

export const DropdownContent = styled.div`
  justify-content: center;
  height: 46px;
  display: grid;
  grid-template-columns: 25px 80px 25px;
  grid-template-rows: 40px;
  grid-gap: 9px;
  align-items: flex-end;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
  @media (max-width: 450px) {
    grid-gap: 5px;
    width: 60px;
    grid-template-columns: 25px 25px;
  }
`;

export const DropdownLegend = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  @media (max-width: 450px) {
    display: none !important;
  }
`;

export const DropdownUl = styled.div`
  list-style: none;
  background: #e2513a;
  padding: 0 10px;
  margin: 0;
`;

export const DropdownLi = styled.li`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  height: 60px;
  display: flex;
  justify-content: space-evenly;
  padding: 0 20px;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #ffffff;
  }
  p {
    margin-left: 10px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }
  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const DropdonwWrapper = styled.div`
  z-index: 5;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 25px;
  position: relative;
  @media (max-width: 800px) {
    padding: 0 20px !important;
  }
`;

export const DropdownList = styled.div`
  position: absolute;
  width: 100%;
  top: 60px;
  left: 0;
`;
