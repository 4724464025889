import React, { useState } from "react";
import { Sumary } from "./Sumary";
import { Search } from "./Search";
import { RecargasForm } from "./RecargasForm";
import { RecargasSumary } from "./RecargasSumary";
import { RecargasWrapper } from "./style";
export function RecargasBtoB() {
  const [inputs, setInputs] = useState([]);
  const [buyer, setBuyer] = useState("");

  const handleChange = (i, event) => {
    const values = [...inputs];
    values[i].value = event.target.value;
    setInputs(values);
  };

  return (
    <div>
      <RecargasWrapper>
        <Sumary />
        <Search setInputs={setInputs} setBuyer={setBuyer} />
        <RecargasForm setInputs={setInputs} inputs={inputs} buyer={buyer} />
      </RecargasWrapper>
      <RecargasSumary setInputs={setInputs} inputs={inputs} buyer={buyer} />
    </div>
  );
}
