import styled from 'styled-components';

export const ProductQuantityAdderWrapper = styled.div`
  > strong {
    display: block;
    margin-bottom: 10px;
  }
  > form {
    display: flex;
    flex-direction: row;
  }
  button:first-child {
    margin-bottom: 10px;
    margin-right: 20px;
  }
  @media (max-width: 500px) {
    button {
      width: 100% !important;
    }
  }
  @media (min-width: 501px) and (max-width: 768px) {
    > form {
      flex-direction: row !important;
    }

    button {
      margin-right: 25px;
    }
  }
`;
