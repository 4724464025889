import React, { useContext, useState } from 'react';
import ProductContext from '../../context/ProductContext';
import { ToastContainer } from './style';

export function Toast({ isOpen, setIsOpen, time }) {
  const { currentTranslate } = useContext(ProductContext)

  const ToastWithTime = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, time);
  };

  const mainToast = (
    <ToastContainer>
      <p>{currentTranslate.cart.addedToCart}</p>
      {ToastWithTime()}
    </ToastContainer>
  );

  return <>{isOpen && mainToast}</>;
}
