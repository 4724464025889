import React, { useContext } from 'react';
import firebase from '@firebase/app';
import { BtnFilled } from '../../Button';
import ProductContext from 'context/ProductContext';
import { navigate } from '@reach/router';
import { TabWrapper, Tab, TabTitle } from './style';

export function TabManager({ activeTab, setTab }) {
  const { permisos, currentTranslate } = useContext(ProductContext);
  const titles = [
    {
      id: 1,
      title: currentTranslate.tabManager.cart,
    },
    {
      id: 2,
      title: currentTranslate.tabManager.productHistory,
    },
    {
      id: 3,
      title: currentTranslate.tabManager.topUpsHistory,
    },
    {
      id: 4,
      title: currentTranslate.tabManager.creditHistory,
    },
  ];

  const logout = () => {
    firebase.auth().signOut();
    localStorage.clear();
  };

  const goToAdmin = () => {
    navigate('/admin');
  };

  return (
    <TabWrapper>
      <Tab>
        {titles.map(value => (
          <TabTitle
            active={activeTab === value.id ? true : false}
            onClick={() => {
              setTab(value.id);
            }}
          >
            {value.title}
          </TabTitle>
        ))}
        {permisos?.admin && (
          <BtnFilled onClick={goToAdmin}>Admin Panel</BtnFilled>
        )}
        <BtnFilled onClick={logout}>
          {currentTranslate.tabManager.close}
        </BtnFilled>
      </Tab>
    </TabWrapper>
  );
}
