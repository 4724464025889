import styled, { css } from 'styled-components';

const productoStyle = ({ producto }) => {
  if (producto) {
    return css`
      color: #b73f7f;
      text-decoration: underline;
    `;
  }
};

const statusStyle = ({ status }) => {
  if (status === 'exitosa') {
    return css`
      color: #7de59a;
    `;
  } else if (status === 'fallida') {
    return css`
      color: #e2513a;
    `;
  }
};

export const HistorialProductosContainer = styled.div`
  margin-bottom: 60px;
  min-width: 1000px;
`;

export const TableTh = styled.th`
  width: ${props => (props.long ? '200px' : '125px')};
  background: #f3f3f3;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  color: #656363;
  height: 61px;
  padding: 10px;
`;

export const TableTd = styled.td`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #c4c4c4;
  vertical-align: middle;
  height: 60px;
  color: #656363;
  white-space: pre-line;
  padding: 10px;
  img {
    cursor: pointer;
  }
  ${statusStyle}
  ${productoStyle}
`;

export const TableBtn = styled.button`
  width: 80px;
  height: 46px;
  background: linear-gradient(269.91deg, #7a27f0 0%, #cb01d7 99.96%);
  border-radius: 5px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

export const ImprimirContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
  padding: 50px 0px 50px 0px;
`;

export const FiltrosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
