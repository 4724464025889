import React, { useState, useEffect } from "react";
import { CheckBoxWrapper, CheckBox, CheckBoxLabel } from "./style";

export function Switch({
  id,
  color,
  width,
  marginLeft,
  value,
  setValue,
  customFunction,
  customFunctionActive,
  paramsCustomFunction,
  customFlag,
}) {
  const [checkValue, setCheckValue] = useState(value || false);

  useEffect(() => {
    if (customFlag) {
      setCheckValue(value);
    }
  }, [value]);

  const onChangeValue = () => {
    if (customFunctionActive) {
      customFunction(paramsCustomFunction);
      setCheckValue(!checkValue);
    } else {
      setCheckValue(!checkValue);
      setValue(!checkValue);
    }
  };
  return (
    <CheckBoxWrapper>
      <CheckBox
        id={id}
        type="checkbox"
        color={color}
        width={width}
        marginLeft={marginLeft}
        checked={checkValue}
        onChange={onChangeValue}
      />
      <CheckBoxLabel htmlFor={id} width={width} />
    </CheckBoxWrapper>
  );
}
