import React, { useContext } from 'react';
import CartContext from 'context/CartContext';
import { QuantityAdjuster } from '../QuantityAdjuster';
import { StyleLink } from '../StyleLink';
import { Button } from '@shopify/polaris';
import {
  CartTitle,
  CartSumaryContainer,
  CartItem,
  SubHeading,
  ImgContainer,
  DescriptionContainer,
  QuantityContainer,
  Footer,
} from './style';

export function CartSumaryContent() {
  const { checkout, updateLineItem } = useContext(CartContext);

  const handleAdjunstQuantity = ({ quantity, variantId }) => {
    updateLineItem({ quantity, variantId });
  };

  return (
    <CartSumaryContainer>
      <CartTitle>Your Cart</CartTitle>
      <hr id="divider"></hr>
      {checkout?.lineItems?.map(lineItem => (
        <CartItem key={lineItem.variant.id}>
          <div>
            <ImgContainer>
              <img src={lineItem.variant.image.src} />
            </ImgContainer>
          </div>

          <DescriptionContainer>
            <div>$ {lineItem.variant.price}</div>
            <SubHeading>{lineItem.title}</SubHeading>
            <SubHeading>
              {lineItem.variant.title === 'Default Title'
                ? ''
                : lineItem.variant.title}
            </SubHeading>
          </DescriptionContainer>
          <div>
            <QuantityContainer>
              <QuantityAdjuster
                item={lineItem}
                onAdjust={handleAdjunstQuantity}
              />
            </QuantityContainer>
          </div>
        </CartItem>
      ))}
      <Footer>
        <StyleLink to={'/cart'}>
          {/* <Button>Ver Carrito</Button> */}
        </StyleLink>
        <StyleLink to={'#'}>
          {/* <Button
            onClick={() => {
              window.location.href = checkout.webUrl;
            }}
          >
            Pagar
          </Button> */}
        </StyleLink>
      </Footer>
    </CartSumaryContainer>
  );
}
