import axios from "axios";

const url = axios.create({
  baseURL: "https://us-central1-sendit-shopify-app.cloudfunctions.net/topups/",
});

export const loginCartaCorp = () => {
  let result = url
    .post(`/cartacorp/login`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export const getOperadoras = (code) => {
  let result = url
    .get(`/dtone/operators?countryCode=${code}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export const getProductList = (code, operatorId) => {
  let result = url
    .get(`/dtone/products?countryCode=${code}&operatorId=${operatorId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export const lookup = (mobileNumber) => {
  let result = url
    .get(`/dtone/lookup/?mobileNumber=${mobileNumber}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export const transaction = (
  productId,
  sender,
  beneficiary,
  shop_email,
  total
) => {
  const body = {
    productId,
    sender,
    beneficiary,
    shop_email,
    total,
  };
  let result = url
    .post("/dtone/transactions", body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export const buyTime = (body) => {
  let result = url
    .post("/cartacorp/buyTime", body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

// send sms
export const sendSms = (body) => {
  let result = url
    .post("/sendSms", body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

//

export const getNumberRecord = (number) => {
  let result = url
    .get(`/numberRecord?number=${number}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};

export const getUsedCredit = (id) => {
  let result = url
    .get(`/usedCredit?id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

  return result;
};
