import styled, { css } from 'styled-components';

export const Input = styled.input.attrs({ type: 'number' })`
  outline: 0;
  background-color: white;
  border: none;
  border-bottom: solid 2px #322665;
  width: ${props => (props.short ? '183px;' : '446px')};
  font-size: 16px;
  color: #322665;
  padding-bottom: 5px;
  ::placeholder {
    color: #322665;
  }
`;

export const InputText = styled.input.attrs({ type: 'text' })`
  outline: 0;
  border: none;
  background-color: white;
  width: ${props => (props.short ? '183px;' : '446px')};
  font-size: 16px;
  color: #322665;
  ::placeholder {
    color: #322665;
  }
`;

export const EmailText = styled.input.attrs({ type: 'email' })`
  outline: 0;
  border-width: 0 0 2px;
  border-color: #322665;
  background-color: white;
  width: 437px;
  padding: 10px;
  font-size: 16px;
  color: #322665;
  ::placeholder {
    color: #322665;
  }
  margin-top: 35px;
  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const PasswordText = styled.input.attrs({ type: 'password' })`
  outline: 0;
  border-width: 0 0 2px;
  border-color: #322665;
  background-color: white;
  width: 437px;
  padding: 10px;
  font-size: 16px;
  color: #322665;
  ::placeholder {
    color: #322665;
  }
  margin-top: 35px;
  @media (max-width: 500px) {
    width: 80%;
  }
`;
