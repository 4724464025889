import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { BtnFiltro, BtnSecondary } from '../../../Button';
import DatePicker from 'react-datepicker';
import { ExcelGenerator } from 'components';
import { COUNTRIES } from '../../../Consts';
import {
  NumberInput,
  Form,
  SelectInput,
  DatePickerWrapper,
  ImprimirContainer,
  FilterWrapper,
} from '../../HistorialRecargas/Filtros/style';
import 'react-datepicker/dist/react-datepicker.css';
import ProductContext from '../../../../context/ProductContext';

export function Filtros({
  setFilters,
  endDate,
  startDate,
  setEndDate,
  setStartDate,
  printHistorial,
  name,
  headers,
  csvData,
}) {
  const { control, register, handleSubmit, reset, errors } = useForm();
  const { currentTranslate } = useContext(ProductContext);

  const onSubmit = data => {
    setFilters(data);
  };
  return (
    <FilterWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DatePickerWrapper>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            isClearable
            placeholderText={currentTranslate.history.dateFrom}
          />
        </DatePickerWrapper>
        <DatePickerWrapper>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            isClearable
            placeholderText={currentTranslate.history.dateTo}
          />
        </DatePickerWrapper>

        <SelectInput name="pais" ref={register()}>
          <option value="">{currentTranslate.history.countries}</option>
          {Object.keys(COUNTRIES).map(code => {
            return (
              <option value={COUNTRIES[code]?.name}>
                {COUNTRIES[code]?.name}
              </option>
            );
          })}
        </SelectInput>
        <BtnFiltro>{currentTranslate.history.filter}</BtnFiltro>
      </Form>
    </FilterWrapper>
  );
}
