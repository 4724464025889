import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ExcelGenerator } from 'components';
import { MobileFilters } from './MobileFilters';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  NumberInput,
  Form,
  SelectInput,
  DatePickerWrapper,
  ImprimirContainer,
  FilterWrapper,
} from './style';

import { BtnFiltro, BtnSecondary } from '../../../Button';
import { OPERATORS, COUNTRIES, TOPUP_STATUS } from '../../../Consts';
import ProductContext from '../../../../context/ProductContext';

export function Filtros({
  setFilters,
  setEndDate,
  setStartDate,
  endDate,
  startDate,
  printHistorial,
  name,
  headers,
  csvData,
  excelText,
}) {
  const { control, register, handleSubmit, reset, errors } = useForm();
  const { currentTranslate } = useContext(ProductContext);

  let mainOptions = [];

  const getAmountList = () => {
    for (var amount = 1; amount <= 35; amount++) {
      if ((amount === 7 || amount % 5 == 0 || amount === 9) && amount <= 30) {
        mainOptions.push(<option value={amount}>{`$${amount}`}</option>);
      }
    }
    return mainOptions;
  };

  const onSubmit = data => {
    setFilters(data);
  };
  return (
    <FilterWrapper>
      <MobileFilters />
      <Form onSubmit={handleSubmit(onSubmit)} id="desktop-form">
        <DatePickerWrapper>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            isClearable
            placeholderText={currentTranslate.history.dateFrom}
          />
        </DatePickerWrapper>
        <DatePickerWrapper>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            isClearable
            placeholderText={currentTranslate.history.dateTo}
          />
        </DatePickerWrapper>

        <SelectInput name="pais" ref={register()}>
          <option value="">{currentTranslate.history.countries}</option>
          {Object.keys(COUNTRIES).map(code => {
            return <option value={code}>{COUNTRIES[code]?.name}</option>;
          })}
        </SelectInput>
        <SelectInput name="operador" ref={register()}>
          <option value="">{currentTranslate.history.carriers}</option>
          {OPERATORS.map(op => (
            <option value={op}>{op}</option>
          ))}
        </SelectInput>

        <SelectInput name="monto" ref={register()}>
          <option value="">{currentTranslate.history.amount}</option>
          {getAmountList()}
        </SelectInput>
        <SelectInput name="tipoProducto" ref={register()}>
          <option value="">{currentTranslate.history.productType}</option>
          <option value="saldo">{currentTranslate.filterOptions.Airtime}</option>
          <option value="paquetes">{currentTranslate.filterOptions.Bundle}</option>
        </SelectInput>
        <SelectInput name="status" ref={register()}>
          <option value="">{currentTranslate.history.status}</option>
          {Object.keys(TOPUP_STATUS).map(status => (
            <option value={status}>{currentTranslate.topupStatus[status]}</option>
          ))}
        </SelectInput>
        <BtnFiltro>{currentTranslate.history.filter}</BtnFiltro>
      </Form>
      <ImprimirContainer>
        {csvData && (
          <ExcelGenerator
            name={name}
            headers={headers}
            csvData={csvData}
            text={excelText}
          />
        )}

        <BtnSecondary onClick={printHistorial}>{currentTranslate.history.print}</BtnSecondary>
      </ImprimirContainer>
    </FilterWrapper>
  );
}
