import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 56px;
  background: #e2513a;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  p {
    line-height: 56px;
  }
`;
