import React, { useEffect, useContext, useState } from 'react';
import ProductContext from 'context/ProductContext';
import { Loading } from '../Loading';
import { navigate } from '@reach/router';

export function PrivateRoute({ component }) {
  const [loading, setLoading] = useState(true);
  const { usuario } = useContext(ProductContext);
  useEffect(() => {
    if (!usuario) {
      navigate('/login');
      setLoading(false);
    }
  }, [usuario]);

  return (
    <div>
      {usuario && loading ? (
        component
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </div>
  );
}
