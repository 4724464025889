import styled from 'styled-components';

export const CreditsListWrapper = styled.div`
  height: 100%;
`;

export const CompraSaldoWrapper = styled.div`
  width: 100%;
  > div:first-child {
    height: calc(100vh - 167px);
  }
`;

export const BtnWrapper = styled.div`
  padding: 30px;
`;

export const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconContainer = styled.div`
  cursor: pointer;
`;
