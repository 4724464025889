import React from "react";
import {
  TableTh,
  TableTd,
  MainTable,
  ActionsContainer,
  DeleteBtn,
  TooltipContent,
} from "./style";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Tooltip } from "../../Tooltip";

import { deleteShop } from "../Requets";
import { TableWrapper } from "../Inputs";

export function Table({
  headers,
  tableData,
  setInnerPage,
  innerPage,
  setSelectedInfo,
  fixed,
}) {
  const data = useStaticQuery(
    graphql`
      {
        trashIcon: file(relativePath: { eq: "trashIcon.png" }) {
          childImageSharp {
            fixed(height: 27, width: 23) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        editIcon: file(relativePath: { eq: "editIcon.png" }) {
          childImageSharp {
            fixed(height: 27, width: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const setEditShop = (value) => {
    setSelectedInfo(value);
    setInnerPage(!innerPage);
  };

  const specialFunctions = (key, value, selectedInfo) => {
    if (key === "action-menu") {
      return (
        <ActionsContainer>
          <div onClick={() => setEditShop(selectedInfo)}>
            <Img fixed={data.editIcon?.childImageSharp.fixed} loading="auto" />
          </div>

          <Tooltip
            content={
              <TooltipContent>
                <span>¿Seguro que desea eliminar esta tienda?</span>
                <br />
                <br />
                <DeleteBtn
                  onClick={() => {
                    deleteShop(selectedInfo.uid);
                  }}
                >
                  Eliminar
                </DeleteBtn>
              </TooltipContent>
            }
            direction="top"
          >
            <Img fixed={data.trashIcon?.childImageSharp.fixed} loading="auto" />
          </Tooltip>
        </ActionsContainer>
      );
    } else if (key === "current-balance") {
      if (fixed) {
        return parseInt(value).toFixed(2);
      } else {
        return parseFloat(value).toFixed(2);
      }
    } else {
      return value;
    }
  };

  return (
    <TableWrapper>
      <MainTable>
        <thead>
          <tr>
            {headers.map((value) => (
              <TableTh>{value.title}</TableTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((value) => {
            return (
              <tr>
                {headers.map((header) => (
                  <TableTd>
                    {specialFunctions(header.key, value[header.key], value)}
                  </TableTd>
                ))}
              </tr>
            );
          })}
        </tbody>
      </MainTable>
    </TableWrapper>
  );
}
