import styled from 'styled-components';

export const RecargasWrapper = styled.form`
  padding: 0px 30px 0px 30px;
  max-width: 1122px;
  margin: 0 auto;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
