import styled, { css } from 'styled-components';

export const ToastContainer = styled.div`
  width: 164px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: calc(30px * -2);
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    color: #50af69;
  }
`;
