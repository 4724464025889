import React, { useContext } from 'react';
import { BannerWrapper, ShopName } from './style';
import ProductContext from 'context/ProductContext';
export function Banner() {
  const { usuario } = useContext(ProductContext);
  return (
    <BannerWrapper>
      <div>
        <ShopName>{usuario.name}</ShopName>
      </div>
    </BannerWrapper>
  );
}
