import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { GobackBtn } from '../../../../Inputs';
import { getDate } from '../../../../../utils/dates';

import { Header, ContentContainer, Label, InfoContainer, Title } from './style';

export function DetalleCompra({ details, setDetailView }) {
  return (
    <div>
      <Header>
        <GobackBtn onClick={() => setDetailView(false)}>
          <FaChevronLeft size="1.0em" />
          Volver
        </GobackBtn>
      </Header>
      <ContentContainer>
        <InfoContainer>
          <Label>Monto</Label>
          <p>${details.amount}</p>
        </InfoContainer>
        <InfoContainer>
          <Label>Fecha de Creación</Label>
          <p>{getDate(details.created_at.toDate())}</p>
        </InfoContainer>
        <InfoContainer>
          <Label>Usuario que lo creo</Label>
          <p>{details?.created_by?.name}</p>
        </InfoContainer>
        <InfoContainer>
          <Label>Tipo</Label>
          <p>{details.type}</p>
        </InfoContainer>
      </ContentContainer>
      <Title>
        Detalle de Compra<div></div>
      </Title>
      <ContentContainer color="#ffffff">
        <InfoContainer>
          <Label>Banco</Label>
          <p>{details.payment.bank}</p>
        </InfoContainer>
        <InfoContainer>
          <Label>Número de Referencia</Label>
          <p>{details.payment.reference}</p>
        </InfoContainer>
        <InfoContainer>
          <Label>Fecha de Transferencia</Label>
          <p>{getDate(details.created_at.toDate())}</p>
        </InfoContainer>
      </ContentContainer>
    </div>
  );
}
