import styled from 'styled-components';

export const Btn = styled.button`
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #e2513a;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 100%;
`;
