import React, { useContext, useState } from 'react';
import { Input } from '../Input';
import { navigate, useLocation } from '@reach/router';
import queryString from 'query-string';
import { SearchForm } from './style';
import ProductContext from '../../context/ProductContext';

export function Search({ id, mobile }) {
  const [searchTerm, setSearchTerm] = useState('');
  const { currentTranslate } = useContext(ProductContext);

  const { search } = useLocation();
  const c = queryString.parse(search)?.c || '';
  const country = queryString.parse(search)?.country;

  const handleSubmit = e => {
    e.preventDefault();
    if (c) {
      navigate(
        `/productos?country=${country}&s=${encodeURIComponent(
          searchTerm
        )}&c=${encodeURIComponent(c)}`
      );
    } else {
      navigate(
        `/productos?country=${country}&s=${encodeURIComponent(searchTerm)}`
      );
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <SearchForm onSubmit={handleSubmit} mobile={mobile}>
      <Input
        placeholder={currentTranslate.menu.Search}
        value={searchTerm}
        onChange={e => setSearchTerm(e.currentTarget.value)}
        onKeyPress={handleKeyPress}
        id={id}
      />
    </SearchForm>
  );
}
