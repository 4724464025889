import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { updateTemplate } from '../../../../Requets';
import { FaChevronLeft } from 'react-icons/fa';
import { getCountry } from '../../../../../Recargas/utils';
import {
  Label,
  FormInput,
  FormBtn,
  ErrorElement,
  GobackBtn,
  Message,
} from '../../../../Inputs';
import {
  Header,
  Content,
  Footer,
  InputContainer,
  PlantillaWrapper,
  InputsWrapper
} from './style';

export function NuevaPlantilla({ display, info }) {
  const { control, register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMesage] = useState('');

  const onSubmit = async data => {
    let commissions = [];
    setLoading(true);
    for (const property in data) {
      commissions = [
        ...commissions,
        {
          commission: data[property],
          operator: property.split('-')[0],
          country_code: property.split('-')[1],
        },
      ];
    }
    let newTemplate = {
      id: info.id,
      commissions,
    };

    await updateTemplate(newTemplate);
    setLoading(false);
    setMesage('La plantilla ha sido actualizada');
  };

  return (
    <div>
      <Header>
        <GobackBtn onClick={() => display(false)}>
          <FaChevronLeft size="1.0em" />
          Volver
        </GobackBtn>
      </Header>
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputsWrapper>
            {info?.commissions.map(value => (
              <InputContainer>
                <Label>{`${value.operator} ${
                  getCountry[value.country_code]
                }`}</Label>
                <Controller
                  as={<FormInput width="300" placeholder="0.15" type="number" />}
                  name={`${value.operator}-${value.country_code}`}
                  control={control}
                  defaultValue={value?.commission || 0}
                  rules={{
                    required: true,
                  }}
                />
                {errors[`${value.operator}-${value.country_code}`] && (
                  <ErrorElement>*Campo Obligatorio</ErrorElement>
                )}
              </InputContainer>
            ))}
          </InputsWrapper>
          {error && <ErrorElement>{error}</ErrorElement>}
          {message && <Message>{message}</Message>}
          <Footer>
            <FormBtn disabled={loading}>Actualizar Plantilla</FormBtn>
          </Footer>
        </form>
      </Content>
    </div>
  );
}
