import styled, { css } from 'styled-components';

const inputStyle = () => {
  return css`
    width: 171px;
    height: 51px;
    background: #f3f3f3;
    border-radius: 5px;
    font-weight: bold;
    color: #656363;
    padding-left: 10px;
    border: none;
    font-size: 14px;
    ::placeholder {
      font-weight: bold;
      line-height: 16px;
      color: #656363;
      padding-left: 10px;
    }
  `;
};

export const InputNumber = styled.input.attrs({ type: 'number' })`
  ${inputStyle}
`;
export const InputText = styled.input`
  ${inputStyle}
`;

export const SelectWrapper = styled.select`
  ${inputStyle}
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  align-self: baseline;
  color: #322665;
  margin-bottom: 30px;
  @media (max-width: 700px) {
    margin-left: 10%;
  }
`;

export const FormContainer = styled.div`
  width: 1106px;
  min-height: 108px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  justify-content: space-around;
  padding: 25px 15px;
  > div:first-child {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 10px;
  }
  button {
    width: 190px;
    height: 51px;
  }
  input, select, button {
    margin-bottom: 10px;
  }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    width: 100%;
    height: 200px;
  }
  @media (max-width: 800px) {
    height: auto;
    > div {
      padding: 0 30px;
      flex-direction: row;
    }
    #pais, #celular, #operadora, button {
      width: calc(50% - 5px);
    }
    #operadora, #celular {
      margin-right: 10px;
    }
    #nombre {
      width: 100%;
    }
  }
`;

export const RecargasContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`;

export const LoadingContainer = styled.div`
  @media (max-width: 500px) {
    width: 107px;
  }
`;

export const ErrorContainer = styled.div`
  @media (max-width: 500px) {
    width: 300px;
  }
`;
