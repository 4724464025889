import styled from 'styled-components';

export const SubMenuWrapper = styled.div`
  width: 235px;
  min-height: 100vh;
  background: #f5f5f5;
  margin-left: -6px;
  margin-top: -56px;
  padding-top: 56px;
`;

export const TitleContainer = styled.div`
  padding: 29px 27px 0px 47px;
  > p {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
  }
`;

export const OptionsContent = styled.div`
  width: 100%;
  padding-top: 20px;
`;

export const LiContainer = styled.div`
  width: 235px;
  height: 52px;
  display: flex;
  text-align: center;
  line-height: 52px;
  font-weight: bold;
  font-size: 16px;
  align-items: center;
  margin-bottom: 21px;
  padding-top: 5px;
  padding-left: 46px;
  background: ${props =>
    props.active
      ? 'linear-gradient(269.91deg, #7A27F0 0%, #CB01D7 99.96%)'
      : 'none'};
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.14);
  }
`;

export const ListItem = styled.div`
  display: ${props => (props.active ? 'block' : 'list-item')};
  list-style-type: disc;
  width: 120px;
  color: ${props => (props.active ? '#ffffff' : '#7e25ef')};
  line-height: 20px;

  div {
    text-align: initial;
  }
`;
