import styled, { css } from 'styled-components';

const fullWidthStyles = ({ fullWidth }) => {
  if (fullWidth) {
    return css`
      display: block;
      width: 100%;
    `;
  }
};

export const Button = styled.button`
  cursor: pointer;
  color: #b73f7f;
  width: 190px;
  height: 60px;
  border: solid 3px #b73f7f;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  background-color: white;
  ${fullWidthStyles}

  &:hover:not(:disabled) {
    background: linear-gradient(180deg, #df4f46 0%, #be4276 100%), #be4276;
    border: 3px solid rgba(223, 79, 70, 0.5);
    color: white;
  }
  &:disabled {
    border-color: #999;
    cursor: not-allowed;
    color: #999;
  }
`;

export const BtnFiltro = styled.button`
  width: 171px;
  height: 57px;
  background: linear-gradient(269.91deg, #7a27f0 0%, #cb01d7 99.96%);
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border: none;
  color: #ffffff;
  cursor: pointer;
`;

export const BtnSecondary = styled.button`
  width: 171px;
  height: 57px;
  background: #f3f3f3;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  border: none;
  color: #322665;
  margin-top: 12px;
  cursor: pointer;
`;

export const BtnFilled = styled.button`
  width: 150px;
  height: 52px;
  background: #e2513a;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 5px;
`;
