import axios from 'axios';

const url = axios.create({
  baseURL: 'https://us-central1-sendit-shopify-app.cloudfunctions.net/admin/',
});

export const createCheckout = (data) => {
  let result = url
    .post('/checkouts/create', data)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });

  return result;
};
