import React, { useContext } from 'react';
import CartContext from 'context/CartContext';
import { FaShoppingCart } from 'react-icons/fa';
import { CartWrapper } from './style';

export function Cart() {
  const { checkout } = useContext(CartContext);
  let totalQuantity = 0;
  if (checkout) {
    checkout.lineItems.forEach(lineItem => {
      totalQuantity = totalQuantity + lineItem.quantity;
    });
  }
  return (
    <CartWrapper>
      <FaShoppingCart size="1.5em" />
      <div>{totalQuantity}</div>
    </CartWrapper>
  );
}
