import styled from 'styled-components';

export const FooterGrid = styled.section`
  min-height: 300px;
  background: #ea6926;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  position: relative;
  font-size: 16px;
  > div:first-child {
    position: absolute;
    bottom: 0;
    font-size: 12px;
    margin: 40px;
    color: white;
    div {
      margin-bottom: 20px;
    }
  }
  > div:last-child {
    position: absolute;
    bottom: 0;
    right: 0;

    font-size: 14px;
    margin: auto 40px;
    div {
      display: flex;
      flex-direction: column;
      a {
        margin-bottom: 20px;
        text-decoration: none;
        color: white;
        display: grid;
        grid-template-columns: 140px 1fr;
        align-items: center;
        text-align: right;
        img {
          height: 30px;
          width: 30px;
          margin-left: 20px;
        }
        &:hover {
          cursor: pointer;
        }
      }
      margin-bottom: 20px;
    }
  }
  @media (max-width: 600px) {
    justify-content: center;
	
    > div:first-child {
      margin: 10px;
      display: flex;
      flex-direction: column;
	  align-items: center;
      p {
        text-align: center;
      }
    }
    > div:last-child {
      position: inherit;
      order: 2;
      margin-bottom: 140px;
      div {
        margin-bottom: 60px;
        flex-direction: row;
        a {
          grid-template-columns: auto;
        }
      }

      p {
        display: none;
      }
    }

   
`;
