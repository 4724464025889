import React, { useState, useContext } from 'react';
import { Input } from '../Input';
import { Button } from '../Button';
import CartContext from 'context/CartContext';
import ProductContext from 'context/ProductContext';
import { ProductQuantityAdderWrapper } from './style';
import { createCheckout } from '../CartContent/requests'
export function ProductQuantityAdder({ variantId, available, messageFunc }) {
  const [quantity, setQuantity] = useState(1);
  const [availableInput, setAvailableInput] = useState(true);
  const { updateLineItem,checkout } = useContext(CartContext);
  const { currentTranslate,usuario } = useContext(ProductContext);

  const handleSubmit = e => {
    e.preventDefault();
    updateLineItem({ variantId, quantity: 1 });
    messageFunc();
  };

  const buyItem =async e => {
      function getToken(url) {
        const tmp1 = url.split('/checkouts/');
        const tmp2 = tmp1[1];
        const tmp3 = tmp2.split('?');
        const token = tmp3[0];
        return token;
      }
  
      if (usuario) {
        const { webUrl } = checkout;
        const data = {
          checkout_token: getToken(webUrl),
          uid: usuario.uid,
        };
        if (usuario.shop_id) {
          data['shop_id'] = usuario.shop_id;
        }
        await createCheckout(data);
      }
  
    setAvailableInput(false);
    updateLineItem({ variantId, quantity: 1, buy: true });
  };

  return (
    <ProductQuantityAdderWrapper>
      <form onSubmit={handleSubmit}>
        <Button onClick={buyItem}>{currentTranslate.buyBtn.btn}</Button>
        <Button  disabled={!availableInput} type="submit">
          {currentTranslate.buyBtn.title}
        </Button>
      </form>
    </ProductQuantityAdderWrapper>
  );
}
