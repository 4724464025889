import React, { useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ProductContext from 'context/ProductContext';

import {
  SaldoContainer,
  FormContainer,
  FormHeader,
  FormLabel,
  InputText,
  InputContainer,
  PagarSaldoFormContainer,
  FormInputContainer,
  Legend,
} from '../PagarSaldoForm/style';
import { ErrorElement } from '../Recargas/error';
import { Button } from '../Button';
import { updateSaldoStatus } from '../AdminPanel/Requets';
import { Message } from '../AdminPanel/Inputs';

export function PagarSaldoEfectivoForm() {
  const { selectedCredit } = useContext(ProductContext);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, errors } = useForm();

  const onSubmit = async data => {
    setLoading(true);
    let newPaymentObject = {
      payment: {
        status: 'validating',
        date: new Date(),
        reference: data.referencia,
      },
      id: selectedCredit.id,
    };

    let response = await updateSaldoStatus(newPaymentObject);
    if (response.error) {
      setError('no se pudo procesar el pago, intente nuevamente');
    } else {
      setMessage('se ha procesado el pago');
    }
  };
  return (
    <PagarSaldoFormContainer>
      <SaldoContainer>
        <p>Monto a Pagar</p>
        <p>${selectedCredit?.amount?.toFixed(2)}</p>
      </SaldoContainer>
      <FormContainer>
        <FormHeader>Pago en Efectivo</FormHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <FormInputContainer width={'300px'}>
              <FormLabel>N° Referencia Bancaria</FormLabel>
              <Controller
                as={
                  <InputText
                    placeholder="N° Referencia"
                    type="number"
                    width="100%"
                  />
                }
                name="referencia"
                id="referencia"
                defaultValue={selectedCredit.reference}
                control={control}
                rules={{
                  required: true,
                }}
              />
              {errors.referencia && (
                <ErrorElement>*Campo Obligatorio</ErrorElement>
              )}
            </FormInputContainer>
            <FormInputContainer width="100%">
              *El estado de pago permanecerá como "Validando" hasta que la transacción sea verificada.
              {/*<Legend>
              </Legend>*/}
            </FormInputContainer>
            <FormInputContainer>
              <Button type="submit" disabled={loading}>
                Continuar
              </Button>
            </FormInputContainer>
            {<Message>{message}</Message>}
            {<ErrorElement>{error}</ErrorElement>}
          </InputContainer>
        </form>
      </FormContainer>
    </PagarSaldoFormContainer>
  );
}
