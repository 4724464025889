import styled, { css } from 'styled-components';

const displayFilters = ({ activeFilter }) => {
  if (activeFilter) {
    return css`
      max-height: 500px !important;
      transition: max-height 0.25s ease-in !important;
    `;
  }
};

export const FiltersWrapper = styled.div`
  padding: 45px;
  background: rgba(223, 79, 70, 0.05);
  width: 376px;
  font-size: 16px;
  line-height: 19px;
  color: #322665;

  .filter-icon-div {
    display: none;
  }
  @media (max-width: 1000px) {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    padding: 18px 23px 25px 10px;
    .filter-icon-div {
      display: flex;
    }
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    ${displayFilters};
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    button {
      width: 225px;
      height: 38px;
    }
  }
`;

export const FilterBtn = styled.button`
  cursor: pointer;

  color: #b73f7f;
  width: 326px;
  height: 47px;
  border: solid 3px #b73f7f;
  margin-top: 30px;
  font-weight: bold;
  font-size: 16px;
  &:hover {
    background: linear-gradient(180deg, #df4f46 0%, #be4276 100%), #be4276;
    border: 3px solid rgba(223, 79, 70, 0.5);
    color: white;
  }
`;

export const FilterIcon = styled.p`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    cursor: pointer;
  }
`;
