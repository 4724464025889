import styled from 'styled-components';

export const FilterMobileItemWrapper = styled.div`
  display: none;
  margin-top: 10px;
  cursor: pointer;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  > div {
    margin-left: 10px;
  }
`;
