import styled, { css } from 'styled-components';

const RightTooltip = ({ direction }) => {
  if (direction === 'left') {
    return css`
      left: auto;
      right: calc(100% + 10px);
      top: 50%;
      transform: translateX(0) translateY(-50%);
    `;
  }
};

const TableMargin = ({ table }) => {
  if (table) {
    return css`
      top: calc(30px * -2);
    `;
  }
};

export const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const TooltipTip = styled.div`
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: white;
  background: black;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  top: calc(30px * -3);
  ${RightTooltip}
  ${TableMargin}
  @media(max-width: 600px) {
    margin-left: 45px;
    max-width: 100vw;
    font-size: 10px;
  }
`;
