import styled from 'styled-components';

export const ConentContainer = styled.div`
  padding-left: 80px;
  background-color: white;
  height: 100vh;
  overflow: hidden;
  margin-top: -56px;
  > div:first-child {
    padding-top: 56px;
    height: 100%;
  }
`;
