import styled from 'styled-components';
import { StyleLink } from '../StyleLink';

export const CartWrapper = styled(StyleLink).attrs(() => ({
  to: '/cart',
}))`
  padding: 0 25px !important;
  display: flex;
  color: black;
  text-decoration: none;
  color: white;
  height: 100%;
  border-right: solid white 1px;
  > svg {
    margin: auto 0;
  }

  > div:last-child {
    padding-left: 16px;
    margin: auto 0;
  }

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 800px) {
    border-left: solid white 1px;
    padding: 0 10px !important;
  }
`;
