import styled from 'styled-components';

export const EditarCuentaWrapper = styled.div`
  display: flex;
`;
export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  max-height: 568px;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    width: 489px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: #5c1565;
`;
