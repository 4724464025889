import React, { useContext, useState } from 'react';
import firebase from '@firebase/app';
import ProductContext from 'context/ProductContext';
import { Cart } from '../Cart';
import { Search } from '../Search';
import { Link } from 'gatsby';
import { Logo } from '../Logo';
import { CountryDrowdown } from '../CountryDrowdown';
import { LanguajeSelect } from '../LanguajeSelect';
import { FaAlignJustify } from 'react-icons/fa';
import { getLocalStorage } from '../utils/storage';

import {
  ProfileWrapper,
  HeaderWrapper,
  HeaderMobile,
  Divider,
  HeaderContainer,
  LogOutItem,
} from './style';

export function Header() {
  const [menuClass, setMenuClass] = useState('');

  const toggleHamburger = () => {
    menuClass ? setMenuClass('') : setMenuClass('showNav');
  };

  const logout = () => {
    firebase.auth().signOut();
    localStorage.clear();
  };

  const { countryList, usuario, currentTranslate } = useContext(ProductContext);
  let recargasUrl = usuario ? '/moduloRecargas' : '/recargas';
  const country =
    typeof window !== 'undefined'
      ? getLocalStorage('country')
      : countryList[0].node.shopifyId;

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <div>
          <FaAlignJustify
            size="1.5em"
            id="hamburger-menu"
            onClick={toggleHamburger}
          />
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div id="link">
          <Link to={'/'}>{currentTranslate.menu.home}</Link>
          <Link to={`/productos?country=${country}`}>
            {currentTranslate.menu.products}
          </Link>
          <Link to={recargasUrl}>{currentTranslate.menu.TopUps}</Link>
          {usuario ? (
            <ProfileWrapper>
              <Link to="/perfil">{usuario.name}</Link>
            </ProfileWrapper>
          ) : (
            <Link to="/login">{currentTranslate.menu.LogIn}</Link>
          )}
        </div>
        <nav class={`navbar ${menuClass}`}>
          {usuario ? (
            <ProfileWrapper>
              <Link to="/perfil">{usuario.name}</Link>
            </ProfileWrapper>
          ) : (
            <Link to="/login">{currentTranslate.menu.LogIn}</Link>
          )}
          <Link to={'/'}>Inicio</Link>
          <Link to={`/productos?country=${country}`}>Productos</Link>
          <Link to={recargasUrl}>Recargas</Link>
          {usuario && <a onClick={logout}>Log Out</a>}
        </nav>
        <Search id="desktop-search" mobile={false} />
        <Cart />
        <CountryDrowdown />
        <LanguajeSelect />
        <Divider />
      </HeaderWrapper>
      <HeaderMobile>
        <Search mobile={true} />
      </HeaderMobile>
    </HeaderContainer>
  );
}
