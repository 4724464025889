import React, { useState } from 'react';
import { SubMenu } from '../../SubMenu';
import { EditarTienda } from './EditarTienda';
import { Operadoras } from './Operadoras';
import { CompraSaldo } from './CompraSaldo';
import { Vendedores } from './Vendedores';
import { Plantillas } from './Plantillas';
import { TiendaWrapper, ContentWrapper } from './style';

export function InnerPages({ selectedInfo }) {
  const [activeTab, setActiveTab] = useState(1);
  let title = 'Editar Tienda: \n';
  let nombreTienda = selectedInfo?.name;
  let options = [
    { id: 1, title: 'Información General' },
    { id: 2, title: 'Operadoras para recargas' },
    { id: 3, title: 'Créditos' },
    { id: 4, title: 'Vendedores' },
    { id: 5, title: 'Plantillas' },
  ];
  return (
    <TiendaWrapper>
      <SubMenu
        title={title}
        user={nombreTienda}
        menuOptions={options}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <ContentWrapper>
        {activeTab === 1 && <EditarTienda info={selectedInfo} />}
        {activeTab === 2 && <Operadoras info={selectedInfo} />}
        {activeTab === 3 && <CompraSaldo />}
        {activeTab === 4 && <Vendedores />}
        {activeTab === 5 && <Plantillas />}
      </ContentWrapper>
    </TiendaWrapper>
  );
}
