import styled from 'styled-components';

export const PresSelectionWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  padding: 60px;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #b73f7f;
    padding: 30px 0px 0px 0px;
    text-align: justify;
  }
  span {
    font-weight: bold;
  }
`;

export const CountriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 70%;
  max-width: 800px;
  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    color: #b73f7f;
  }
`;

export const FlagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    font-weight: bold;
  }
`;

export const BtnOmitir = styled.button`
  border: 3px solid;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  width: 117px;
  height: 44px;
  color: #322665;
  border-image-source: linear-gradient(0deg, #b73f7f, #b73f7f),
    linear-gradient(
      96.38deg,
      rgba(223, 79, 70, 0.5) 9.7%,
      rgba(255, 255, 255, 0) 100%
    );
  position: absolute;
  top: 14px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
`;
