import React, { useState, useContext } from 'react';
import { SubMenu } from '../SubMenu';
import firebase from '@firebase/app';
import ProductContext from '../../../context/ProductContext';
import { useForm, Controller } from 'react-hook-form';
import { updateShop } from '../Requets';

import { FormInput, SubmitBtnSecondary, ErrorElement, Message } from '../Inputs';
import {
  EditarCuentaWrapper,
  ContentWrapper,
  FormContainer,
  Footer,
  Title,
} from './style';

export function EditarCuenta() {
  const { control, register, handleSubmit, errors, reset } = useForm();
  const { usuario } = useContext(ProductContext);
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  let title = 'Editar';
  let nombreTienda = 'Cuenta';
  let options = [{ id: 1, title: 'Cambiar Contraseña' }];

  const onSubmit = data => {
    setLoading(true);
    const { oldPass, newPass, newPassConfirm } = data;
    var user = firebase.auth().currentUser;
    if (newPass === newPassConfirm) {
      var credential = firebase.auth.EmailAuthProvider.credential(
        firebase.auth().currentUser.email,
        oldPass
      );

      user
        .reauthenticateWithCredential(credential)
        .then(async function () {
          let updatedInfo = {
            uid: usuario.uid,
            password: newPass,
          };
          setError('');
          await updateShop(updatedInfo);
          setLoading(false);
          reset();
          setMessage('Cambio de contraseña exitoso')
        })
        .catch(function (error) {
          setError(
            'Contraseña antigua inválida. Intenta de nuevo o contacta al equipo de Sendit a través de info.sendit.usa@gmail.com'
          );
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError('La confirmación de la contraseña no coincide');
    }
  };

  return (
    <EditarCuentaWrapper>
      <SubMenu
        title={title}
        user={nombreTienda}
        menuOptions={options}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <ContentWrapper>
        <FormContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Title>Cambiar constraseña</Title>
            <Controller
              as={
                <FormInput placeholder="Contraseña antigua" type="password" />
              }
              name="oldPass"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
            />
            {errors.oldPass && <ErrorElement>*Campo Obligatorio</ErrorElement>}
            <Controller
              as={<FormInput placeholder="Contraseña nueva" type="password" />}
              name="newPass"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
            />
            {errors.newPass && <ErrorElement>*Campo Obligatorio</ErrorElement>}
            <Controller
              as={
                <FormInput placeholder="Confirmar Contraseña" type="password" />
              }
              name="newPassConfirm"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
            />
            {errors.newPassConfirm && (
              <ErrorElement>*Campo Obligatorio</ErrorElement>
            )}
            {error && <ErrorElement>{error}</ErrorElement>}
            <Message>{message}</Message>
            <Footer>
              <SubmitBtnSecondary disabled={loading}>
                Guardar todos los cambios
              </SubmitBtnSecondary>
            </Footer>
          </form>
        </FormContainer>
      </ContentWrapper>
    </EditarCuentaWrapper>
  );
}
