import styled from 'styled-components';

export const SumaryContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media (max-width: 700px) {
    display: block;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 265px 265px 265px 265px;
  grid-template-rows: 230px;
  margin-top: -20px;
  z-index: 5;
  background: #dcf9ff;
  > div {
    position: relative;
  }
  > div:nth-child(even) {
    background: #6fc7da;
  }
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  @media (max-width: 1100px) {
    grid-template-columns: 25% 25% 25% 25%;
  }
  @media (max-width: 700px) {
    grid-template-columns: 50% 50%;
    margin-top: 0px;
    div:nth-child(3) {
      order: 1;
    }
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #322665;
  margin-top: 20px;
`;

export const SubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #322665;
`;

export const TitleToAction = styled.div`
  font-size: 16px;
  cursor: pointer;
  color: white;
  background: linear-gradient(269.91deg, #7A27F0 0%, #CB01D7 99.96%);
  position: absolute;
  width: 100%;
  height: 44px;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 0px 0px 5px 5px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  div:first-child {
    align-self: center;
  }
`;
