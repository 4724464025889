import React from "react";
import { FiltrosContainer } from "./style";
import { useForm, Controller } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { BasicBtn, SearchInput, SelectInput, SubmitBtn } from "../../Inputs";
import { USA_STATES } from "../InnerPages/const";
export function Filtros({ setFilters, setCreatedShop }) {
  const { control, register, handleSubmit, watch, errors } = useForm();
  const watchAllFields = watch();

  const checkKeyDown = (e) => {
    if (e.code === "Enter" || e.keyCode == 13) {
      setFilters(watchAllFields);
      e.preventDefault();
    }
  };

  const onSubmit = (data) => {
    setFilters(data);
  };
  return (
    <FiltrosContainer>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <BasicBtn onClick={() => setCreatedShop(true)}>
          <FaPlus size="1.0em" />
          {` Nueva Tienda`}
        </BasicBtn>
        <Controller
          as={<SearchInput placeholder="Búsqueda de tienda..." />}
          name="searchName"
          control={control}
          defaultValue=""
        />
        <SelectInput name="status" ref={register()}>
          <option value="">Estado</option>
          <option value={true}>activa</option>
          <option value={false}>inactiva</option>
        </SelectInput>
        <SelectInput name="location" ref={register()}>
          <option value="">Ubicación</option>
          {Object.keys(USA_STATES).map((state) => (
            <option value={state}>{state}</option>
          ))}
        </SelectInput>
        <SubmitBtn>Buscar</SubmitBtn>
      </form>
    </FiltrosContainer>
  );
}
