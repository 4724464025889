import React, { useContext } from 'react';
import ProductContext from 'context/ProductContext';
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from 'react-tabs';
import { FaPlus } from 'react-icons/fa';
import { TableContainer, TabContainer } from './style';

import 'react-tabs/style/react-tabs.css';

export function Tabs({ metafields }) {
  const { currentTranslate } = useContext(ProductContext);
  const getValue = key => {
    const field = metafields.find(metafield => {
      return metafield.key === key;
    });
    const value = field ? field.value : currentTranslate.productTableInfo.NotSpecified;
    return value;
  };

  const measures = getValue('Height');
  const tmp = measures ? measures.split('x') : [];
  const dimensions = {
    height: tmp[0] || null,
    width: tmp[1] || null,
    thickness: tmp[2] || null,
  };

  return (
    <TabContainer>
      <TabsComponent>
        <TabList>
          <Tab>
            <span>
              {currentTranslate.productTableInfo?.Specifications}{' '}
              <FaPlus size="1em" />
            </span>
          </Tab>
          <Tab>
            <span>
              {currentTranslate.productTableInfo?.Dimensions}{' '}
              <FaPlus size="1em" />
            </span>
          </Tab>
        </TabList>

        <TabPanel>
          <TableContainer>
            <table>
              <tbody>
                <tr>
                  <td> {currentTranslate.productTableInfo?.brand}:</td>
                  <td>{getValue('Brand')}</td>
                </tr>
                <tr>
                  <td>{currentTranslate.productTableInfo?.Model}:</td>
                  <td>{getValue('Model')}</td>
                </tr>
                <tr>
                  <td>{currentTranslate.productTableInfo?.Net}:</td>
                  <td>{getValue('Red')}</td>
                </tr>
                <tr>
                  <td>{currentTranslate.productTableInfo?.Connectivity}:</td>
                  <td>{getValue('Connectivity')}</td>
                </tr>
                <tr>
                  <td>{currentTranslate.productTableInfo?.InternalMemory}:</td>
                  <td>{getValue('Storage')}</td>
                </tr>
                <tr>
                  <td>{currentTranslate.productTableInfo?.OperatingSystem}:</td>
                  <td>{getValue('Operating System')}</td>
                </tr>
                <tr>
                  <td>{currentTranslate.productTableInfo?.BatteryType}:</td>
                  <td>{getValue('Battery')}</td>
                </tr>
                <tr>
                  <td>{currentTranslate.productTableInfo?.Accessories}:</td>
                  <td>{getValue('Accessories')}</td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
        </TabPanel>
        <TabPanel>
          <TableContainer>
            <table>
              <tbody>
                <tr>
                  <td>{currentTranslate.productTableInfo?.Height}:</td>
                  <td>{dimensions.height}</td>
                </tr>
                <tr>
                  <td>{currentTranslate.productTableInfo?.Width}:</td>
                  <td>{dimensions.width}</td>
                </tr>
                <tr>
                  <td>{currentTranslate.productTableInfo?.Depth}:</td>
                  <td>{dimensions.thickness}</td>
                </tr>
              </tbody>
            </table>
          </TableContainer>
        </TabPanel>
      </TabsComponent>
    </TabContainer>
  );
}
