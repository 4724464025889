import styled from 'styled-components';

export const Divider = styled.div`
  border-top: 1px solid #c4c4c4;
  width: 100%;
`;

export const TiendasWrapper = styled.div`
  height: calc(100vh - 156px);
`;

export const TableWrapper = styled.div`
  height: 100%;
`;
