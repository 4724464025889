import styled from 'styled-components';

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 563px;
  margin: 0 auto;
  margin-top: 50px;
  hr {
    border: 1px solid rgba(229, 229, 229, 0.7);
    width: 100%;
  }
  button {
    align-self: center;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const SuccessMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: black;
  margin-bottom: 30px;
  border-radius: 0px 0px 5px 5px;
  background-color: #7DE59A;
  padding: 20px;
`;

export const ResumenPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const ResumenPriceInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  padding: 10px 10px 10px 0px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  padding: 10px 10px 10px 0px;
  p:first-child {
    margin-left: 43%;
    font-weight: bold;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;
