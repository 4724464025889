import React, { useState } from 'react';
import { Banner } from './Banner';
import { TabManager } from './TabManager';
import { CartContent } from '../CartContent';
import { HistorialProductos } from './HistorialProductos';
import { HistorialRecargas } from './HistorialRecargas';
import { HistorialCredito } from './HistorialCredito';
import { ContentWrapper } from './style';

export function PerfilComponent() {
  const [tab, setTab] = useState(1);
  return (
    <div>
      <Banner />
      <TabManager activeTab={tab} setTab={setTab} />
      <ContentWrapper>
        {tab === 1 && <CartContent />}
        {tab === 2 && <HistorialProductos />}
        {tab === 3 && <HistorialRecargas />}
        {tab === 4 && <HistorialCredito />}
      </ContentWrapper>
    </div>
  );
}
