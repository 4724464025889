import styled from 'styled-components';

export const CartFooter = styled.div`
  border-bottom: 1px solid #322665;
  display: grid;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  grid-template-columns: 6fr 1fr;
  margin: 30px 0px 30px 0px;
  > div {
    padding: 0 8px;

    &:last-child {
      text-align: center;
      margin-bottom: 30px;
    }
    &:first-child {
      text-align: right;
    }
  }
  @media (max-width: 600px) {
    padding: 0 20px;
  }
`;

export const CartHeader = styled.div`
  display: grid;
  text-align: center;
  grid-template-columns: 30px 1fr 174px 1fr 196px;
  border-bottom: 1px solid #322665;
  font-weight: bold;
  div:nth-child(2) {
    text-align: left;
  }
  > div {
    padding: 8px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 30px 1fr 1fr 1fr;
    div:nth-child(3) {
      display: none;
    }
  }
  @media (max-width: 800px) {
    grid-template-columns: 30px 2fr 1fr;
    div:nth-child(4) {
      display: none;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: 30px 2fr;

    div:nth-child(5) {
      display: none;
    }
  }
`;

export const CartItem = styled.div`
  border-bottom: 1px solid #322665;
  display: grid;
  grid-template-columns: 30px 1fr 174px 1fr 196px;
  padding: 20px;
  > div {
    padding-left: 14px;

    &:first-child {
      > div:first-child {
        font-weight: bold;
      }
      > div:last-child {
        color: #999;
        margin-top: 4px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 1000px) {
    grid-template-columns: 30px 1fr 1fr 1fr;
    #price-desktop {
      display: none;
    }
  }
  @media (max-width: 800px) {
    grid-template-columns: 30px 2fr 1fr;
    #quantity-desktop {
      display: none;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: 30px 2fr;
    #subtotal-desktop {
      display: none;
    }
  }
`;

export const PriceMobile = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: #322665;
  display: none;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    display: flex;
  }
`;

export const Footer = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start !important;
    padding-bottom: 50px;
    padding: 20px;
    button {
      margin-bottom: 15px;
    }
  }
  @media (max-width: 600px) {
    > div {
      flex-direction: column;
      align-items: center !important;
      div:first-child {
        margin-bottom: 10px;
      }
    }
  }
  @media (max-width: 362px) {
    > div {
      flex-direction: column;
    }
    button {
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    > div {
      align-items: flex-end;
    }
  }
`;

export const ImgContainer = styled.div`
  width: 176px;
  height: 173px;
  img {
    width: 176px;
    height: 173px;
    border: 1px solid rgba(183, 63, 127, 0.5);
    box-sizing: border-box;
    object-fit: contain;
  }
  @media (max-width: 600px) {
    width: 117px;
    height: 187px;
    img {
      width: 117px;
      height: 187px;
    }
  }
`;

export const ProductContainer = styled.div`
  display: flex;
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
  #quantity-mobile {
    display: none;
  }
  #subtotal-mobile {
    display: none;
  }
  @media (max-width: 800px) {
    #quantity-mobile {
      display: flex;
    }
  }
  @media (max-width: 600px) {
    p:first-child {
      font-weight: bold;
      color: black;
      margin-bottom: 5px;
    }
    p {
      font-size: 16px;
      color: #322665;
      font-weight: 500;
    }
    #subtotal-mobile {
      display: flex;
      font-weight: bold;
      font-size: 16px;
      line-height: 33px;
      color: #322665;
      text-align: right;
      justify-content: flex-end;
    }
    #quantity-mobile {
      align-self: baseline;
      div {
        width: 100px;
        height: 40px;
      }
    }
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #322665;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 600px) {
    font-size: 24px;
    display: flex;
    justify-content: center;
  }
`;

export const ProductTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 10px;
`;

export const ProductVariant = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-top: 12px;
  margin-bottom: 10px;
`;

export const Price = styled.p`
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  color: #322665;
  align-self: center;
  display: flex;
  justify-content: center;
`;
export const QuantityContainer = styled.p`
  display: flex;
  justify-content: center;
  align-self: center;
`;

export const ZipCodeContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 220px;
  grid-gap: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #b3abff;
  a {
    text-decoration-line: underline;
    color: #b73f7f;
    cursor: pointer;
  }
`;

export const FooterBtnContainer = styled.div`
  width: 191px;
`;
