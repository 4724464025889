import React from 'react';
import { Checkbox } from 'components';
import { FilterMobileItemWrapper, FilterContainer } from './style';
export function MobileFilters() {
  let status = [
    {
      id: 1,
      title: 'Procesada',
    },
    {
      id: 2,
      title: 'En camino',
    },
    {
      id: 3,
      title: 'Completadaa',
    },
    {
      id: 4,
      title: 'Anulada',
    },
  ];
  return (
    <FilterMobileItemWrapper>
      {status.map(value => (
        <FilterContainer key={value.id}>
          <Checkbox />
          <div>{value.title}</div>
        </FilterContainer>
      ))}
    </FilterMobileItemWrapper>
  );
}
