import React from 'react';
import { ProductTitle } from '../ProductTitle';

import { ProductGridWrapper } from './style';

export function ProductsGrid({ products, interno }) {
  if (products.length === 1) {
    interno = true;
  }

  return (
    <ProductGridWrapper interno={interno}>
      {products.map(product => {
        let img = product.images[0] ? product.images[0].localFile : null;
        if (!img) return null;

        const imgFluid = img.childImageSharp.fluid;
        return (
          <>
            <ProductTitle
              interno={interno}
              handle={product.handle}
              minPrice={product.priceRange.minVariantPrice.amount}
              key={product.shopifyId}
              title={product.title}
              imgFluid={imgFluid}
              description={product.description}
              idCart={product.variants[0].shopifyId}
            />
          </>
        );
      })}
    </ProductGridWrapper>
  );
}
