import React, { useContext } from 'react';
import CartContext from 'context/CartContext';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import { Icon } from './style';

export function RemoveItem({ lineItemId }) {
  const { removeLineItem } = useContext(CartContext);
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "delete.png" }) {
        childImageSharp {
          fixed(width: 33, height: 34) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const handleDelete = () => {
    removeLineItem(lineItemId);
  };
  return (
    <Icon onClick={handleDelete}>
      <Img
        fixed={data.file.childImageSharp.fixed}
        loading="auto"
        id="btn-icon"
      />
    </Icon>
  );
}
