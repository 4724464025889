import axios from 'axios';

const url = axios.create({
  baseURL:
    'https://us-central1-sendit-shopify-app.cloudfunctions.net/admin/shops/',
});
const urlAdmin = axios.create({
  baseURL: 'https://us-central1-sendit-shopify-app.cloudfunctions.net/admin/',
});

export const createShop = body => {
  let result = url
    .post('/create', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });

  return result;
};

export const updateShop = body => {
  let result = url
    .post('/update', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });

  return result;
};

export const deleteShop = id => {
  let body = { uid: id };
  let result = url
    .post('/delete', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });

  return result;
};

export const getShopCommissions = () => {
  let result = url
    .get(`/getCommissions`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });

  return result;
};

export const createComission = body => {
  let result = url
    .post('/commissions', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });

  return result;
};

export const createEmployee = body => {
  let result = url
    .post('/employees/create', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });

  return result;
};

export const deleteEmployee = body => {
  let result = url
    .post('/employees/delete', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });

  return result;
};

export const updateEmployee = body => {
  let result = url
    .post('/employees/update', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });

  return result;
};

export const updateTemplate = body => {
  let result = urlAdmin
    .post('/templates/update', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });

  return result;
};

export const createSaldo = body => {
  let result = urlAdmin
    .post('/credits/create', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return { error: error };
    });

  return result;
};

export const updateSaldoStatus = body => {
  let result = urlAdmin
    .post('/credits/update', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return { error: error };
    });

  return result;
};
