import styled from 'styled-components';

export const RemaningCollections = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 5%;
  max-width: 1155px;
  justify-content: center;
  margin: 0 auto;

  > div {
    margin-right: 20px;
    flex-grow: 1;
    min-width: 100%;

    @media (min-width: 768px) {
      min-width: 50%;
    }

    @media (min-width: 1024px) {
      min-width: 273px;
    }
  }
`;
export const HomeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: linear-gradient(
      0deg,
      rgba(111, 199, 218, 0.2),
      rgba(111, 199, 218, 0.2)
    ),
    linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
`;
