import { COUNTRIES } from "../../Consts";
import { CARTA_CORP_PRODUCT_LIST } from "../Step2/const";

export const getCountry = {
  SLV: COUNTRIES["SLV"].name,
  HND: COUNTRIES["HND"].name,
  GTM: COUNTRIES["GTM"].name,
  COL: COUNTRIES["COL"].name,
  MEX: COUNTRIES["MEX"].name,
  NIC: COUNTRIES["NIC"].name,
  BOL: COUNTRIES["BOL"].name,
  ECU: COUNTRIES["ECU"].name,
};

export const TIGO_CODES = ["1505", "1535", "110", "1454", "1474"];

export const isTigo = (code) => TIGO_CODES.includes(code?.toString());

export const isTigoByName = (name) => name?.includes("Tigo");

export const isCartaCorp = (code, country, packageName) => {
  const ccCountries = Object.keys(CARTA_CORP_PRODUCT_LIST);
  let result = isTigo(code) && ccCountries.includes(country);
  if (packageName) {
    return result && isTigoByName(packageName);
  }
  return result;
};
