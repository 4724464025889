import React, { useContext, useEffect, useState } from 'react';
import ProductContext from 'context/ProductContext';
import { Select } from './style';

import { getLocalStorage, setLocalStorage } from '../utils/storage';

export function LanguajeSelect() {
  const { currentLang, setCurrentLang, currentTranslate } = useContext(
    ProductContext
  );
  const handleChange = event => {
    setCurrentLang(event.target.value);
  };

  return (
    <div>
      <Select value={currentLang} onChange={e => handleChange(e)}>
        <option value="en">en</option>
        <option value="es">es</option>
      </Select>
    </div>
  );
}
