import React, { useState, useContext } from 'react';
import ProductContext from '../../../../../../context/ProductContext';
import { useForm, Controller } from 'react-hook-form';
import { FaChevronLeft } from 'react-icons/fa';
import { createEmployee, updateEmployee } from '../../../../Requets';
import {
  GobackBtn,
  FormInput,
  FormBtn,
  ErrorElement,
  Message,
} from '../../../../Inputs';

import {
  Header,
  Content,
  Label,
  RowTemplate,
  Footer,
  FormContainer,
} from './style';

export function NuevoVendedor({ display }) {
  const { selectedShop } = useContext(ProductContext);
  const { control, register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const onSubmit = async data => {
    setLoading(true);
    setMessage('');
    setError('');
    const {
      nombre,
      apellidos,
      contraseña,
      email,
      contraseñConfirm,
    } = data;
    let requestBody = {
      email,
      name: `${nombre} ${apellidos}`,
      username: nombre,
      password: contraseña,
      shop_id: selectedShop.uid,
    };

    if (contraseña === contraseñConfirm) {
      await createEmployee(requestBody);
      setLoading(false);
      setMessage('El usuario ha sido creado exitosamente');
    } else {
      setError('Las contraseñas no coinciden');
      setLoading(false);
    }
  };

  return (
    <div>
      <Header>
        <GobackBtn onClick={() => display(false)}>
          <FaChevronLeft size="1.0em" />
          Volver
        </GobackBtn>
      </Header>
      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer>
            <RowTemplate>
              <div>
                <Label>Nombres</Label>
                <Controller
                  as={<FormInput width="300" placeholder="Editar nombre" />}
                  name="nombre"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                />
                {errors.nombre && (
                  <ErrorElement>*Campo Obligatorio</ErrorElement>
                )}
              </div>
              <div>
                <Label>Email</Label>
                <Controller
                  as={
                    <FormInput width="300" placeholder="Email" />
                  }
                  name="email"
                  control={control}
                  defaultValue=""
                  type="email"
                  rules={{
                    required: true,
                  }}
                />
                {errors.email && (
                  <ErrorElement>*Campo Obligatorio</ErrorElement>
                )}
              </div>
            </RowTemplate>
            <RowTemplate>
              <div>
                <Label>Apellidos</Label>
                <Controller
                  as={<FormInput width="300" placeholder="Apellidos" />}
                  name="apellidos"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                />
                {errors.apellidos && (
                  <ErrorElement>*Campo Obligatorio</ErrorElement>
                )}
              </div>
              <div>
                <Label>Contraseña</Label>
                <Controller
                  as={<FormInput width="300" placeholder="contraseña" />}
                  name="contraseña"
                  control={control}
                  defaultValue=""
                  type="password"
                  rules={{
                    required: true,
                  }}
                />
                {errors.contraseña && (
                  <ErrorElement>*Campo Obligatorio</ErrorElement>
                )}
              </div>
              <div>
                <Label>Confirmar Contraseña</Label>
                <Controller
                  as={
                    <FormInput
                      width="300"
                      placeholder="Confirmar Contraseña"
                      type="password"
                    />
                  }
                  name="contraseñConfirm"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                />
                {errors.contraseñConfirm && (
                  <ErrorElement>*Campo Obligatorio</ErrorElement>
                )}
              </div>
            </RowTemplate>
          </FormContainer>
          {error && <ErrorElement>{error}</ErrorElement>}
          {message && <Message>{message}</Message>}
          <Footer>
            <FormBtn disabled={loading}>Crear Usuario</FormBtn>
          </Footer>
        </form>
      </Content>
    </div>
  );
}
