import styled from 'styled-components';
export const Select = styled.select`
  width: 100%;
  height: 35px;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  border: none;
  // margin-left: 10px;
  font-size: 16px;
  color: #322665;
  margin-bottom: 30px;
  border-bottom: 2px solid;
  border-color: #322665;
`;
