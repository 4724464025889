import React, { useState, useContext } from 'react';
import ProductContext from 'context/ProductContext';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { updateSaldoStatus } from '../../../Requets';
import { GenerarCompra } from './GenerarCompra';
import { DetalleCompra } from './DetalleCompra';
import { FirestoreCollection } from '@react-firebase/firestore';
import { Loading } from '../../../../Loading';
import { getDate } from '../../../../utils/dates';

import { FaPlus } from 'react-icons/fa';
import {
  SecondaryBtn,
  Divider,
  MainTable,
  TableTh,
  TableTd,
  ErrorElement,
  Message,
  ColoredTableTd,
  TableWrapper
} from '../../../Inputs';

import {
  BtnWrapper,
  CompraSaldoWrapper,
  DividerContainer,
  IconContainer,
  CreditsListWrapper
} from './style';
import { CREDIT_STATUS } from '../../../../Consts';

export function CompraSaldo() {
  const { selectedShop } = useContext(ProductContext);
  const [selectedInfo, setSelectedInfo] = useState(null);
  const [detailView, setDetailView] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [nuevaCompraView, setNuevaCompraView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const changeDetailView = details => {
    setSelectedInfo(details);
    setDetailView(true);
  };

  const manageSelect = async (newValue, info, id) => {
    setStatusLoading(true);
    setSelectedCredit(id);
    setMessage('');
    setError(null);
    let newPaymentObject = {};
    if (newValue !== info.payment.status) {
      info.payment.status = newValue;
      let payment = {
        ...info.payment,
        date: info.payment.date.toDate()
      };
      newPaymentObject = {
        payment,
        id,
      };
      let response = await updateSaldoStatus(newPaymentObject);
      if (response.error) {
        setError(
          'Ha ocurrido un error actualizando el estado del pago. Intenta más tarde.'
        );
        setEdit(false);
      } else {
        setMessage('Se ha actualizado el estado del pago exitosamente');
        setEdit(false);
      }
      setStatusLoading(false);
    }
  };

  const icon = useStaticQuery(
    graphql`
      {
        detallesIcon: file(relativePath: { eq: "detallesIcon.png" }) {
          childImageSharp {
            fixed(height: 30, width: 45) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        editIcon: file(relativePath: { eq: "editIcon.png" }) {
          childImageSharp {
            fixed(height: 27, width: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  return (
    <CompraSaldoWrapper>
      {detailView && (
        <DetalleCompra details={selectedInfo} setDetailView={setDetailView} />
      )}
      {nuevaCompraView && (
        <GenerarCompra display={setNuevaCompraView} />
      )}
      {!detailView && !nuevaCompraView && (
        <div>
          <FirestoreCollection
            path="/credits"
            where={{
              field: 'shop.id',
              operator: '==',
              value: selectedShop.uid,
            }}
            // orderBy={{
            //   field: 'created_at',
            // }}
          >
            {data => {
              let mainData = data?.value;

              // order by date
              if (mainData) {
                mainData.map((value, idx) => {
                  value['id'] = data.ids[idx];
                });
                mainData.sort((a, b) => b.created_at.toDate() - a.created_at.toDate())
              }

              return data.isLoading ? (
                <Loading />
              ) : (
                <CreditsListWrapper>
                  <BtnWrapper>
                    <SecondaryBtn onClick={() => setNuevaCompraView(true)}>
                      <FaPlus size="1.0em" />
                      {` Nuevo Crédito`}
                    </SecondaryBtn>
                  </BtnWrapper>
                  <DividerContainer>
                    <Divider width="80%" />
                  </DividerContainer>
                  <TableWrapper>
                    <MainTable>
                      <thead>
                        <tr>
                          <TableTh>Monto</TableTh>
                          <TableTh>Fecha de Ingreso</TableTh>
                          <TableTh>Descripción</TableTh>
                          <TableTh>Tipo de Compra</TableTh>
                          <TableTh>Estado</TableTh>
                          <TableTh>Editar</TableTh>
                          <TableTh>Detalle</TableTh>
                        </tr>
                      </thead>
                      <tbody>
                        {mainData?.map((value, idx) => (
                          <tr>
                            <TableTd>${value.amount}</TableTd>
                            <TableTd>
                              {getDate(value.created_at.toDate())}
                            </TableTd>

                            <TableTd>{value.description}</TableTd>
                            <TableTd>{value.type}</TableTd>
                            <ColoredTableTd color={CREDIT_STATUS[value.payment.status].color}>
                              {edit && value.id === selectedCredit ? (
                                <div>
                                  {statusLoading ? (
                                    <Loading />
                                  ) : (
                                    <select
                                      onChange={e =>
                                        manageSelect(
                                          e.target.value,
                                          value,
                                          value.id
                                        )
                                      }
                                    >
                                      {Object.keys(CREDIT_STATUS).map(statusValue => (
                                        <option
                                          value={statusValue}
                                          selected={
                                            statusValue === value.payment.status
                                              ? true
                                              : false
                                          }
                                          key={statusValue}
                                        >
                                          {CREDIT_STATUS[statusValue].message}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </div>
                              ) : (
                                <>{CREDIT_STATUS[value.payment.status].message}</>
                              )}
                            </ColoredTableTd>

                            <TableTd>
                              <IconContainer
                                onClick={() => {
                                  setSelectedCredit(value.id);
                                  setEdit(!edit);
                                }}
                              >
                                <Img
                                  fixed={icon.editIcon?.childImageSharp.fixed}
                                  loading="auto"
                                />
                              </IconContainer>
                            </TableTd>
                            <TableTd>
                              <IconContainer
                                onClick={() => changeDetailView(value)}
                              >
                                <Img
                                  fixed={icon.detallesIcon?.childImageSharp.fixed}
                                  loading="auto"
                                />
                              </IconContainer>
                            </TableTd>
                          </tr>
                        ))}
                      </tbody>
                    </MainTable>
                  </TableWrapper>
                  <ErrorElement>{error}</ErrorElement>
                  <Message>{message}</Message>
                </CreditsListWrapper>
              );
            }}
          </FirestoreCollection>
        </div>
      )}
    </CompraSaldoWrapper>
  );
}
