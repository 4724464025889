import styled from 'styled-components';

export const TableContainer = styled.div`
  table {
    width: 100%;
    max-width: 627px;
  }
  table td {
    height: 54px;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    vertical-align: middle;
    text-align: initial;
  }
  table td:first-child {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    width: 30%;
    color: #000000;
    padding-left: 10px;
  }
  table tr:nth-child(odd) td {
    background-color: #b3abff;
    border: solid #b3abff;
  }
  table tr:nth-child(even) td {
    background-color: #d9d5ff;
    border: solid #d9d5ff;
  }
  @media (max-width: 500px) {
    td {
      width: 50% !important;
    }
  }
`;

export const TabContainer = styled.div`
  .react-tabs {
    margin-top: 50px;
    margin-bottom: 100px;
    .react-tabs__tab-list {
      border-bottom: none;
      .react-tabs__tab--selected {
        font-weight: bold;
        color: #322665;
        border-color: white;
        span {
          display: inline-block;
          border-bottom: 5px solid #322665;
          padding-bottom: 2px;
        }
      }
      li {
        font-size: 18px;
        line-height: 21px;
        color: rgba(167, 154, 255, 1);
      }
    }
    @media (max-width: 500px) {
      .react-tabs__tab-list {
        li {
          font-size: 16px;
          padding: 6px 5px;
        }
      }
    }
  }
`;
