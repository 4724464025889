import styled, { css } from 'styled-components';

const homeStyle = ({ home }) => {
  if (home) {
    return css`
      padding: 0px;
    `;
  }
};

const cartStyleResponsive = ({ cart }) => {
  if (cart) {
    return css`
      @media (max-width: 600px) {
        padding: 0px;
      }
    `;
  }
};

export const LayoutWrapper = styled.div`
  box-sizing: border-box;
  background-color: white;
  ${homeStyle}
  ${cartStyleResponsive}
`;
