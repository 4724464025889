import styled from 'styled-components';

export const Step1Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  form {
    width: 100%;
  }
  input {
    width: 100%;
  }
  select {
    margin-bottom: 0;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 30px;
`;

export const StepFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10%;
`;

export const PhoneWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  input:last-child {
    margin-left: 5px;
  }
  input[type=number]:first-child {
    -moz-appearance: textfield;
    width: 40px;
    text-align: center;
    padding: 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
