import React, { useContext, useEffect, useState } from 'react';
import ProductContext from 'context/ProductContext';
import { navigate, useLocation } from '@reach/router';
import queryString from 'query-string';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import {
  DropdownContent,
  DropdownLegend,
  DropdownUl,
  DropdownLi,
  DropdonwWrapper,
  DropdownList,
} from './style';
import { getLocalStorage, setLocalStorage } from '../utils/storage';

export function CountryDrowdown() {
  const [open, setOpen] = useState(false);
  const data = useStaticQuery(
    graphql`
      {
        location: file(relativePath: { eq: "Location.png" }) {
          childImageSharp {
            fixed(height: 29) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        vector: file(relativePath: { eq: "Vector.png" }) {
          childImageSharp {
            fixed(height: 13, width: 16) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        Guatemala: file(relativePath: { eq: "guatemalaflag.png" }) {
          childImageSharp {
            fixed(height: 41) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        Honduras: file(relativePath: { eq: "hondurasflag.png" }) {
          childImageSharp {
            fixed(height: 41) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        ElSalvador: file(relativePath: { eq: "elsalvadorflag.png" }) {
          childImageSharp {
            fixed(height: 41) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const handleOpen = () => {
    setOpen(!open);
  };

  const { countryList, setSelectCountry, selectCountry } = useContext(
    ProductContext
  );
  let navigateTo = '/productos';
  let location = useLocation();
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const searchTerm = qs.s;
  const filter = qs.c;

  const manageQueryParams = country => {
    let path = location.pathname;
    if (filter && !searchTerm) {
      path = `${path}?country=${country}&c=${filter}`;
    } else if (filter && !!searchTerm) {
      path = `${path}?country=${country}&c=${filter}&s=${searchTerm}`;
    } else if (!filter && !!searchTerm) {
      path = `${path}?country=${country}&s=${searchTerm}`;
    } else {
      path = `${path}?country=${country}`;
    }
    return path;
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (getLocalStorage('country')) {
        setSelectCountry(getLocalStorage('country'));
        navigate(`${manageQueryParams(getLocalStorage('country'))}`);
      } else {
        setSelectCountry(countryList[0].node.shopifyId);
        navigate(`${manageQueryParams(countryList[0].node.shopifyId)}`);
        //setLocalStorage('country', countryList[0].node.shopifyId);
      }
    }
  }, []);

  const onChangeCountry = value => {
    setLocalStorage('country', value);
    setSelectCountry(value);
    localStorage.removeItem('checkout');
    navigate(`${navigateTo}?country=${value}`);
    handleOpen();
  };
  let currentCountry = countryList.filter(
    value => value.node.shopifyId === selectCountry
  );
  return (
    <DropdonwWrapper>
      <DropdownContent onClick={handleOpen}>
        <div>
          <Img fixed={data.location?.childImageSharp.fixed} loading="auto" />
        </div>
        <DropdownLegend>
          {selectCountry ? (
            <p>{currentCountry[0].node.title}</p>
          ) : (
            <p>
              Elige tu
              <br /> país aca
            </p>
          )}
        </DropdownLegend>
        <div>
          <Img fixed={data.vector?.childImageSharp.fixed} loading="auto" />
        </div>
      </DropdownContent>
      {open && (
        <DropdownList>
          <DropdownUl>
            {countryList.map(pais => (
              <DropdownLi
                onClick={() => onChangeCountry(pais.node.shopifyId)}
                key={pais.node.shopifyId}
              >
                <Img
                  fixed={
                    data?.[pais.node.title.replace(/\s/g, '')]?.childImageSharp
                      .fixed
                  }
                  loading="auto"
                />

                <p> {pais.node.title}</p>
              </DropdownLi>
            ))}
          </DropdownUl>
        </DropdownList>
      )}
    </DropdonwWrapper>
  );
}
