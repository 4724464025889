import React from 'react';
import ReactLoading from 'react-loading';

import { LoadingWrapper } from './style';

export function Loading() {
  return (
    <LoadingWrapper>
      <ReactLoading type="bubbles" color="#e2513a" />
    </LoadingWrapper>
  );
}
