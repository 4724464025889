import styled from "styled-components";

export const WaStyledButton = styled.div`
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 91px;
  left: 25px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #06d755;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 100%;
`;
