import styled from "styled-components";

export const FormWrapper = styled.form`
  display: flex;
  justify-content: flex-end;
  margin: 60px 60px 0px 0px;
  @media (max-width: 600px) {
    justify-content: center;
    margin: 60px 0px 0px 0px;
    input {
      width: 80%;
    }
  }
`;

export const Input = styled.input`
  width: 448px;
  height: 51px;
  background: #f3f3f3;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: none;
  color: #656363;
  padding-left: 20px;
}
  ::placeholder {
    font-size: 14px;
    color: #656363;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
`;
