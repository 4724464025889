import styled from 'styled-components';
import { StyleLink } from '../StyleLink';

export const CartSumaryContainer = styled.div`
  background-color: white;
  #divider {
    border: 1px solid #322665;
    width: 50%;
    margin-bottom: 50px;
    margin-top: 50px;
  }
  ${StyleLink} {
    text-decoration: none;
    cursor: pointer;
    button {
      color: #b73f7f;
      width: 190px;
      height: 60px;
      border: solid 3px #b73f7f;
      text-decoration: none;
      margin-right: 20px;
      span {
        font-weight: bold;
        font-size: 16px;
      }
      &:hover {
        background: linear-gradient(180deg, #df4f46 0%, #be4276 100%), #be4276;
        border: 3px solid rgba(223, 79, 70, 0.5);
        color: white;
      }
    }
  }
`;

export const CartTitle = styled.p`
  margin-top: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #322665;
`;

export const CartItem = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  > div {
    padding: 8px;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: #322665;
  }
`;

export const SubHeading = styled.div`
  font-size: 16px;
`;
export const ImgContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  img {
    width: 201px;
    height: 198px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 65%;
`;

export const QuantityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
