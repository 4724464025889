import React, { useContext } from 'react';
import { CollectionTitle } from '../CollectionTitle';
import { Banner } from '../Banner';
import { InformativeSection } from '../InformativeSection';
import { HOME_SECTIONS } from '../Consts';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

import { RemaningCollections, HomeGrid } from './style';
import ProductContext from '../../context/ProductContext';

export function HomePageCollectionGrip({ collections }) {
  const { currentTranslate } = useContext(ProductContext);
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const country = qs.country;
  const promotionCollection = collections.find(
    collection => collection.title === 'Promoción'
  );

  const { btob, btoc } = useStaticQuery(
    graphql`
      {
        btob: file(relativePath: { eq: "btob.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        btoc: file(relativePath: { eq: "btoc.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <HomeGrid>
      <Banner id={promotionCollection.shopifyId} interno={false} />
      <RemaningCollections id="RemaningCollections">
        {HOME_SECTIONS.map(collection => (
          <CollectionTitle
            title={currentTranslate.home[collection.name]}
            destination={`/${collection.base}?country=${country}${collection.urlHandler}`}
            key={collection.id}
            image={collection.image}
            hoverImage={collection.hoverImage}
          />
        ))}
      </RemaningCollections>
      <InformativeSection />
    </HomeGrid>
  );
}
