import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  padding: 30px;
  border-bottom: 1px solid #c4c4c4;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #656363;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 0px 60px 0px;
  form {
    width: 489px;
  }
`;

export const RowContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 227px 227px;
`;

export const DatePickerContainer = styled.div`
  width: 489px;
  background: #f3f3f3;
  padding: 10px;

  input {
    width: 469px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
