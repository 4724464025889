import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

export function Invoice({ info }) {
  const getDate = str => {
    const dateObj = new Date(str);
    const opts = { hour: '2-digit', minute: '2-digit', hour12: false };
    const date = dateObj.toLocaleDateString('en-US');
    const time = dateObj.toLocaleTimeString([], opts);
    return `${date} ${time}`;
  };

  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#FFFFFF',
      alignItems: 'center',
    },
    section: {
      margin: 10,
      padding: 10,
      width: '70%',
    },
    sectionFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      flexDirection: 'row',
      paddingTop: 5,
      paddingBottom: 5,
    },
    sectionCenter: {
      textAlign: 'center',
      paddingTop: 10,
      paddingBottom: 10,
    },
    sectionDivider: {
      textAlign: 'center',
      paddingTop: 20,
      paddingBottom: 20,
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.sectionCenter}>
            {getDate(info?.created_at.toDate())}
          </Text>
          <Text style={styles.sectionDivider}>
            ------------------------------------------------------
          </Text>
          <Text style={styles.sectionCenter}>TICKET DE CRÉDITO</Text>
          <Text style={styles.sectionDivider}>
            ------------------------------------------------------
          </Text>
          <Text style={styles.sectionCenter}>
            Tipo de pago:
            {' ' + info?.type}
          </Text>
          <Text style={styles.sectionCenter}>
            Monto
            {' $' + info?.amount.toFixed(2) + ' USD'}
          </Text>
          <View style={styles.sectionFlex}>
            <Text>Tienda:</Text>
            <Text> {info?.shop?.name}</Text>
          </View>
          {info?.payment?.bank && (
            <View style={styles.sectionFlex}>
              <Text>Banco:</Text>
              <Text>{info?.payment?.bank}</Text>
            </View>
          )}
          <View style={styles.sectionFlex}>
            <Text>Referencia:</Text>
            <Text>{info?.payment?.reference}</Text>
          </View>
          <View style={styles.sectionFlex}>
            <Text>Estado:</Text>
            <Text>{info?.payment?.status}</Text>
          </View>
          {info?.payment?.status === 'completed' && (
            <View style={styles.sectionFlex}>
              <Text>Fecha de pago:</Text>
              <Text>{getDate(info?.payment?.date?.toDate())}</Text>
            </View>
          )}
          <View style={styles.sectionFlex}>
            <Text>Descripción: </Text>
            <Text>{info?.description}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
