import styled, { css } from 'styled-components';

const superPackContainer = ({ superPack }) => {
  if (superPack) {
    return css`
      border: 2px solid #be4276;
      // min-height: 288px;
    `;
  }
};

const superPackResumen = ({ superPack }) => {
  if (superPack) {
    return css`
      // min-height: 170px;
    `;
  }
};
const packInternetContainer = ({ packInternet }) => {
  if (packInternet) {
    return css`
      border: 2px solid orange;
      // min-height: 288px;
    `;
  }
};

const packInternetResumen = ({ packInternet }) => {
  if (packInternet) {
    return css`
      // min-height: 170px;
    `;
  }
};
export const Step2Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Resumen = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #322665;
  margin-bottom: 50px;
  margin-top: 45px;
`;

export const ResumenInfo = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #322665;
  padding: 10px;
`;

export const TextLine = styled.div`
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #322665;
  margin: 10px 0px 10px 0px;
  div {
    width: 80%;
    height: 1px;
    background-color: #322665;
  }
`;

export const PaquetesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 700px) {
    justify-content: center;
  }
  @media (max-width: 400px) {
    overflow: scroll;
    height: 300px;
  }
`;

export const PaqueteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 2px solid ${props => (props.active ? '#e2513a' : '#b3abff')};
  box-sizing: border-box;
  box-shadow: ${props =>
    props.active ? '2px 0px 21px #e2513a' : '0px 4px 4px rgba(0, 0, 0, 0.15)'};

  width: 257px;
  // min-height: 148px;
  border-radius: 0px 0px 5px 5px;
  margin: 10px 0px 10px 0px;
  cursor: pointer;
  ${superPackContainer};
  ${packInternetContainer};
`;
export const PaqueteInformationContainer = styled.div`
  padding: 10px;
`;

export const PaqueteTitle = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #322665;
  padding: 4px 0 4px 0;
`;

export const PaqueteMonto = styled.p`
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #322665;
  padding: 4px 0 4px 0;
  border-bottom: 1px solid #c4c4c4
`;

export const PaqueteMontoMoneda = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #322665;
`;

export const PaqueteMontoResumen = styled.p`
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: rgba(50, 38, 101, 0.8);
  padding: 7px 0 4px 0;
  border-top: 1px solid #c4c4c4;
  ${superPackResumen};
  ${packInternetResumen};
  li {
    padding: 5px 0px 5px 0px;
  }
  > li:nth-child(1){
    font-size: 16px;
    font-weight: 600;
    color: #ea6926;
  }
  > div:last-child {
    margin-top: 15px;
  }
`;

export const PaqueteMontoFooter = styled.div`
  width: 254px;
  height: auto;
  padding: 5px;
  text-align: right;
  font-weight: bold;
  background: ${props =>
    props.active ? '#e2513a' : props.superPack ? '#B73F7F' : props.packInternet ? '#e3813b' : '#b3abff'};
`;

export const StepFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5%;
`;

export const Error = styled.p`
  font-weight: bold;
  color: red;
`;
