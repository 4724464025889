import React, { useContext } from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import emailIcon from '../../images/emailIcon.png';
import InstagramIcon from '../../images/InstagramIcon.png';
import FacebookIcon from '../../images/FacebookIcon.png';
import waIcon from '../../images/waIcon.png';
import { FooterGrid } from './style';
import ProductContext from '../../context/ProductContext';

export function Footer() {
  const { currentTranslate } = useContext(ProductContext);

  const logo = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "senditlogo.png" }) {
        childImageSharp {
          fixed(width: 71, height: 102) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <FooterGrid>
      <div>
        <Link to="/">
          <Img fixed={logo.file.childImageSharp.fixed} loading="auto" />
        </Link>
        <p>
          SENDIT © {currentTranslate.footer.copyright} <br></br>All rights reserved
          2020
        </p>
      </div>
      <div>
        <div>
          <a href="https://www.facebook.com/sendit.usa/">
            <p>@Sendit.usa</p> <img src={FacebookIcon} />
          </a>
          <a href="https://www.instagram.com/sendit.usa/" target="_blank">
            <p> @Sendit.usa</p> <img src={InstagramIcon} />
          </a>
          <a href="tel:+50360627014">
            <p>+(503) 6062-7014</p> <img src={waIcon} />
          </a>
          <a href="mailto: info@mysendit.net">
            <p> info@mysendit.net</p> <img src={emailIcon} />
          </a>
        </div>
      </div>
    </FooterGrid>
  );
}
