import styled, { css } from 'styled-components';

import { StyleLink } from '../StyleLink';

const internoStyle = ({ interno }) => {
  if (interno) {
    return css`
      margin-bottom: 5% !important;
    `;
  }
};

export const BannerWrapper = styled.div`
  width: 100%;
  left: 0;
  .carousel-slider {
    height: auto;
  }
  .slider-wrapper,
  ul.slider {
    height: 100%;
  }
  li.slide img {
    min-width: 100%;
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  .legend {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    bottom: 0 !important;
    right: 0;
    margin-top: auto !important;
    margin-left: auto !important;
    font-weight: bold !important;
    font-size: 42px !important;
    color: white !important;
    width: auto !important;
    opacity: 1 !important;
    background-color: transparent !important;
    ${internoStyle}
    span {
      font-size: 16px;
      margin: 10px 0;
    }
    ${StyleLink} {
      text-decoration: none;
      cursor: pointer;
      margin-bottom: 90px;
      button {
        color: #b73f7f;
        width: 190px;
        height: 60px;
        border: solid 3px #b73f7f;
        text-decoration: none;
        border-radius: 5px;
        background-color: white;
        span {
          font-weight: bold;
          font-size: 16px;
        }
        &:hover {
          background: linear-gradient(180deg, #df4f46 0%, #be4276 100%), #be4276;
          border: 3px solid rgba(223, 79, 70, 0.5);
          color: white;
        }
      }
    }
    @media (max-width: 500px) {
      span {
        margin-bottom: 0;
        margin-top: 2px;
        font-size: 13px;
      }
    }
    @media (max-width: 800px) {
      font-size: 5vw !important;
      button {
        display: none;
      }
    }
  }
`;
