import styled, { css } from 'styled-components';
import bubleBackground from '../../images/bubleBackground.png';

export const InformationContainer = styled.section`
  background-color: white;
  margin-top: 50px;
  padding: 0 35px;
  @media (max-width: 700px) {
    padding: 0px;
    background-image: none;
  }
`;

const alingStylesRight = ({ aling }) => {
  if (aling) {
    return css`
      margin-top: 40px;
      margin-left: 28%;
      text-align: right;
      @media (max-width: 700px) {
        margin-left: 0%;
        text-align: start;
      }
    `;
  }
};

export const BigBullet = styled.div`
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 0 15px;
  border-radius: 100%;
  background: linear-gradient(91.18deg, #9C0A58 -0.43%, #322665 82.55%), #B73F7F;
`;

export const InfoTitleLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoTitleRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Header = styled.p`
  font-weight: 900;
  font-size: 28px;
  line-height: 33px;
  color: #3C2464;
  font-weight: 900;
  ${alingStylesRight}
  @media (max-width: 700px) {
    margin-left: 0%;
  }
`;

export const SubHeading = styled.p`
  font-size: 16px;
  width: ${props => (props.right ? '391px' : '296px')};
  line-height: 19px;
  color: #3C2464;
  margin-top: 12px;
  margin-left: ${props => (props.right ? 'auto' : '50px')};
  margin-right: ${props => (props.right ? '50px' : 'auto')};
  white-space: break-spaces;
  @media (max-width: 700px) {
    margin-left: 0%;
    margin-bottom: 30px;
    width: 291px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const GridLeft = styled.section`
  height: 469px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 30px;

  > div:first-child {
    order: 2;
  }
  > div:last-child {
    order: 1;
  }
  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px;
    text-align: right;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    height: auto;

    > div:first-child {
      order: 1;
      height: 201px !important;
      &::before {
        background-position: center center;
      }
    }
    > div:last-child {
      order: 2;
      padding: 20px 0 20px 30px;
      width: 90%;
    }
  }
`;

export const GridRight = styled.section`
  margin-top: 40px;
  height: 469px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 69px;
  }
  @media (max-width: 700px) {
    margin-top: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: 230px 300px;
    height: auto;
    > div:first-child {
      height: 201px !important;
      &::before {
        background-position: center center;
      }
    }
    > div:last-child {
      padding: 0;
      padding-left: 30px;
      width: 90%;
    }
  }
`;
