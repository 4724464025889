import styled from 'styled-components';

export const SliderContainer = styled.div`
  justify-content: center;
  display: flex;
  height: 340px;
  width: 100%;
  margin-top: 10%;
  align-items: center;
  display: none;
  @media (max-width: 500px) {
    display: flex;
  }
`;

export const ImageContainer = styled.div`
  width: 219px;
  height: 264.21px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${props => (props.image ? props.image : null)});
`;
