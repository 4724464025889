import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Label,
  FormInput,
  FormSelect,
  FormTextArea,
  FormBtn,
  ErrorElement,
  Message,
} from '../../../Inputs';
import { Switch } from '../../../../Switch';

import { createShop } from '../../../Requets';
import {
  CrearTiendaWrapper,
  Footer,
  GridContainer,
  InputContainer,
  Legend,
} from './style';
import { USA_STATES } from '../const';

export function CrearTienda() {
  const [statusValue, setValue] = useState(true);
  const [loading, setLoading] = useState(false);
  const [validatePsw, setValidatePsw] = useState(false);
  const [passError, setPassError] = useState('');
  const [message, setMessage] = useState('');

  const { control, register, watch, handleSubmit, errors } = useForm();
  const watchFields = watch(['psw', 'confirmpsw']);

  const onSubmit = async data => {
    const { psw, confirmpsw } = watchFields;
    setLoading(true);
    setMessage('');
    setPassError('');
    let createInfo = {
      password: data.psw,
      email: data.email,
      name: data.nombre,

      balance: {
        current: parseFloat(data.saldo_actual),
        minimum: parseFloat(data.saldo_minimo),
      },
      location: {
        address: data.direccion,
        state: data.ubicacion,
      },
    };
    if (data.telefono) {
      createInfo['telephone'] = data.telefono;
    }
    if (psw === confirmpsw) {
      setPassError('');
      try {
        await createShop(createInfo);
        setMessage('Tienda creada exitosamente');
      } catch (error) {
        setPassError('Error al crear la tienda');
      }
    } else {
      setPassError('Las contraseñas deben coincidir');
    }

    setLoading(false);
  };

  return (
    <CrearTiendaWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label>Nombre</Label>
        <Controller
          as={<FormInput placeholder="Editar nombre..." />}
          name="nombre"
          control={control}
          defaultValue={''}
          rules={{
            required: true,
          }}
        />
        {errors.nombre && <ErrorElement>*Campo Obligatorio</ErrorElement>}
        <Label>Email</Label>
        <Controller
          as={<FormInput placeholder="Email" type="email" />}
          name="email"
          control={control}
          defaultValue={''}
          rules={{
            required: true,
          }}
        />
        {errors.email && <ErrorElement>*Campo Obligatorio</ErrorElement>}
        <GridContainer>
          <InputContainer>
            <Label>Saldo Mínimo</Label>
            <Controller
              as={<FormInput placeholder="12.52" type="number" width={'100'} />}
              name="saldo_minimo"
              control={control}
              defaultValue={''}
              rules={{
                required: true,
              }}
            />
            {errors.saldo_minimo && (
              <ErrorElement>*Campo Obligatorio</ErrorElement>
            )}
          </InputContainer>
          <InputContainer>
            <Label>Saldo Actual</Label>
            <Controller
              as={<FormInput placeholder="20.0" type="number" width={'100'} />}
              name="saldo_actual"
              control={control}
              defaultValue={''}
              rules={{
                required: true,
              }}
            />
            {errors.saldo_actual && (
              <ErrorElement>*Campo Obligatorio</ErrorElement>
            )}
          </InputContainer>
          <InputContainer>
            <Label>Estado</Label>
            <Legend status={true}>{statusValue ? 'Activo' : 'Inactivo'}</Legend>
            <Switch
              id="checkbox"
              color={'#7DE59A'}
              value={statusValue}
              setValue={setValue}
            />
          </InputContainer>
        </GridContainer>
        <Label>Ubicación</Label>
        <FormSelect name="ubicacion" ref={register()}>
          <option value="">Escoge Estado</option>
          {Object.keys(USA_STATES).map(state => (
            <option value={state}>{state}</option>
          ))}
        </FormSelect>
        <Label>Teléfono</Label>
        <Controller
          as={<FormInput placeholder="Ejemplo (11 dígitos): +12289674762" />}
          name="telefono"
          control={control}
          defaultValue={''}
          rules={{
            pattern: /^\+[0-9]{11,}$/,
          }}
        />
        {errors.telefono && <ErrorElement>*Campo Obligatorio</ErrorElement>}

        <Label>Dirección</Label>
        <Controller
          as={
            <FormTextArea
              placeholder="1101 Calle Universitaria, Esta es una dirección de ejemplo, psj 2, casa 23 "
              rows="3"
            />
          }
          name="direccion"
          control={control}
          defaultValue={''}
          rules={{
            required: true,
          }}
        />
        {errors.direccion && <ErrorElement>*Campo Obligatorio</ErrorElement>}
        <GridContainer>
          <InputContainer>
            <Label>Contraseña</Label>
            <Controller
              as={
                <FormInput
                  placeholder="******"
                  type="password"
                  width={'214px'}
                />
              }
              name="psw"
              control={control}
              defaultValue={''}
              rules={{
                required: true,
              }}
            />
            {errors.psw && <ErrorElement>*Campo Obligatorio</ErrorElement>}
          </InputContainer>
          <InputContainer>
            <Label>Confirmar Contraseña</Label>
            <Controller
              as={
                <FormInput
                  placeholder="******"
                  type="password"
                  width={'214px'}
                />
              }
              name="confirmpsw"
              control={control}
              defaultValue={''}
              rules={{
                required: true,
              }}
            />
            {errors.confirmpsw && (
              <ErrorElement>*Campo Obligatorio</ErrorElement>
            )}
          </InputContainer>
        </GridContainer>
        {passError && <ErrorElement>{passError}</ErrorElement>}
        <Message>{message}</Message>
        <Footer>
          <FormBtn disabled={loading}>Crear Tienda</FormBtn>
        </Footer>
      </form>
    </CrearTiendaWrapper>
  );
}
