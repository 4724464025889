import React, { useState, useContext } from 'react';
import ProductContext from '../../../../../context/ProductContext';
import ReactLoading from 'react-loading';
import { FaPlus } from 'react-icons/fa';
import { FirestoreCollection } from '@react-firebase/firestore';
import { useForm, Controller } from 'react-hook-form';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { NuevaOperadora } from './NuevaOperadora';
import { createComission, updateShop } from '../../../Requets';
import {
  SecondaryBtn,
  Divider,
  MainTable,
  TableTh,
  TableTd,
  TableWrapper,
} from '../../../Inputs';
import {
  OperadorasWrapper,
  BtnWrapper,
  DividerContainer,
  IconContainer,
  ComisionInput,
  EditContainer,
  ComisionContainer,
} from './style';
import { getCountry } from '../../../../Recargas/utils';

export function Operadoras() {
  const { selectedShop, setSelectedShop } = useContext(ProductContext);
  const [selectedInput, setSelectedInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingNewComissions, setLoadingNewComissions] = useState(false);
  const [created, setCreated] = useState(false);

  let defaultValues = selectedShop?.commissions?.map((value, index) => {
    return { key: ['comision-' + index], value: value.commission };
  });
  defaultValues = defaultValues.reduce(
    (obj, item) => ((obj[item.key] = item.value), obj),
    {}
  );

  const { control, handleSubmit, errors } = useForm({ defaultValues });

  const onSubmit = data => {};

  const dataIcons = useStaticQuery(
    graphql`
      {
        trashIcon: file(relativePath: { eq: "trashIcon.png" }) {
          childImageSharp {
            fixed(height: 27, width: 23) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        editIcon: file(relativePath: { eq: "editIconWhite.png" }) {
          childImageSharp {
            fixed(height: 21, width: 24) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        checkIcon: file(relativePath: { eq: "checkIcon.png" }) {
          childImageSharp {
            fixed(height: 21, width: 24) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const deleteComission = async index => {
    setLoading(true);
    let tempData = selectedShop;
    tempData.commissions.splice(index, 1);
    let newComissions = {
      uid: selectedShop.uid,
      commissions: [...tempData.commissions],
    };
    await createComission(newComissions);
    setSelectedShop({ ...tempData });
    setLoading(false);
  };

  const manageOnBlur = async (index, value) => {
    let tempData = selectedShop;
    tempData.commissions[index].commission = value;
    let newComissions = {
      uid: selectedShop.uid,
      commissions: [...tempData.commissions],
    };
    await createComission(newComissions);
    setSelectedShop({ ...tempData });
    setSelectedInput(index);
  };

  const manageOnChange = async (index, value) => {
    let tempData = selectedShop;
    tempData.commissions[index].commission = value;
    setSelectedShop({ ...tempData });
  };

  const newCommissions = async data => {
    setLoadingNewComissions(true);
    let tempData = selectedShop;
    tempData.commissions = data[0].commissions;
    let newComisiones = {
      uid: selectedShop.uid,
      commissions: data[0].commissions,
    };

    await updateShop(newComisiones);
    setSelectedShop({ ...tempData });
    setLoadingNewComissions(false);
  };

  return (
    <OperadorasWrapper>
      {created ? (
        <NuevaOperadora
          display={setCreated}
          id={selectedShop.uid}
          comissionsCreated={selectedShop.commissions}
        />
      ) : (
        <div>
          <FirestoreCollection path="/templates/">
            {data => {
              return data.isLoading ? (
                <ReactLoading type="bubbles" color="#e2513a" height={30} />
              ) : (
                <div>
                  <BtnWrapper>
                    <SecondaryBtn
                      onClick={() => {
                        setCreated(!created);
                      }}
                    >
                      <FaPlus size="1.0em" />
                      {` Nueva Operadora`}
                    </SecondaryBtn>
                    <SecondaryBtn
                      width="180px"
                      marginLeft="30px"
                      onClick={() => {
                        newCommissions(data?.value);
                      }}
                    >
                      {` Aplicar Plantilla`}
                    </SecondaryBtn>
                  </BtnWrapper>
                  <DividerContainer>
                    <Divider width="80%" />
                  </DividerContainer>
                  {loadingNewComissions ? (
                    <ReactLoading type="bubbles" color="#e2513a" height={30} />
                  ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <TableWrapper>
                        <MainTable>
                          <thead>
                            <tr>
                              <TableTh>Nombre</TableTh>
                              <TableTh>País</TableTh>
                              <TableTh>Comisión (%)</TableTh>
                              <TableTh></TableTh>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedShop?.commissions?.map((value, index) => (
                              <tr>
                                <TableTd>{value.operator}</TableTd>
                                <TableTd>
                                  {getCountry[value.country_code]}
                                </TableTd>
                                <TableTd>
                                  <ComisionContainer>
                                    <Controller
                                      name={`comision-${index}`}
                                      control={control}
                                      render={props => (
                                        <ComisionInput
                                          {...props}
                                          type="number"
                                          className="input"
                                          placeholderText="05.50"
                                          name={`comision-${index}`}
                                          value={value?.commission}
                                          onBlur={e =>
                                            manageOnBlur(index, e.target.value)
                                          }
                                          onChange={e =>
                                            manageOnChange(index, e.target.value)
                                          }
                                          defaultValue={value?.commission}
                                        />
                                      )}
                                    />
                                    <EditContainer
                                      check={
                                        selectedInput === index ? true : false
                                      }
                                    >
                                      {selectedInput === index ? (
                                        <Img
                                          fixed={
                                            dataIcons.checkIcon?.childImageSharp
                                              .fixed
                                          }
                                          loading="auto"
                                        />
                                      ) : (
                                        <Img
                                          fixed={
                                            dataIcons.editIcon?.childImageSharp
                                              .fixed
                                          }
                                          loading="auto"
                                        />
                                      )}
                                    </EditContainer>
                                  </ComisionContainer>
                                </TableTd>
                                <TableTd>
                                  <IconContainer
                                    onClick={() => deleteComission(index)}
                                  >
                                    {loading ? (
                                      <ReactLoading
                                        type="bubbles"
                                        color="#e2513a"
                                        height={30}
                                      />
                                    ) : (
                                      <Img
                                        fixed={
                                          dataIcons.trashIcon?.childImageSharp
                                            .fixed
                                        }
                                        loading="auto"
                                      />
                                    )}
                                  </IconContainer>
                                </TableTd>
                              </tr>
                            ))}
                          </tbody>
                        </MainTable>
                      </TableWrapper>
                    </form>
                  )}
                </div>
              );
            }}
          </FirestoreCollection>
        </div>
      )}
    </OperadorasWrapper>
  );
}
