import styled, { css } from "styled-components";

export const BasicBtn = styled.button`
  width: 118px;
  height: 51px;
  background: #f3f3f3;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  border: none;
  color: #656363;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  width: 222px;
  height: 51px;
  padding: 10px;
  background: #f3f3f3;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
  ::placeholder {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #656363;
  }
`;

export const SelectInput = styled.select`
  width: 130px;
  height: 51px;
  font-size: 14px;
  line-height: 16px;
  background: #f3f3f3;
  border-radius: 5px;
  padding: 15px;
  border: none;
  font-weight: bold;
  color: #656363;
`;

export const SubmitBtn = styled.button`
  width: 100px;
  height: 51px;
  background: linear-gradient(269.91deg, #7a27f0 0%, #cb01d7 99.96%);
  border-radius: 5px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #656363;
`;

const formInputStlye = () => {
  return css`
    width: ${(props) => (props.width ? props.width + "px" : "489px")};
    height: 51px;
    background: #f3f3f3;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: none;
    padding: 10px;
    margin: 10px 0px 10px 0px;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #656363;
  `;
};

export const FormInput = styled.input`
  ${formInputStlye}
`;

export const FormSelect = styled.select`
  ${formInputStlye}
`;

export const FormTextArea = styled.textarea`
  ${formInputStlye}
`;

export const FormBtn = styled.button`
  width: 165px;
  height: ${(props) => (props.height ? props.height : "51px")};
  background: linear-gradient(269.91deg, #7a27f0 0%, #cb01d7 99.96%);
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  &:disabled {
    border-color: #999;
    cursor: not-allowed;
    color: #999;
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
`;

export const FormBtnPagination = styled.button`
  width: 165px;
  height: ${(props) => (props.height ? props.height : "51px")};
  background: linear-gradient(269.91deg, #7a27f0 0%, #cb01d7 99.96%);
  margin-left: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  &:disabled {
    border-color: #999;
    cursor: not-allowed;
    color: #999;
  }
  a {
    color: #ffffff;
    text-decoration: none;
  }
`;
export const ErrorElement = styled.p`
  font-weight: bold;
  color: red;
`;

export const Message = styled.p`
  font-weight: bold;
  color: green;
`;

export const SecondaryBtn = styled.button`
  width: ${(props) => (props.width ? props.width : " 147px")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : " 0Px")};
  height: 51px;
  background: #f3f3f3;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border: none;
  color: #656363;
  cursor: pointer;
`;

export const TableWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

export const MainTable = styled.table`
  width: 100%;
  margin-top: 30px;

  td {
    padding: 10px;
  }

  tr:nth-child(odd) td {
    background: rgba(196, 196, 196, 0.2);
  }
  tr:nth-child(even) td {
    background-color: white;
  }
`;

export const TableTh = styled.th`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #656363;
  height: 20px;
  background-color: white;
  vertical-align: middle;
  padding: 10px;
  border-right: ${(props) =>
    props.borderRight ? "1.5px solid #C4C4C4" : "none%"};
`;

export const TableTd = styled.td`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  width: ${(props) => (props.width ? props.width : "16%")};
  height: 60px;
  color: #656363;
  text-align: center;
  vertical-align: middle;
  border-right: ${(props) =>
    props.borderRight ? "1.5px solid #C4C4C4" : "none%"};
`;

export const ColoredTableTd = styled.td`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  color: ${(props) => (props.color ? props.color : "#656363")};
  border: ${(props) => (props.border ? "1px solid #c4c4c4" : "0")};
  white-space: pre-line;
  padding: 10px;
  img {
    cursor: pointer;
  }
  span {
    font-weight: bold;
    text-transform: capitalize;
  }
  u {
    cursor: help;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid #c4c4c4;
  width: ${(props) => (props.width ? props.width : "100%")};
`;

export const SubmitBtnSecondary = styled.button`
  border-radius: 5px;
  width: 190px;
  height: 60px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  background-color: #ffffff;
  font-weight: bold;
  border: none;
  border: solid 3px #b73f7f;
  color: #b73f7f;
  cursor: pointer;
  &:disabled {
    border-color: #999;
    cursor: not-allowed;
    color: #999;
  }
`;

export const SelectInputRecargas = styled.select`
  border: none;
  width: ${(props) => (props.width ? props.width : "151px")};
  height: ${(props) => (props.height ? props.height : "47px")};
  margin-top: ${(props) => (props.margin ? props.margin : "3px")};
  background: #f3f3f3;
  border-radius: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #656363;
  outline: none;
`;

export const SearchRecargas = styled.input`
  width: 194px;
  height: 57px;
  background: #f3f3f3;
  border-radius: 5px;
  border: none;
  padding: 10px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
  &:placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    color: #656363;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const DatePickerWrapper = styled.div`
  margin-top: 12px;
  input {
    border: none;
    width: 151px;
    height: 30px;
    margin-top: 3px;
    background: #f3f3f3;
    border-radius: 5px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: #656363;
  }
`;

export const GobackBtn = styled.button`
  width: 104px;
  height: 52px;
  background: #ffffff;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #7c26f0;
  border: solid 3px #b73f7f;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`;
