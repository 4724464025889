import styled from 'styled-components';

export const BannerWrapper = styled.div`
  width: 100%;
  height: 181px;
  background: linear-gradient(91.18deg, #9c0a58 -0.43%, #322665 82.55%);
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 30px 0;
  > div {
    max-width: 1122px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 65px;
    @media (max-width: 500px) {
      padding: 0 20px;
    }
  }
`;

export const ProfilePic = styled.div`
  width: 138px;
  height: 138px;
  border: 3px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80');

  @media (max-width: 500px) {
    margin-left: 30px;
  }
`;

export const ShopName = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: #ffffff;
  @media (max-width: 400px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }
`;
