import styled from 'styled-components';

export const Step3Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1000px;
  margin-top: 50px;
  padding-bottom: 100px;
  hr {
    border: 1px solid rgba(229, 229, 229, 0.7);
    width: 70%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
    input:first-child {
      width: 100%;
    }
    #titular {
      width: 100%;
    }
  }
`;
export const ShortInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  @media (max-width: 800px) {
    input {
      width: 85% !important;
    }
  }
`;

export const FormInputContainer = styled.div`
  margin-bottom: 70px;
  border-bottom: solid 2px #322665;
  padding-bottom: 10px;
`;

export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
  button {
    margin-bottom: 10px;
  }
`;

export const ResumenPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const ResumenPriceInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  padding: 10px 10px 10px 0px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  padding: 10px 10px 10px 0px;
  p:first-child {
    margin-left: 43%;
    font-weight: bold;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;
