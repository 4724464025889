import React, { useContext, useState } from "react";
import { Filtros } from "./Filtros";
import ProductContext from "context/ProductContext";
import { Invoice } from "../../Invoice/customInvoice";
import { TableTh, TableTd } from "../HistorialProductos/style";
import { ColoredTableTd } from "../../AdminPanel/Historial/VentaDeSaldo/style";
import { HistorialRecargasWrapper } from "./style";
import { PDFDownloadLink } from "@react-pdf/renderer";
import useFetchData from "../../hooks/useFetchData";
import { BtnFiltro } from "../../Button";
import PrintIcon from "../../../images/PrintIcon.png";
import { getDate } from "../../utils/dates";
import { TOPUP_STATUS } from "../../Consts";
import { Loading } from "../../Loading";
import { isCartaCorp } from "../../Recargas/utils";

export function HistorialRecargas() {
  const [filter, setFilters] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [limit, setLimit] = useState(200);
  let excelData = [];

  const { usuario, currentTranslate } = useContext(ProductContext);

  const printHistorial = () => {
    var tab = document.getElementById("printTable");
    var win = window.open("", "", "height=700,width=700");
    win.document.write(tab.outerHTML);
    win.document.close();
    win.print();
  };

  const filterByDays = (data) => {
    if (startDate && endDate) {
      let date;
      //let date = new Date(data?.creation_date?.seconds * 1000);
      /*date = new Date(data?.creation_date);
      date.setHours(0, 0, 0, 0);*/

      if (new Date(data?.creation_date?._seconds * 1000).setHours(0, 0, 0, 0)) {
        date = new Date(data?.creation_date?._seconds * 1000);
        date.setHours(0, 0, 0, 0);
        return date <= endDate && date >= startDate;
      } else {
        date = new Date(data?.creation_date?._seconds * 1000);
        date.setHours(0, 0, 0, 0);
        return date <= endDate && date >= startDate;
      }
    }
    return true;
  };

  const filterByAmount = (data) => {
    let mainValue = data?.benefits?.[0]?.amount?.base;
    if (filter.monto) {
      return mainValue == filter.monto;
    }
    return true;
  };

  const filterByState = (data) => {
    if (filter.status) {
      return (
        data?.status?.message
          .toLocaleLowerCase()
          .indexOf(filter.status.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByCountry = (data) => {
    if (filter.pais) {
      return (
        data?.product?.operator?.country?.iso_code
          .toLocaleLowerCase()
          .indexOf(filter.pais.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterBySearchTerm = (data) => {
    if (filter.telefono) {
      return (
        data?.credit_party_identifier?.mobile_number
          .toLocaleLowerCase()
          .indexOf(filter.telefono.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByTypeOfProduct = (data) => {
    if (filter.tipoProducto) {
      if (filter.tipoProducto === "saldo") {
        if (
          isCartaCorp(
            data?.product?.operator?.id,
            data?.product?.operator?.country?.iso_code,
            data?.product?.operator?.name
          )
        ) {
          return data?.product?.type === "Airtime";
        } else {
          return data?.product?.name.includes("USD");
        }
      } else {
        if (
          isCartaCorp(
            data?.product?.operator?.id,
            data?.product?.operator?.country?.iso_code,
            data?.product?.operator?.name
          )
        ) {
          return data?.product?.type !== "Airtime";
        } else {
          return !data?.product?.name.includes("USD");
        }
      }
    }
    return true;
  };
  const filterByOperador = (data) => {
    if (filter.operador) {
      return data?.product?.operator?.name.includes(filter.operador);
    }
    return true;
  };

  const getCommission = (data) => {
    const array = usuario?.commissions?.find(
      (o) =>
        o.country_code === data?.product?.operator?.country?.iso_code &&
        data?.product?.operator?.name.includes(o.operator)
    );
    return array?.commission;
  };

  const headers = [
    { label: "Fechas", key: "fecha" },
    { label: "Número de destino", key: "beneficiary.mobile_number" },
    { label: "Número de comprador", key: "sender.mobile_number" },
    { label: "País", key: "product.operator.country.name" },
    { label: "Operadora", key: "product.operator.name" },
    { label: "Producto", key: "product.name" },
    { label: "Monto", key: "monto" },
    {
      label: "Porcentaje de Comisión",
      key: "commission",
    },
    {
      label: "Monto Comision",
      key: "ganancia",
    },
    { label: "Estado", key: "status.message" },
  ];

  const { data, loading, error } = useFetchData(
    `https://us-central1-sendit-shopify-app.cloudfunctions.net/topups/allTopUpsById?limit=${limit}&id=${usuario.uid}`,
    limit
  );

  let filteredData = data
    ?.filter(filterBySearchTerm)
    .filter(filterByCountry)
    .filter(filterByState)
    .filter(filterByAmount)
    .filter(filterByOperador)
    .filter(filterByDays)
    .filter(filterByTypeOfProduct);

  excelData = filteredData?.map((info) => {
    return {
      ...info,
      monto: `$${parseFloat(info?.prices?.retail?.amount)?.toFixed(2) || 0} `,
      fecha: getDate(info?.createdAt?._seconds * 1000),
      commission: getCommission(info) ? `${getCommission(info)}%` : "--",
      ganancia: getCommission(info)
        ? `$${(getCommission(info) * info?.prices?.retail?.amount) / 100}`
        : "..",
    };
  });

  return (
    <>
      {loading && <Loading />}
      {!loading && data && !error && (
        <div>
          <Filtros
            setFilters={setFilters}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            printHistorial={printHistorial}
            name="TopUps"
            headers={headers}
            csvData={excelData}
            excelText={currentTranslate.history.excel}
          />
          {limit <= data.length && (
            <BtnFiltro onClick={() => setLimit(limit + 200)}>
              Cargar Mas
            </BtnFiltro>
          )}
          <div style={{ marginBottom: 10 }}></div>
          <HistorialRecargasWrapper>
            <table id="printTable">
              <tr>
                <TableTh long={true}>{currentTranslate.history.dates}</TableTh>
                <TableTh>{currentTranslate.history.destination}</TableTh>
                <TableTh>{currentTranslate.history.buyer}</TableTh>
                <TableTh> {currentTranslate.history.country}</TableTh>
                <TableTh> {currentTranslate.history.operator}</TableTh>
                <TableTh> {currentTranslate.history.product}</TableTh>
                <TableTh>
                  {currentTranslate.history.amountOfP}
                  {currentTranslate.history.product}
                </TableTh>
                <TableTh>
                  {currentTranslate.history.percentage} <br></br>
                  {currentTranslate.history.commissions}
                </TableTh>
                <TableTh>
                  {currentTranslate.history.amountOfP}
                  <br /> {currentTranslate.history.commission}
                </TableTh>
                <TableTh>{currentTranslate.history.status}</TableTh>
                <TableTh></TableTh>
              </tr>
              {filteredData.map((recarga) => (
                <tr>
                  <TableTd>
                    {getDate(recarga?.createdAt?._seconds * 1000)}
                  </TableTd>
                  <TableTd> {recarga?.beneficiary?.mobile_number}</TableTd>
                  <TableTd> {recarga?.sender?.mobile_number}</TableTd>
                  <TableTd>{recarga?.product?.operator?.country?.name}</TableTd>
                  <TableTd>
                    {recarga?.product?.operator?.name.replace(
                      recarga?.product?.operator?.country?.name,
                      ""
                    )}
                  </TableTd>
                  <TableTd producto={true}>
                    {isCartaCorp(
                      recarga.product?.operator?.id,
                      recarga.product?.operator?.country?.iso_code,
                      recarga.product?.operator?.name
                    ) ? (
                      <>
                        {recarga?.product?.type === "Airtime" ? (
                          <>{recarga?.product.name}</>
                        ) : (
                          <>{recarga?.product.type}</>
                        )}
                      </>
                    ) : (
                      <>{recarga?.product?.name}</>
                    )}
                  </TableTd>
                  <TableTd>
                    {recarga?.prices?.retail?.amount ? (
                      <>
                        {"$" +
                          parseFloat(recarga?.prices?.retail?.amount)?.toFixed(
                            2
                          )}
                      </>
                    ) : (
                      <>--</>
                    )}
                  </TableTd>
                  <TableTd>
                    {getCommission(recarga)
                      ? `${getCommission(recarga)}%`
                      : "--"}
                  </TableTd>
                  <TableTd>
                    {getCommission(recarga)
                      ? `$${
                          (getCommission(recarga) *
                            recarga?.prices?.retail?.amount) /
                          100
                        }`
                      : ".."}
                  </TableTd>
                  <ColoredTableTd
                    color={
                      TOPUP_STATUS[recarga?.status?.message.toLocaleLowerCase()]
                        ?.color
                    }
                  >
                    <span>
                      {
                        currentTranslate.topupStatus[
                          recarga?.status?.message.toLocaleLowerCase()
                        ]
                      }
                    </span>
                  </ColoredTableTd>
                  <TableTd>
                    <PDFDownloadLink
                      document={<Invoice info={recarga} />}
                      fileName={`Ticket-${getDate(
                        recarga?.createdAt?._seconds * 1000
                      )}`}
                    >
                      <img src={PrintIcon} />
                    </PDFDownloadLink>
                  </TableTd>
                </tr>
              ))}
            </table>
          </HistorialRecargasWrapper>
        </div>
      )}
    </>
  );
}
