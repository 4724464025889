import styled, { css } from 'styled-components';

const internoStyle = ({ interno }) => {
  if (interno) {
    return css`
      padding-bottom: 70px;
      border: none;
      @media (max-width: 384px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    `;
  }
};

export const ProductGridWrapper = styled.div`
  display: grid;
  border: 1px solid rgba(190, 66, 118, 0.5);
  grid-template-columns: 50% 50%;
  text-align: center;
  ${internoStyle}
  a {
    text-decoration: none;
  }

  @media (min-width: 384px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
