import styled from 'styled-components';
import myImage from '../../images/operadorasResp.png';

import { StyleLink } from '../StyleLink';

export const CollectionTitleWrapper = styled.div`
  height: 356px;
  display: flex;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 5px;
  margin-bottom: 60px;
  @media (max-width: 600px) {
    margin-bottom: 5%;
    margin-right: 0 !important;
    width: 100%;
    height: 276px;
  }
  @media (min-width: 1024px) {
    width: calc(33% - 20px);
    min-width: unset !important;
  }
`;

export const PromoTitleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  background: #EA6926;
  text-align: center;
  > div {
    ${StyleLink} {
      text-decoration: none;
      cursor: pointer;
      button {
        padding: 0 25px;
        color: #B73F80;
        background: white;
        width: 100%;
        max-width: 100%;
        height: 99px;
        border-radius: 15px;
        border: none;
        font-size: 43px;
        font-weight: 700px;
        text-transform: uppercase;
        @media (max-width: 600px) {
          font-size: 40px;
        }
      }
    }
  }
`;

export const CollectionTitleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  background: white;
  text-align: center;
  &:hover {
    background: linear-gradient(
      0.2deg,
      #a79aff 0.19%,
      rgba(255, 255, 255, 0) 99.85%
    );
  }
  > img {
    height: 200px;
    width: auto;
  }
  > div {
    width: 100%;
    align-self: flex-end;
    ${StyleLink} {
      text-decoration: none;
      cursor: pointer;
      button {
        color: white;
        width: 100%;
        max-width: 100%;
        height: 91px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        border: none;
        background: linear-gradient(91.18deg, #9C0A58 -0.43%, #322665 82.55%), #B73F7F;
        font-size: 48px;
        text-transform: uppercase;
        span {
          font-weight: bold;
          font-size: 16px;
        }
        &:hover {
          background: linear-gradient(180deg, #df4f46 0%, #be4276 100%), #be4276;
          border: 3px solid rgba(223, 79, 70, 0.5);
        }
        @media (max-width: 600px) {
          font-size: 40px;
        }
      }
    }
  }
  @media (max-width: 600px) {
    img {
      width: auto;
      max-width: none;
      height: 159px;
    }
  }
`;
