import styled from 'styled-components';

export const MainTable = styled.table`
  width: 100%;
  margin-top: 30px;

  tr:nth-child(odd) td {
    background: rgba(196, 196, 196, 0.2);
  }
  tr:nth-child(even) td {
    background-color: white;
  }
`;

export const TableTh = styled.th`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #656363;
  height: 20px;
  background-color: white;
  vertical-align: middle;
`;

export const TableTd = styled.td`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  width: 16%;
  height: 60px;
  color: #656363;
  text-align: center;
  vertical-align: middle;
`;

export const ActionsContainer = styled.div`
  display: flex;
  > div {
    margin: 0px 10px 10px 0px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const DeleteBtn = styled.button`
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  font-weight: 400;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
`;

export const TooltipContent = styled.div`
  padding: 10px;
`;
