import React, { useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useStaticQuery, graphql } from 'gatsby';
import { EditarVendedor } from './EditarVendedor';
import { NuevoVendedor } from './NuevoVendedor';
import Img from 'gatsby-image';
import ProductContext from 'context/ProductContext';
import { deleteEmployee, updateEmployee } from '../../../Requets';
import { FirestoreCollection } from '@react-firebase/firestore';
import { Loading } from '../../../../Loading';
import ReactLoading from 'react-loading';

import { Switch } from '../../../../Switch';
import {
  SecondaryBtn,
  Divider,
  MainTable,
  TableTh,
  TableTd,
  SearchInput,
  SubmitBtn,
  TableWrapper,
} from '../../../Inputs';
import { FaPlus } from 'react-icons/fa';
import {
  VendedoresContainer,
  BtnWrapper,
  DividerContainer,
  IconContainer,
  SwitchContainer,
} from './style';

export function Vendedores() {
  const [selectedInfo, setSelectedInfo] = useState(null);
  const [detailView, setDetailView] = useState(false);
  const [newUserView, setNewUserView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');

  const { selectedShop } = useContext(ProductContext);

  const deleteSelectedEmployee = async id => {
    setLoading(true);
    await deleteEmployee({ uid: id });
    setLoading(false);
  };

  const changeDetailView = details => {
    setSelectedInfo(details);
    setDetailView(true);
  };

  const icons = useStaticQuery(
    graphql`
      {
        trashIcon: file(relativePath: { eq: "trashIcon.png" }) {
          childImageSharp {
            fixed(height: 27, width: 23) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        editIcon: file(relativePath: { eq: "editIcon.png" }) {
          childImageSharp {
            fixed(height: 27, width: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const { control, register, handleSubmit, errors } = useForm();

  const filterBySearchTerm = data => {
    if (filter.searchTerm) {
      return (
        data?.username
          .replace(/ /g, '')
          .toLocaleLowerCase()
          .indexOf(filter.searchTerm.replace(/ /g, '').toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };
  const filterByName = data => {
    if (filter.searchTerm) {
      return (
        data?.name
          .replace(/ /g, '')
          .toLocaleLowerCase()
          .indexOf(filter.searchTerm.replace(/ /g, '').toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const onSubmit = data => {
    setFilter(data);
  };

  return (
    <FirestoreCollection
      path="/employees/"
      where={{
        field: 'shop_id',
        operator: '==',
        value: selectedShop.uid,
      }}
      // orderBy={{
      //   field: 'created_at',
      // }}
    >
      {data => {
        let filteredData = data?.value
          ?.filter(filterByName)
          ?.filter(filterBySearchTerm);

        // order by date
        if (filteredData) {
          filteredData.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
        }

        return data.isLoading ? (
          <Loading />
        ) : (
          <VendedoresContainer>
            {detailView && (
              <EditarVendedor
                details={selectedInfo}
                setDetailView={setDetailView}
              />
            )}
            {newUserView && <NuevoVendedor display={setNewUserView} />}
            {!newUserView && !detailView && (
              <div>
                <BtnWrapper>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Controller
                        as={<SearchInput placeholder="Búsqueda de usuario" />}
                        name="searchTerm"
                        control={control}
                        defaultValue=""
                      />
                      <SubmitBtn>Buscar</SubmitBtn>
                    </form>
                  </div>
                  <SecondaryBtn onClick={() => setNewUserView(true)}>
                    <FaPlus size="1.0em" />
                    {` Nuevo Usuario`}
                  </SecondaryBtn>
                </BtnWrapper>
                <DividerContainer>
                  <Divider width="90%" />
                </DividerContainer>
                <TableWrapper>
                  <MainTable>
                    <thead>
                      <tr>
                        <TableTh>Usuario</TableTh>
                        <TableTh>Nombre</TableTh>
                        <TableTh>Activo</TableTh>
                        <TableTh>Editar</TableTh>
                        <TableTh>Eliminar</TableTh>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData?.map(value => (
                        <tr>
                          <TableTd>{value.username}</TableTd>
                          <TableTd>{value.name}</TableTd>
                          <TableTd>
                            <SwitchContainer>
                              <Switch
                                id={`switch ${value.uid}`}
                                color={'#7DE59A'}
                                width={'52px'}
                                marginLeft={'57%'}
                                value={value.active}
                                customFunction={updateEmployee}
                                customFunctionActive={true}
                                paramsCustomFunction={{
                                  uid: value.uid,
                                  active: !value.active,
                                }}
                              />
                            </SwitchContainer>
                          </TableTd>
                          <TableTd>
                            <IconContainer
                              onClick={() => changeDetailView(value)}
                            >
                              <Img
                                fixed={icons.editIcon?.childImageSharp.fixed}
                                loading="auto"
                              />
                            </IconContainer>
                          </TableTd>
                          <TableTd>
                            <IconContainer
                              onClick={() => deleteSelectedEmployee(value.uid)}
                            >
                              {loading ? (
                                <ReactLoading type="bubbles" color="#e2513a" />
                              ) : (
                                <Img
                                  fixed={icons.trashIcon?.childImageSharp.fixed}
                                  loading="auto"
                                />
                              )}
                            </IconContainer>
                          </TableTd>
                        </tr>
                      ))}
                    </tbody>
                  </MainTable>
                </TableWrapper>
              </div>
            )}
          </VendedoresContainer>
        );
      }}
    </FirestoreCollection>
  );
}
