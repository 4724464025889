import styled, { css } from 'styled-components';

const activeTab = ({ active }) => {
  if (active) {
    return css`
      color: white;
      border-radius: 5px;
      background: linear-gradient(
        269.91deg,
        #7a27f0 0%,
        #cb01d7 99.96%
      ) !important;
    `;
  }
};

export const TabWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  @media (max-width: 1000px) {
    height: auto;
    padding-top: 61px;
    justify-content: flex-start;
  }
`;

export const Tab = styled.div`
  width: 840px;
  padding: 5px;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    margin-bottom: 13px;
  }
  @media (max-width: 1000px) {
    height: auto;
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 12px;

    grid-template-columns: 40% 40%;
    div:first-child {
      width: 102px;
    }
  }
`;

export const TabTitle = styled.div`
  height: 52px;
  margin-bottom: 13px;
  margin-right: 11px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8423ed;
  font-weight: bold;
  background: #ffffff;
  padding: 15px;

  ${activeTab}
  cursor: pointer;
  @media (max-width: 1000px) {
    background: #f2f2f2;
    border-radius: 5px;
    width: 200px;
    height: 52px;
  }
`;
