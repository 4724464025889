import React, { useState, useContext } from "react";
import ProductContext from "../../../context/ProductContext";
import { Filtros } from "./Filtros";
import { Table } from "../Table";
import { InnerPages } from "./InnerPages";
import { Divider, TiendasWrapper, TableWrapper } from "./style";
import { Loading } from "../../Loading";
import { CrearTienda } from "./InnerPages/CrearTienda";
import { FirestoreCollection } from "@react-firebase/firestore";

export function Tiendas({
  innerPage,
  setInnerPage,
  createdShop,
  setCreatedShop,
}) {
  const { setSelectedShop, selectedShop, setShopList } = useContext(
    ProductContext
  );
  const [filter, setFilters] = useState("");

  const headers = [
    { title: "Nombre", key: "name" },
    { title: "Ubicación", key: "location-state" },
    { title: "Email", key: "email" },
    { title: "Saldo Actual", key: "current-balance" },
    { title: "Estado", key: "state" },
    { title: "", key: "action-menu" },
  ];

  const filterByName = (data) => {
    if (filter.searchName) {
      return (
        data?.name
          .toLocaleLowerCase()
          .indexOf(filter.searchName.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByLocation = (data) => {
    if (filter.location) {
      return (
        data?.location?.state
          .toLocaleLowerCase()
          .indexOf(filter.location.toLocaleLowerCase()) >= 0
      );
    }
    return true;
  };

  const filterByStatus = (data) => {
    if (filter.status) {
      if (data.active.toString() === filter.status) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      {innerPage ? (
        <InnerPages selectedInfo={selectedShop} />
      ) : (
        <TiendasWrapper>
          {createdShop ? (
            <CrearTienda />
          ) : (
            <TableWrapper>
              <Filtros
                setFilters={setFilters}
                setCreatedShop={setCreatedShop}
              />
              <Divider />
              <FirestoreCollection
                path="/users/"
                where={{
                  field: "roles",
                  operator: "array-contains",
                  value: "shop-admin",
                }}
              >
                {(data) => {
                  let tempData = data?.value
                    ?.map((value) => {
                      let { state } = value.location;
                      let { current } = value.balance;
                      let active = value.active ? "activa" : "inactiva";
                      value["location-state"] = state;
                      value["current-balance"] = current;
                      value["state"] = active;
                      return value;
                    })
                    ?.filter(filterByName)
                    ?.filter(filterByLocation)
                    ?.filter(filterByStatus);

                  // order by date
                  if (tempData) {
                    tempData.sort(
                      (a, b) => b.created_at.toDate() - a.created_at.toDate()
                    );
                  }
                  return data.isLoading ? (
                    <Loading />
                  ) : (
                    <Table
                      headers={headers}
                      tableData={tempData}
                      setInnerPage={setInnerPage}
                      innerPage={innerPage}
                      setSelectedInfo={setSelectedShop}
                      fixed={false}
                    />
                  );
                }}
              </FirestoreCollection>
            </TableWrapper>
          )}
        </TiendasWrapper>
      )}
    </div>
  );
}
