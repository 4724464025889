import React from 'react';
import Image from 'gatsby-image';
import { ImageThumbailWrapper } from './style';

export default function ImageThumbails({ isActive, onClick, image }) {
  const handleClick = () => {
    onClick(image);
  };
  if (!image.localFile) return null;
  return (
    <ImageThumbailWrapper onClick={handleClick} isActive={isActive}>
      <Image fluid={image.localFile.childImageSharp.fluid} loading="auto" />
    </ImageThumbailWrapper>
  );
}
