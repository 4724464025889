import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  padding: 30px;
  border-bottom: 1px solid #c4c4c4;
`;

export const Content = styled.div`
  padding: 60px 0px 60px 0px;
  background-color: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 20px;
  height: 100%;
  form {
    width: 649px;
    overflow: scroll;
    max-height: 700px;
  }
`;

export const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #656363;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlantillaWrapper = styled.div`
  height: calc(100% - 300px);
`;
