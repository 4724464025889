import React, { useState, useContext } from "react";
import ProductContext from "context/ProductContext";
import { Input, FormWrapper } from "./style";
import {
  getNumberRecord,
  getProductList,
  lookup,
} from "../../Recargas/requests";
import { CARTA_CORP_PRODUCT_LIST } from "../../Recargas/Step2/const";
import { isCartaCorp } from "../../Recargas/utils";
import ReactLoading from "react-loading";

export function Search({ setInputs, setBuyer }) {
  const { currentTranslate } = useContext(ProductContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [undefinedPackages, setUndefinedPackages] = useState([]);

  async function fetchDtOneProducts(codigoPais, operatorId) {
    const response = await getProductList(codigoPais, operatorId);
    let filteredData = [];
    if (response.errors) {
      // setError(currentTranslate.topUpBtoB.error);
    } else {
      filteredData = response.filter((value) => {
        let { amount } = value.destination;
        if (value.benefits[0].additional_information) {
          return value;
        } else {
          return (amount === 7 || amount % 5 == 0) && amount <= 30;
        }
      });
    }
    return filteredData;
  }

  function getCartaCorpProducts(codigoPais) {
    return CARTA_CORP_PRODUCT_LIST[codigoPais];
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInputs([]);
    setLoading(true);
    const number = e.target.value;
    const record = await getNumberRecord(number);
    const carriers = {};
    let inputs = [];
    setBuyer(number);

    // order by date
    // if (record) {
    //   record.sort(
    //     (a, b) => b.createdAt.toDate() - a.createdAt.toDate()
    //   );
    // }

    record.forEach((value) => {
      let { beneficiary, product } = value;
      let { operator } = product;
      let codigoPais = operator.country.iso_code;
      let { id } = operator;

      carriers[id] = {
        codigoPais,
        productos: {},
      };

      let input = {
        active: false,
        celular: beneficiary.mobile_number,
        nombre: beneficiary.first_name,
        codigoPais,
        operadora: {
          id: operator.id,
          name: operator.name,
        },
        productos: [],
        comprador: number,
      };
      inputs.push(input);
    });

    let opIds = Object.keys(carriers);

    Promise.all(
      opIds.map(async (id) => {
        if (isCartaCorp(id, carriers[id].codigoPais)) {
          carriers[id].productos = getCartaCorpProducts(
            carriers[id].codigoPais
          );
        } else {
          carriers[id].productos = await fetchDtOneProducts(
            carriers[id].codigoPais,
            id
          );
        }
      })
    ).then(() => {
      inputs.forEach((input, idx) => {
        let selectedCarrier = carriers[input.operadora.id];
        input.productos = selectedCarrier.productos;
        input.price = record[idx].prices.retail?.amount;

        if (
          isCartaCorp(
            input.operadora.id,
            input.codigoPais,
            input.operadora.name
          )
        ) {
          let countryProducts =
            CARTA_CORP_PRODUCT_LIST[selectedCarrier.codigoPais];

          let paquete = countryProducts.productTypes.find(
            (prod) => prod.acronym === record[idx].product.type
          );

          if (paquete) {
            let { type, acronym, amounts, descriptions } = paquete;
            let amount = amounts.find(
              (amount) => amount == record[idx].prices.retail?.amount
            );

            let index = amounts.indexOf(amount);
            let benefits = descriptions
              ? descriptions[index]?.split(";")
              : [`${acronym} $${amount}`];
            if (!amount) {
              input.paquete = null;
            } else {
              input.paquete = {
                id: countryProducts.id,
                amount,
                type,
                acronym,
                descriptions,
                index,
              };
            }
            input.benefits = benefits;
          } else {
            input.paquete = null;
          }
        } else {
          let paquete = selectedCarrier.productos.find(
            (p) => p.id === record[idx].product.id
          );
          input.paquete = paquete.id;
          input.benefits =
            paquete?.description.split(",").length > 1
              ? paquete?.description.split(",")
              : paquete?.name.split(",");
        }
      });

      const tempInputs = inputs.filter((value) => value?.paquete);
      setLoading(false);
      setInputs(tempInputs);
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      handleSubmit(event);
    }
  };

  const handleBuyer = (e) => {
    const number = e.currentTarget.value;
    setSearchTerm(number);
    setBuyer(number);
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      {loading && <ReactLoading type="bubbles" color="#e2513a" />}
      <Input
        placeholder={currentTranslate.topUpBtoB.search}
        value={searchTerm}
        onChange={handleBuyer}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        disabled={loading}
        type="number"
        min="0"
      />
    </FormWrapper>
  );
}
