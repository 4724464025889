import React, { useContext, useState } from 'react';
import { Toast } from 'components';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { navigate } from '@reach/router';
import { createCheckout } from '../CartContent/requests'
import CartContext from 'context/CartContext';
import ProductContext from 'context/ProductContext';

import { StyleLink } from '../StyleLink';
import {
  ProductTitleWrapper,
  Title,
  Description,
  Price,
  InfoContainer,
  Overlay,
  Button,
} from './style';

export function ProductTitle({
  title,
  imgFluid,
  description,
  minPrice,
  handle,
  idCart,
  interno,
}) {
  const { updateLineItem,checkout } = useContext(CartContext);
  const { currentTranslate,usuario } = useContext(ProductContext);

  const [available, setAvailable] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "cart.png" }) {
        childImageSharp {
          fixed(width: 33, height: 34) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const buyItem=async e=>{
    setAvailable(false);
    e.stopPropagation();
    function getToken(url) {
      const tmp1 = url.split('/checkouts/');
      const tmp2 = tmp1[1];
      const tmp3 = tmp2.split('?');
      const token = tmp3[0];
      return token;
    }

    if (usuario) {
      const { webUrl } = checkout;
      const data = {
        checkout_token: getToken(webUrl),
        uid: usuario.uid,
      };
      if (usuario.shop_id) {
        data['shop_id'] = usuario.shop_id;
      }
      await createCheckout(data);
    }
    updateLineItem({ quantity: 1, variantId: idCart, buy: true });
  }

  return (
    <ProductTitleWrapper
      interno={interno}
      onClick={() => {
        navigate(`/producto/${handle}`);
      }}
    >
      <Overlay id="overlay-container">
        <Button
          disabled={!available}
          onClick={e => buyItem(e)}
        >
          {currentTranslate.btnBuy.message}
        </Button>
        <Toast isOpen={isOpen} setIsOpen={setIsOpen} time={3000} />
        <Button
          onClick={e => {
            e.stopPropagation();
            updateLineItem({ quantity: 1, variantId: idCart });
            setIsOpen(true);
          }}
        >
          <Img
            fixed={data.file.childImageSharp.fixed}
            loading="auto"
            id="btn-icon"
          />
        </Button>
      </Overlay>
      <Img fluid={imgFluid} loading="auto" />
      <InfoContainer>
        <Price>$ {parseFloat(minPrice).toFixed(2)}</Price>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </InfoContainer>
    </ProductTitleWrapper>
  );
}
