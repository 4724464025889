import React, { useState } from 'react';
import { Header } from './Header';
import { LeftMenu } from './LeftMenu';
import { Tiendas } from './Tiendas';
import { EditarCuenta } from './EditarCuenta';
import { Historial } from './Historial';
import { ConentContainer } from './style';
export function AdminPanel() {
  const [page, setPage] = useState('TIENDAS');
  const [innerPage, setInnerPage] = useState(false);
  const [selected, SetSelected] = useState(3);
  const [subMenuSelected, setSubMenuSelected] = useState(1);
  const [createdShop, setCreatedShop] = useState(false);
  return (
    <div>
      <Header title={page}></Header>
      <LeftMenu
        setPage={setPage}
        setInnerPage={setInnerPage}
        selected={selected}
        SetSelected={SetSelected}
        subMenuSelected={subMenuSelected}
        setSubMenuSelected={setSubMenuSelected}
        setCreatedShop={setCreatedShop}
      />
      <ConentContainer>
        {selected === 2 && <EditarCuenta />}
        {selected === 3 && (
          <Tiendas
            innerPage={innerPage}
            setInnerPage={setInnerPage}
            setCreatedShop={setCreatedShop}
            createdShop={createdShop}
          />
        )}
        {selected === 4 && <Historial subMenuSelected={subMenuSelected} />}
      </ConentContainer>
    </div>
  );
}
