import React, { useState, useContext, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ProductContext from "../../../context/ProductContext";
import { Tooltip } from "../../Tooltip";

import Img from "gatsby-image";
import { Button } from "../../Button";
import { Switch } from "../../Switch";
import {
  buyTime,
  transaction,
  sendSms,
  loginCartaCorp,
} from "../../Recargas/requests";
import { isCartaCorp } from "../../Recargas/utils";

import {
  RecargasSumaryWrapper,
  TableTd,
  MainTable,
  SelectWrapper,
  Footer,
  TotalTitle,
  MobileDeleteWrapper,
  FlexContainer,
  InfoContainer,
  TooltipContent,
} from "./style";
import { ErrorElement } from "../../Recargas/error";
import { ColoredTableTd } from "../../AdminPanel/Inputs";
import { COUNTRIES, TOPUP_STATUS } from "../../Consts";
import { Loading } from "../../Loading";

export function RecargasSumary({ setInputs, inputs, buyer }) {
  const [selectAll, setSelectAll] = useState(false);
  const { usuario, setUsuario, permisos, currentTranslate } = useContext(
    ProductContext
  );

  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState();
  const [processing, setProcessing] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [smsError, setSmsError] = useState([]);

  const icons = useStaticQuery(
    graphql`
      {
        deleteIcon: file(relativePath: { eq: "Basurero.png" }) {
          childImageSharp {
            fixed(width: 27, height: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        infoIcon: file(relativePath: { eq: "InfoIcon.png" }) {
          childImageSharp {
            fixed(width: 6, height: 33) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );
  useEffect(() => {
    setProcessing([]);
    setErrors([]);
    setStatuses([]);
    verifyInputs();
    calculateTotal();
  }, [inputs]);

  const sendSmsToUser = async (statuses, comprador) => {
    let completeMessage = "";
    let formatedCompradorNumber = `+${comprador}`;
    let validInputs = inputs.filter((value) => value.active === true);
    let procesedInputs = [];
    validInputs.map((value, index) => {
      if (
        TOPUP_STATUS[statuses[index]?.toLocaleLowerCase()]?.message ===
        "Confirmada"
      ) {
        procesedInputs.push(value);
      }
    });

    if (procesedInputs.length > 0) {
      completeMessage = `Gracias por tu compra en tienda ${usuario.name} la recarga para el numero ${procesedInputs[0].celular} se realizo de forma exitosa.Gracias por utilizar el servicio de Sendit`;
      let body = {
        to: formatedCompradorNumber,
        body: completeMessage,
      };
      let result = await sendSms(body);
      /*
      if (result.toString().includes("Error")) {
        let message = `No pudo enviarse el sms para la recarga correspondiente al numero ${procesedInputs[0].celular}`;
        setSmsError([...smsError, message]);
      }/*/
    }
  };

  const calculateTotal = () => {
    let subtotal = 0;
    let newTotal = inputs
      .filter((value) => value.active === true)
      .reduce((a, b) => {
        if (!b.paquete) return a;
        let packageAmount = a + (parseFloat(b.price) || 0);
        let commissionInfo = usuario.commissions?.find(
          (c) =>
            b.operadora.name.includes(c.operator) &&
            c.country_code === b.codigoPais
        );
        let commission = commissionInfo
          ? (b.price * commissionInfo.commission) / 100
          : 0;
        let total = packageAmount - commission;
        subtotal += parseFloat(b.price);
        return total;
      }, 0);
    setSubTotal(subtotal);
    setTotal(newTotal);
  };

  const getPriceByComission = (amount, operator, country) => {
    const commissionInfo = usuario.commissions?.find(
      (c) => operator.includes(c.operator) && c.country_code === country
    );
    const commission = commissionInfo
      ? (amount * commissionInfo.commission) / 100
      : 0;
    return amount - commission;
  };

  const deleteItem = (id) => {
    let tempData = inputs;
    tempData.splice(id, 1);
    setInputs([...tempData]);
    calculateTotal();
  };

  const updateItem = (id) => {
    let tempData = inputs;
    tempData[id].active = !tempData[id].active;
    setInputs([...tempData]);
    calculateTotal();
  };

  const updateBalance = (current) => {
    const newUserData = {
      ...usuario,
      balance: {
        current,
        minumim: usuario.balance.minimum,
        spent: usuario.balance.spent + total,
      },
    };
    if (!permisos?.admin) {
      setUsuario(newUserData);
    }
  };

  const handleCartaCorpProducts = (e, id, p) => {
    let paqueteInfo = JSON.parse(e.target.value);
    let tempData = inputs;
    let index = paqueteInfo.index;
    tempData[id].paquete = paqueteInfo;
    const newPrice = paqueteInfo.amount || 0;
    tempData[id].price = newPrice;
    tempData[id].benefits = paqueteInfo.descriptions
      ? paqueteInfo.descriptions[index].split(";")
      : [`${paqueteInfo.acronym} $${newPrice}`];

    setInputs([...tempData]);
    calculateTotal();
  };

  const handleDtOneProducts = (e, id) => {
    let tempData = inputs;
    tempData[id].paquete = e.target.value;

    let tempValue = tempData[id].productos.filter(
      (value) => value.id == e.target.value
    );

    tempData[id].benefits =
      tempValue[0]?.description.split(",").length > 1
        ? tempValue[0]?.description.split(",")
        : tempValue[0]?.name.split(",");

    const newPrice = tempValue[0]?.prices?.retail?.amount || 0;
    tempData[id].price = newPrice;

    setInputs([...tempData]);
    calculateTotal();
  };

  const verifyInputs = () => {
    const activeTopups = inputs.filter((value) => value.active === true);
    const packagesSelected = inputs.reduce((a, b) => {
      return a && b.paquete && b.paquete !== "";
    }, true);

    const ready = activeTopups.length > 0 && packagesSelected;
    setIsReady(ready);
  };

  const onSubmit = async (e) => {
    //new login for cartacorp tigo
    await loginCartaCorp();
    e.preventDefault();

    let requestBody = [];
    let statuses = [];
    let processing = [];
    const { balance } = usuario;
    const finalBalance = balance.current - total;

    if (!permisos?.admin) {
      if (finalBalance < 0) {
        setError("Crédito insuficiente para realizar todas las recargas");
        return;
      }
    }

    inputs.map(async (recargaInfo, index) => {
      if (!recargaInfo.active) return;

      let result = {};
      const {
        celular,
        nombre,
        codigoPais,
        paquete,
        price,
        operadora,
        comprador,
      } = recargaInfo;
      processing[index] = true;

      const beneficiary = {
        mobile_number: celular,
      };

      if (nombre?.length > 0) {
        beneficiary["first_name"] = nombre;
      } else {
        //beneficiary["first_name"] = "";
      }
      let commissionInfo = usuario.commissions?.find(
        (c) =>
          operadora.name.includes(c.operator) && c.country_code === codigoPais
      );
      if (!commissionInfo) {
        setProcessing(false);
        setError(
          "Este usuario no posee una comision para la operadora selecionado,por favor contactar al encargado para actualizar comisiones"
        );
      }
      let commission = (price * 0.1) / 100;
      let total = commission ? price - commission : price;

      if (isCartaCorp(operadora.id, codigoPais, operadora.name)) {
        const { type, acronym } = recargaInfo.paquete;
        const payload = {
          countryCode: codigoPais,
          countryName: COUNTRIES[codigoPais].name || "",
          productCode: paquete.id,
          telephone: celular,
          amount: price,
          type,
          acronym,
          phone: usuario.telephone,
          beneficiary,
          uid: usuario.uid,
          shop_id: usuario.uid,
          shop_email: usuario.email,
          total,
        };

        if (comprador) {
          payload["sender"] = {
            mobile_number: `+${comprador}`,
          };
        }

        try {
          requestBody.push(payload);
          result = await buyTime(payload);
          processing[index] = false;
        } catch (error) {
          processing[index] = false;
          result["errors"] = error;
        }
      } else {
        const payload = {
          productId: paquete,
          beneficiary,
          shop_email: usuario.email,
        };

        if (comprador) {
          payload["sender"] = {
            mobile_number: `+${comprador}`,
          };
        }

        result = await transaction(
          paquete,
          payload["sender"],
          beneficiary,
          usuario.email,
          total
        );
        requestBody.push(payload);
        processing[index] = false;
      }
      //error handler
      if (result.errors) {
        errors[index] = errors;
      } else {
        statuses[index] = result.message;
        updateBalance(finalBalance);
        sendSmsToUser(statuses, buyer);
        let tempData = inputs;
        tempData[index].active = false;
        tempData[index].status = statuses[index];
        setInputs([...tempData]);
        calculateTotal();
      }
    });

    setStatuses(statuses);
    setProcessing(processing);
    setErrors(errors);
  };

  const clearInputs = () => {
    if (selectAll) {
      setInputs([]);
      setTotal(0);
      setSmsError([]);
    }
  };

  const clean = () => {
    setSmsError([]);
    setInputs([]);
  };

  return (
    <RecargasSumaryWrapper>
      <MobileDeleteWrapper>
        <FlexContainer>
          <input
            type="checkbox"
            id="selectAll"
            name="selectAll"
            value={selectAll}
            onChange={(e) => setSelectAll(e.target.checked)}
          />
          <p> Seleccionar todos</p>
        </FlexContainer>
        <div onClick={clearInputs}>
          <Img fixed={icons.deleteIcon?.childImageSharp.fixed} loading="auto" />
        </div>
      </MobileDeleteWrapper>
      <MainTable>
        <tbody>
          {inputs?.map((value, index) => (
            <tr key={index}>
              <TableTd>{value.celular}</TableTd>

              <TableTd>{value.nombre || "-------"}</TableTd>

              <TableTd>{COUNTRIES[value.codigoPais].name}</TableTd>
              <TableTd>{value.operadora?.name}</TableTd>
              <TableTd width="50px">
                {isCartaCorp(
                  value?.operadora?.id,
                  value.codigoPais,
                  value?.operadora?.name
                ) ? (
                  <FlexContainer>
                    <SelectWrapper
                      onChange={(e) => {
                        handleCartaCorpProducts(e, index, value);
                      }}
                      value={JSON.stringify(value?.paquete)}
                    >
                      <option value={JSON.stringify({})}>Paquetes</option>
                      {value?.productos?.productTypes?.map((type) =>
                        type.amounts.map((amount, idx) => (
                          <option
                            value={JSON.stringify({
                              id: value?.productos?.id,
                              amount,
                              type: type.type,
                              acronym: type.acronym,
                              descriptions: type.descriptions,
                              index: idx,
                            })}
                            key={idx}
                          >
                            {type.acronym} {amount} USD
                          </option>
                        ))
                      )}
                    </SelectWrapper>
                    <Tooltip
                      content={
                        <TooltipContent>
                          {value?.benefits?.map((value) => (
                            <li>
                              <span>{value}</span>
                            </li>
                          ))}
                        </TooltipContent>
                      }
                      direction="top"
                      table={true}
                    >
                      <InfoContainer>
                        <Img
                          fixed={icons.infoIcon?.childImageSharp.fixed}
                          loading="auto"
                          style={{ cursor: "pointer" }}
                        />
                      </InfoContainer>
                    </Tooltip>
                  </FlexContainer>
                ) : (
                  <FlexContainer>
                    <SelectWrapper
                      onChange={(e) => {
                        handleDtOneProducts(e, index);
                      }}
                      value={value?.paquete}
                    >
                      <option value="">Paquetes</option>
                      {value?.productos
                        ?.filter((value) => value?.prices?.retail?.amount)
                        ?.map((paquete) => (
                          <option value={paquete.id} key={paquete.id}>
                            {paquete.name}
                            {paquete.benefits[0].type !== "CREDITS"
                              ? ` (${paquete.prices?.retail?.amount || 0} USD)
                              `
                              : ""}
                          </option>
                        ))}
                    </SelectWrapper>
                    <Tooltip
                      content={
                        <TooltipContent>
                          <p>
                            {value?.benefits?.map((value) => (
                              <li>{value}</li>
                            ))}
                          </p>
                        </TooltipContent>
                      }
                      direction="top"
                      table={true}
                    >
                      <InfoContainer>
                        <Img
                          fixed={icons.infoIcon?.childImageSharp.fixed}
                          loading="auto"
                          style={{ cursor: "pointer" }}
                        />
                      </InfoContainer>
                    </Tooltip>
                  </FlexContainer>
                )}
              </TableTd>
              <TableTd width="50px">
                <Switch
                  id={`checkbox ${index}`}
                  value={value.active}
                  customFunction={updateItem}
                  customFunctionActive={true}
                  paramsCustomFunction={index}
                  customFlag={true}
                />
              </TableTd>
              <TableTd>
                <div onClick={() => deleteItem(index)}>
                  <Img
                    fixed={icons.deleteIcon?.childImageSharp.fixed}
                    loading="auto"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </TableTd>
              {errors[index] ? (
                <TableTd>
                  <ErrorElement>{errors[index]}</ErrorElement>
                </TableTd>
              ) : processing[index] === true ? (
                <TableTd>
                  <Loading />
                </TableTd>
              ) : (
                <ColoredTableTd
                  color={
                    value?.status
                      ? TOPUP_STATUS[value?.status?.toLocaleLowerCase()]?.color
                      : TOPUP_STATUS[statuses[index]?.toLocaleLowerCase()]
                          ?.color
                  }
                >
                  <span style={{ fontSize: 28 }}>
                    {value?.status
                      ? TOPUP_STATUS[value?.status?.toLocaleLowerCase()]
                          ?.message
                      : TOPUP_STATUS[statuses[index]?.toLocaleLowerCase()]
                          ?.message}
                  </span>
                </ColoredTableTd>
              )}
            </tr>
          ))}
        </tbody>
      </MainTable>
      <Footer>
        <div>
          <TotalTitle>{`${currentTranslate.topUpBtoB.total} $${subTotal.toFixed(
            2
          )}`}</TotalTitle>
          {inputs.length > 0 && (
            <div>
              <Button onClick={(e) => onSubmit(e)} disabled={!isReady}>
                {currentTranslate.topUpBtoB.topUpBtn}
              </Button>
              <Button onClick={() => clean()} disabled={!isReady}>
                Recargar a otro número
              </Button>
            </div>
          )}
        </div>
        <div>
          <ErrorElement>{error}</ErrorElement>
          {smsError &&
            smsError.map((value) => <ErrorElement>{value}</ErrorElement>)}
        </div>
      </Footer>
    </RecargasSumaryWrapper>
  );
}
