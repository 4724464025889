import React from 'react';
import { CSVLink } from 'react-csv';
import { ExcelWrapper } from './style';

export function ExcelGenerator({ name, headers, csvData, text }) {
  if (!csvData) return null;
  return (
    <ExcelWrapper>
      <CSVLink
        data={csvData}
        headers={headers}
        filename={`${name}- ${new Date().toLocaleDateString()}.csv`}
      >
        <button>{text}</button>
      </CSVLink>
    </ExcelWrapper>
  );
}
