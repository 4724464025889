import React, { useContext } from 'react';
import ProductContext from 'context/ProductContext';
import { ProductsGrid } from '../ProductsGrid';
import { Title } from './style';
export function FeaturedProducts({ products, interno }) {
  const { currentTranslate } = useContext(ProductContext);
  let flag = [];
  let temp = products.map(node => {
    return node.node;
  });

  temp.map(item => {
    if (item.images.length >= 1) {
      flag.push(1);
    }
  });

  return (
    <section>
      {flag.length >= 1 && (
        <div>
          <Title>{currentTranslate.relatedProduct.message}</Title>
          <ProductsGrid products={temp} interno={interno} />
        </div>
      )}
    </section>
  );
}
