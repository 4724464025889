import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { SliderContainer, ImageContainer } from './style';

export function ResponsiveSlider({ selectedVariantImageId, images }) {
  const [activeImageThumbail, setActiveImageThumbail] = useState(
    images.find(({ id }) => id === selectedVariantImageId) || images[0]
  );
  const [index, setIndex] = useState(
    images.findIndex(value => value.id == selectedVariantImageId)
  );
  let imagesLimit = images.length - 1;

  let localFile = activeImageThumbail ? activeImageThumbail.localFile : null;

  useEffect(() => {
    setActiveImageThumbail(
      images.find(({ id }) => id === selectedVariantImageId) || images[0]
    );
    setIndex(images.findIndex(value => value.id == selectedVariantImageId));
  }, [selectedVariantImageId, images, setActiveImageThumbail]);

  const handleClickNext = () => {
    if (index >= imagesLimit) {
      setActiveImageThumbail(images[0]);
      setIndex(0);
    } else {
      setIndex(index + 1);
      setActiveImageThumbail(images[index + 1]);
    }
  };

  const handleClickPrev = () => {
    let total = images.length - 1;
    if (index === 0) {
      setActiveImageThumbail(images[total]);
      setIndex(total);
    } else {
      setIndex(index - 1);
      setActiveImageThumbail(images[index - 1]);
    }
  };

  const { izquierdaArrow, derechaArrow } = useStaticQuery(
    graphql`
      {
        izquierdaArrow: file(relativePath: { eq: "flecha_dos.png" }) {
          childImageSharp {
            fixed(width: 16, height: 39) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        derechaArrow: file(relativePath: { eq: "flecha_uno.png" }) {
          childImageSharp {
            fixed(width: 16, height: 39) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  return (
    <SliderContainer>
      <div onClick={handleClickPrev}>
        <Img fixed={izquierdaArrow.childImageSharp.fixed} loading="auto" />
      </div>
      <ImageContainer image={localFile?.childImageSharp.fluid.src} />
      <div onClick={handleClickNext}>
        <Img fixed={derechaArrow.childImageSharp.fixed} loading="auto" />
      </div>
    </SliderContainer>
  );
}
