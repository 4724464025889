import React, { useContext } from 'react';
import { HOME_INFORMATION } from '../Consts';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import {
  GridLeft,
  GridRight,
  Header,
  SubHeading,
  InformationContainer,
  BigBullet,
  InfoTitleLeft,
  InfoTitleRight
} from './style';
import ProductContext from '../../context/ProductContext';

export function InformativeSection() {
  const { currentTranslate } = useContext(ProductContext);

  const backgroundStyleRight = {
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center right'
  };
  const backgroundStyleLeft = {
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center left'
  };
  const { btob, btoc } = useStaticQuery(
    graphql`
      {
        btob: file(relativePath: { eq: "btob.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        btoc: file(relativePath: { eq: "btoc.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <InformationContainer>
      <GridRight>
        <BackgroundImage
          loading="auto"
          fluid={btob.childImageSharp.fluid}
          style={backgroundStyleRight}
        />

        <div>
          {HOME_INFORMATION[0].sections.map(section => (
            <div>
              <InfoTitleLeft>
                <BigBullet /><Header>{currentTranslate.home[section.translationTitleKey]}</Header>
              </InfoTitleLeft>
              <SubHeading>{currentTranslate.home[section.translationDescKey]}</SubHeading>
            </div>
          ))}
        </div>
      </GridRight>
      <GridLeft>
        <BackgroundImage
          fluid={btoc.childImageSharp.fluid}
          style={backgroundStyleLeft}
        />

        <div>
          {HOME_INFORMATION[1].sections.map(section => (
            <div>
              <InfoTitleRight>
                <Header>{currentTranslate.home[section.translationTitleKey]}</Header><BigBullet />
              </InfoTitleRight>
              <SubHeading right={true}>{currentTranslate.home[section.translationDescKey]}</SubHeading>
            </div>
          ))}
        </div>
      </GridLeft>
    </InformationContainer>
  );
}
