import React, { useState, useEffect, useContext } from 'react';
import ProductContext from 'context/ProductContext';
import { useDebouncedCallback } from 'use-debounce';
import ReactLoading from 'react-loading';
import { COUNTRIES } from '../../Consts';
import {
  Title,
  FormContainer,
  InputNumber,
  InputText,
  RecargasContainer,
  LoadingContainer,
  ErrorContainer,
} from './style';
import { Button } from '../../Button';
import { getProductList, lookup } from '../../Recargas/requests';
import { CARTA_CORP_PRODUCT_LIST } from '../../Recargas/Step2/const';
import { isCartaCorp } from '../../Recargas/utils';
import { ErrorElement } from '../../Recargas/error';

export function RecargasForm({ setInputs, inputs, buyer }) {
  const { currentTranslate } = useContext(ProductContext);
  const [operadora, setOperadora] = useState({});
  const [codigoPais, setCodigoPais] = useState('');
  const [paquetes, setPaquetes] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [fields, setFields] = useState({
    celular: '',
    nombre: '',
    pais: '',
    operadora: '',
    codigoPais: '',
    productos: [],
    active: true,
    comprador: buyer,
  });

  useEffect(() => {
    if (!fields.celular) return;
    setOperadora({});
    setCodigoPais('');
    debouncedValidation.callback();
  }, [fields.celular]);

  useEffect(() => {
    async function fetchDtOneProducts() {
      const response = await getProductList(codigoPais, operadora.id);
      if (response.errors) {
        setError(currentTranslate.topUpBtoB.error);
      } else {
        let filteredData = response.filter(value => {
          let { amount } = value.destination;
          if (value.benefits[0].additional_information) {
            return value;
          } else {
            return (amount === 7 || amount % 5 == 0) && amount <= 30;
          }
        });
        setPaquetes(filteredData);
      }
      setLoading(false);
    }

    function getCartaCorpProducts() {
      const products = CARTA_CORP_PRODUCT_LIST[codigoPais];
      setPaquetes(products);
      setLoading(false);
    }

    if (!operadora.id) return;
    setPaquetes(null);
    setLoading(true);

    if (isCartaCorp(operadora.id, codigoPais, operadora.name)) {
      getCartaCorpProducts();
    } else {
      fetchDtOneProducts();
    }
  }, [codigoPais]);

  const debouncedValidation = useDebouncedCallback(
    () => validateNumber(),
    2000
  );

  async function validateNumber() {
    setLoading(true);
    const number = fields.celular;
    const payload = await lookup(number);

    if (payload.errors || payload.isAxiosError) {
      handlePhoneErrors();
      return;
    }

    const operator = payload.find(op => op.identified);
    if (!operator) {
      handlePhoneErrors();
      return;
    }

    const { id, name, country } = operator;
    const { iso_code } = country;

    setError(null);
    const values = { ...fields };
    values.operadora = { id, name };
    values.codigoPais = iso_code;
    setFields(values);
    setOperadora({ id, name });
    setCodigoPais(iso_code);
  }

  const handlePhoneErrors = () => {
    setError(currentTranslate.topUpBtoB.cellError);
    setLoading(false);
    setOperadora({});
    setCodigoPais('');
    return;
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const values = { ...fields };
    if (name === 'pais') {
      values.codigoPais = value;
      values[name] = COUNTRIES[value];
    } else {
      values[name] = value;
    }
    setFields(values);
  };

  const onSubmit = e => {
    e.preventDefault();
    fields.productos = paquetes;
    fields.celular = `+${fields.celular}`;
    fields.comprador = buyer;
    setInputs([...inputs, fields]);
    setFields({
      celular: '',
      nombre: '',
      pais: '',
      codigoPais: '',
      operadora: '',
      productos: [],
      active: true,
      comprador: '',
    });
    setOperadora({});
    setCodigoPais('');
  };

  return (
    <RecargasContainer>
      <Title>{currentTranslate.topUpBtoB.formTitle}</Title>
      <FormContainer>
        <div>
          <InputText
            placeholder={currentTranslate.topUpBtoB.name}
            id="nombre"
            name="nombre"
            onChange={handleChange}
            value={fields.nombre}
          />
          <InputNumber
            placeholder={currentTranslate.topUpBtoB.cellphone}
            id="celular"
            name="celular"
            value={fields.celular}
            onChange={handleChange}
          />
          <InputText
            placeholder={currentTranslate.topUpBtoB.country}
            id="pais"
            name="pais"
            value={COUNTRIES[codigoPais]?.name || ''}
            disabled
          />
          <InputText
            placeholder={currentTranslate.topUpBtoB.operator}
            id="operadora"
            name="operadora"
            value={operadora.name || ''}
            disabled
          />
          <Button
            onClick={e => onSubmit(e)}
            disabled={
              !(fields.celular && buyer && codigoPais && operadora.id && paquetes) ||
              error
            }
          >
            {currentTranslate.topUpBtoB.btnForm}
          </Button>
        </div>

        {loading ? (
          <LoadingContainer>
            <ReactLoading type="bubbles" color="#e2513a" />
          </LoadingContainer>
        ) : (
          error && (
            <ErrorContainer>
              <ErrorElement>{error}</ErrorElement>
            </ErrorContainer>
          )
        )}
      </FormContainer>
      <Title>{currentTranslate.topUpBtoB.title}</Title>
    </RecargasContainer>
  );
}
