import React, { useState, useContext } from 'react';
import ProductContext from 'context/ProductContext';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { createSaldo } from '../../../../Requets';
import { FaChevronLeft } from 'react-icons/fa';
import {
  GobackBtn,
  FormInput,
  FormBtn,
  ErrorElement,
  DatePickerWrapper,
  FormTextArea,
  FormSelect,
  Message,
} from '../../../../Inputs';
import {
  Header,
  Label,
  FormContainer,
  InputContainer,
  RowContainer,
  DatePickerContainer,
  Footer,
} from './style';
import 'react-datepicker/dist/react-datepicker.css';

export function GenerarCompra({ display }) {
  const { selectedShop, usuario } = useContext(ProductContext);
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const { control, register, handleSubmit, errors } = useForm();

  const onChange = dates => {
    setStartDate(dates);
  };

  const onSubmit = async data => {
    setLoading(true);
    let compraInformation = {
      amount: parseFloat(data.monto),
      balance: parseFloat(selectedShop.balance?.current) + parseFloat(data.monto),
      description: data.comentarios,
      payment: {
        reference: parseFloat(data.referencia),
        status: data.estado === 'true' ? 'completed' : 'pending',
        date: startDate,
      },
      type: 'efectivo',
      created_by: {
        admin: true,
        name: usuario.name,
      },
      shop: {
        name: selectedShop.name,
        id: selectedShop.uid,
        location: selectedShop.location.state,
      },
    };
    let response = await createSaldo(compraInformation);
    if (response.error) {
      setError(
        'Ocurrió un error, no pudo crearse la compra. Intenta de nuevo o contacta al equipo de Sendit a través de info.sendit.usa@gmail.com.'
      );
    } else {
      setMessage('Se ha generado una nueva compra exitosamente.');
    }
    setLoading(false);
  };
  return (
    <div>
      <Header>
        <GobackBtn onClick={() => display(false)}>
          <FaChevronLeft size="1.0em" />
          Volver
        </GobackBtn>
      </Header>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RowContainer>
            <InputContainer>
              <Label>Monto</Label>
              <Controller
                as={<FormInput width="227" placeholder="Monto" type="number" />}
                name="monto"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                }}
              />
              {errors.monto && <ErrorElement>*Campo Obligatorio</ErrorElement>}
            </InputContainer>
            <InputContainer>
              <Label>N° de referencia</Label>
              <Controller
                as={
                  <FormInput
                    width="227"
                    placeholder="N° de referencia"
                    type="number"
                  />
                }
                name="referencia"
                control={control}
                defaultValue=""
              />
            </InputContainer>
          </RowContainer>
          <InputContainer>
            <Label>Estado</Label>
            <FormSelect name="estado" ref={register()}>
              <option value={false}>Pendiente de pago</option>
              <option value={true}>Pagado</option>
            </FormSelect>
          </InputContainer>
          <InputContainer>
            <DatePickerContainer>
              <Label>Fechas</Label>
              <DatePickerWrapper>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  value={startDate}
                  showDisabledMonthNavigation
                />
              </DatePickerWrapper>
            </DatePickerContainer>
          </InputContainer>
          <InputContainer>
            <Label>Comentarios</Label>
            <Controller
              as={<FormTextArea width="489" placeholder="Comentarios" />}
              name="comentarios"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
            />
            {errors.comentarios && (
              <ErrorElement>*Campo Obligatorio</ErrorElement>
            )}
          </InputContainer>
          <Footer>
            <FormBtn disabled={loading}>Guardar</FormBtn>
          </Footer>
          {error && <ErrorElement>*Campo Obligatorio</ErrorElement>}
          {message && <Message>{message}</Message>}
        </form>
      </FormContainer>
    </div>
  );
}
