import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  padding: 30px;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 200px;
  background: ${props => (props.color ? props.color : ' #f3f3f3')};
  padding: 30px;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #656363;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-top: 24px;
    color: #656363;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #656363;
  background-color: #ffffff;
  padding: 10px 30px;

  display: grid;
  grid-template-columns: 130px 90%;
  align-items: baseline;
  > div {
    height: 1px;
    background-color: #c4c4c4;
    width: 80%;
  }
`;
