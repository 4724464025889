import React, { useState } from "react";
import { Timeline } from "./Timeline";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Success } from "./Success";
import { StepContainer } from "./style";
export function Recargas() {
  const [step, setStep] = useState(1);
  const [recargaInfo, setRecargaInfo] = useState({});
  const nextStep = (newStep, validateStep) => {
    if (validateStep) {
      newStep = newStep + 1;
      setStep(newStep);
    }
  };
  const prevStep = (newStep, validateStep) => {
    if (validateStep) {
      newStep = newStep - 1;
      setStep(newStep);
    }
  };
  return (
    <StepContainer>
      {/*
      <Timeline step={step} setStep={setStep} />
      {step === 1 && (
        <Step1 setStep={setStep} setRecargaInfo={setRecargaInfo} />
      )}
      {step === 2 && (
        <Step2
          setStep={setStep}
          setRecargaInfo={setRecargaInfo}
          recargaInfo={recargaInfo}
        />
      )}
      {step === 3 && <Step3 setStep={setStep} recargaInfo={recargaInfo} />}
      {step === 4 && <Success setStep={setStep} recargaInfo={recargaInfo} />}
      */}
      <h1>La seccion de regargas esta en mantenimeinto</h1>
    </StepContainer>
  );
}
