import styled from 'styled-components';

export const HeaderContainer = styled.header`
  @media (max-width: 800px) {
    position: sticky;
    z-index: 5;
    top: 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 0 0 0 20px;
  height: 60px;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  max-height: 60px;
  background: #ea6926;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  #hamburger-menu {
    margin: 11px;
    color: white;
    cursor: pointer;
    display: none;
  }
  #link {
    margin: auto 0 auto auto;
    height: 100%;
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      height: 100%;
      text-decoration: none;
      color: white;
      font-weight: bold;
      margin-right: 30px;
      padding: 0;
      cursor: pointer;
      &:hover {
        color: black;
      }
    }
  }
  .navbar {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    text-align: initial;
    position: absolute;
    top: 114px;
    z-index: 9;
    left: 0;
    background: #e2513a;
    width: 50%;
    height: 100vh;
    overflow: hidden;
    color: white;
    transform: translateX(-100%);
    /* inicialmente aparece oculto no usar display none */
    transition: transform 0.2s ease;
  }
  .showNav {
    /* este calse deberia mostrar el menu */
    transform: translateX(0);
    padding: 0 20px;
  }
  a {
    color: white;
    text-decoration: none;
    padding: 10px;
  }
  @media (max-width: 800px) {
    z-index: 5;
    position: sticky;
    padding-right: 0;
    top: 0;
    #link {
      display: none;
    }
    #hamburger-menu {
      display: initial;
    }
    form {
      margin: auto 0 auto auto;
    }
    a {
      order: 1;
    }
  }
`;

export const HeaderMobile = styled.div`
  border-top: 1px solid white;
  height: 54px;
  background: #e2513a;
  z-index: 4;
  position: sticky;
  top: 60px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;

export const Divider = styled.div`
  border-right: 1px solid white;
  margin-top: -10px;
  display: none;
  @media (max-width: 800px) {
    display: block;
    margin-left: 5px;
  }
`;

export const ProfilePic = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80');
`;

export const ProfileWrapper = styled.div`
  border-left: solid 1px white;
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 11px;
  a {
    margin-right: 5px !important;
  }
  @media (max-width: 800px) {
    height: auto;
    border-left: none;
    border-bottom: solid 1px white;
    padding: 0;
    a {
      padding: 0;
      &:first-child {
        margin: 12px 20px 12px 0 !important;
      }
    }
  }
`;
