export const CARTA_CORP_PRODUCT_LIST = {
  GTM: {
    id: 7,
    productTypes: [
      {
        type: 0,
        acronym: "Airtime",
        amounts: [7, 10, 12, 15],
      },
      {
        type: 4,
        acronym: "Paquetigo",
        amounts: [7, 10,12, 15, 20],
        descriptions: [
          "Vigencia: 5 días. ;\
          100 minutos de llamadas a EE.UU., Canadá y redes móviles  locales.;\
          100 SMS a EE.UU., Canadá y las redes móviles locales.;\
           3 GB de datos.;\
          Facebook & WhatsApp ilimitado.;\
          No aplica las promociones de tiempo aire adicionales.;\
          Entrega sólo navegación en internet para a Smartphones y Modems",
          "Vigencia: 7 días. ;\
          150 minutos de llamadas a EE.UU., Canadá y redes móviles locales.;\
          150 SMS a EE.UU., Canadá y las redes móviles locales.;\
          5 GB de datos.;\
          Facebook & WhatsApp ilimitado.;\
          No aplica las promociones de tiempo al aire adicional.;\
          Entregas sólo navegación en internet para Smarthphones  y Módem",
          "Vigencia: 10 días. ;\
          200 minutos de llamada a EE.UU., Canadá y redes móviles locales.;\
          6 GB de datos.;\
          Instagram, Facebook & WhatsApp ilimitado.;\
          No aplica las promociones de tiempo al aire adicional.;\
          Entregas sólo navegación en internet para Smarthphones  y Módem",
          "Vigencia: 10 días. ;\
          200 SMS a EE.UU., Canadá y las redes móviles locales.;\
        6 GB de datos.;\
        Instagram, Facebook & WhatsApp ilimitado.;\
        No aplica las promociones de tiempo aire adicionales.;\
        Entrega sólo navegación en internet para a Smartphones y Modems.",
          "Vigencia: 30 días. ;\
          300 minutos de llamada a EE.UU., Canadá y redes móviles locales.;\
        300 SMS a EE.UU., Canadá y las redes móviles locales.;\
        8 GB de datos.;\
        Facebook & WhatsApp ilimitado.;\
        No aplica las promociones de tiempo aire adicionales.;\
        Entrega sólo navegación en internet para a Smartphones y Modems."],
      },
      {
        type: 7,
        acronym: "Internet",
        amounts: [20,12, 30, 40],
        descriptions: ["Vigencia: 30 días;\
        10 GB Internet + Saldo Promocional 288 Minutos.;\
        Instagram, Facebook & WhatsApp ilimitado.",
        "Vigencia: 10 días;\
        7 GB Internet + Saldo Promocional 432 Minutos.;\
        Instagram, Facebook & WhatsApp ilimitado.",
          "Vigencia: 30 días;\
        18 GB Internet + Saldo Promocional 432 Minutos.;\
        Instagram, Facebook & WhatsApp ilimitado.",
          "Vigencia: 30 días;\
        30 GB Internet + Saldo Promocional 432 Minutos.;\
        Instagram, Facebook & WhatsApp ilimitado."],
      },
      {
        type: 8,
        acronym: "Antenita Tigo",
        amounts: [7, 6.98, 6.99, 15],
        descriptions: [
          "Vigencia: 30 días. ;\
        Fox Premium.",
          "Vigencia: 30 días. ;\
        HBO Premium",
          "Vigencia: 30 días. ;\
        Contenido de Adultos - Venus.",
          "Vigencia: 30 días. ;\
        Básico - 55 canales."],
      },
      {
        type: 10,
        acronym: "Tigo Sport",
        amounts: [5, 10],
        descriptions: ["Vigencia: 5 días. ;\
        Contenido Premium Canal Tigo Sport",
          "Vigencia: 30 días. ;\
        Contenido Premium Canal Tigo Sport"],
      },
    ],
  },
  SLV: {
    id: 9,
    productTypes: [
      {
        type: 0,
        acronym: "Airtime",
        amounts: [7, 10, 15, 20],
      },
      {
        type: 4,
        acronym: "Paquetigos Imparables",
        amounts: [7, 10, 12, 15, 20],
        descriptions: [
                    "Vigencia: 8 días;\
                    Minutos ilimitados EE.UU, Canadá & redes móviles locales;\
          50 minutos a todas las redes;\
          7GB de datos;\
          WhatsApp chat, Facebook, Instagram, Youtube & Twitter ilimitado\n\
          (no incluye video llamada o Facebook Messenger);\
          Balance de promo no aplica;\
          Entrega solo navegación en internet para Smartphones y Modem",
                    "Vigencia: 16 días;\
                    Minutos ilimitados EE.UU, Canadá & redes móviles locales;\
          100 minutos a todas las redes;\
          10 GB de datos;WhatsApp chat, Facebook, Instagram, Youtube & Twitter ilimitado\n\
          (no incluye video llamada o Facebook Messenger);\
          Balance de promo no aplica;\
          Entrega sólo navegación en internet para Smartphones y Modem",
          "Vigencia: 16 días;\
          Minutos ilimitados EE.UU, Canadá & redes móviles locales;\
          125 minutos a todas las redes;\
          15 GB de datos;\
          WhatsApp chat, Facebook, Instagram, Youtube & Twitter ilimitado\n\
          (no incluye video llamada o Facebook Messenger);\
          Balance de promo no aplica;\
          Entrega sólo navegación en internet para Smartphones y Modem.",
                    "Vigencia: 16 días;\
                    Minutos ilimitados EE.UU, Canadá & redes móviles locales;\
          125 minutos a todas las redes;\
          15 GB de datos;\
          WhatsApp chat, Facebook, Instagram, Youtube & Twitter ilimitado\n\
          (no incluye video llamada o Facebook Messenger);\
          Balance de promo no aplica;\
          Entrega sólo navegación en internet para Smartphones y Modem",
                    "Vigencia: 20 días;\
                    Minutos ilimitados EE.UU, Canadá & redes móviles locales;\
          150 minutos a todas las redes;\
          15 GB de datos;\
          WhatsApp chat, Facebook, Instagram, Youtube & Twitter ilimitado\n\
          (no incluye video llamada o Facebook Messenger);\
          Balance de promo no aplica;\
          Entrega sólo navegación en internet para Smartphones y Modem",
                    "Vigencia: 30 días;\
                    Minutos ilimitados EE.UU, Canadá & redes móviles locales;\
          200 minutos a todas las redes;\
          20 GB de datos;\
          WhatsApp chat, Facebook, Instagram, Youtube & Twitter ilimitado\n\
          (no incluye video llamada o Facebook Messenger);\
          Balance de promo no aplica;\
          Entrega sólo navegación en internet para Smartphones y Modem",
        ],
      },
    ],
  },
  HND: {
    id: 10,
    productTypes: [
      {
        type: 0,
        acronym: "Airtime",
        amounts: [7, 10, 12, 15, 20, 25, 30, 50],
      },
      {
        type: 9,
        acronym: "Super Recarga",
        amounts: [7, 8, 10, 15, 20],
        descriptions: [
          "Vigencia: 7 días.;\
          8 GB;\
        L300 saldo promocional.;\
        WhatsApp Chat y Facebook ilimitado.;\
        Minutos ilimitados para llamar a Tigo Honduras.;\
        100 minutos para llamar a USA.",
        "Vigencia: 7 días.;\
        8 GB Súper Recarga Gamer;\
        L300 saldo promocional.;\
        Free Fire, Clash of Clans Clash Royale, Candy Crush.;\
        WhatsApp Chat y Facebook ilimitado.;\
        Minutos ilimitados para llamar a Tigo Honduras.;\
        100 minutos para llamar a USA.",
          "Vigencia: 10 días.;\
          20GB;\
        L500 saldo promocional.;\
        WhatsApp chat y Facebook ilimitado.;\
        Minutos ilimitados para llamar a Tigo Honduras.;\
        300 minutos para llamar a USA y Canadá.",
          "Vigencia: 10 días.;\
          20GB;\
        L500 saldo promocional.;\
        WhatsApp chat y Facebook ilimitado.;\
        Minutos ilimitados para llamar a Tigo Honduras.;\
        300 minutos para llamar a USA y Canadá.",
        "Vigencia: 30 días.;\
        40GB;\
        L850 saldo promocional.;\
        WhatsApp chat y Facebook ilimitado.;\
        Minutos ilimitados para llamar a Tigo Honduras.;\
        500 minutos para llamar a USA y Canadá.",
        ],
      },
    ],
  },
  NIC: {
    id: 52,
    productTypes: [
      {
        type: 0,
        acronym: "Airtime",
        amounts: [7, 10, 15, 20, 25],
      },
      {
        type: 4,
        acronym: "Paquetigo",
        amounts: [7, 10, 12, 15, 20],
        descriptions: [
          "4GB;\
100 minutos para llamar a USA;\
Llamadas ilimitadas a Tigo Nicaragua;\
SMS Ilimitados;\
Ofrece gratis Facebook, Twitter, WhatsApp, Instagram;\
Vigencia: 7 days",
          "6GB;\
150 minutos para llamar a USA;\
Llamadas ilimitadas a Tigo Nicaragua;\
SMS Ilimitados;\
Ofrece gratis Facebook, Twitter, WhatsApp, Instagram;\
Expiración 10 días",
"Vigencia: 10 días;\
10GB;\
SMS Ilimitados;\
Ofrece gratis Facebook, Twitter, WhatsApp, Instagram.",
          "10GB;\
200 minutos para llamar a USA;\
SMS Ilimitados;\
Ofrece gratis Facebook, Twitter, WhatsApp, Instagram;\
Expiration 15 días",
"Vigencia: 30 días;\
14GB;\
300 minutos para llamar a USA;\
Llamadas ilimitadas a Tigo Nicaragua;\
SMS Ilimitados;\
Ofrece gratis Facebook, Twitter, WhatsApp, Instagram.",
        ],
      },
    ],
  },
};
