import styled from 'styled-components';

export const TableTh = styled.th`
  width: ${props => (props.long ? '200px' : '125px')};
  background: #f3f3f3;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  color: #656363;
  height: 61px;
  padding: 10px;
`;

export const TableTd = styled.td`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #c4c4c4;
  vertical-align: middle;
  height: 60px;
  color: ${props => (props.color ? props.color : '#656363')};
  white-space: break-spaces;
  padding: 10px;
  img {
    cursor: pointer;
  }
`;

export const BtnPagar = styled.button`
  width: 106px;
  height: 42px;
  background: linear-gradient(269.91deg, #7a27f0 0%, #cb01d7 99.96%);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  img {
    padding-right: 5px;
  }
  span {
    vertical-align: middle;
  }
  &:disabled {
    border-color: #999;
    cursor: not-allowed;
    color: #999;
    background: #f3f3f3;
  }
`;

export const SaldoContainer = styled.div`
  width: 264px;
  height: 124px;
  background: #6fc7da;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  p {
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #322665;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-wrap: wrap;
    table {
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    overflow: scroll;
  }
`;

export const Table = styled.table`
  width: 70%;
`;
