import React, { useEffect, useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ProductContext from 'context/ProductContext';
import axios from 'axios';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import { Button } from '../Button';
import { ErrorElement } from '../Recargas/error';
import {
  SaldoContainer,
  FormContainer,
  FormHeader,
  FormLabel,
  InputText,
  InputContainer,
  InputFlex,
  ComprarSaldoFormContainer,
  FormInputContainer,
  CardInfoContainer
} from './style';
import { createSaldo } from '../AdminPanel/Requets';
import { Message } from '../AdminPanel/Inputs';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#322665',
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#322665',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const url = axios.create({
  baseURL:
    'https://us-central1-sendit-shopify-app.cloudfunctions.net/topups/',
});

export function ComprarSaldoForm() {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [cardError, setCardError] = useState(null);
  const [emptyCard, setEmptyCard] = useState(true);
  const [emptyExpiry, setEmptyExpiry] = useState(true);
  const [emptyCvc, setEmptyCvc] = useState(true);
  const [processing, setProcessing] = useState('');
  
  const { usuario, setUsuario } = useContext(ProductContext);

  const stripe = useStripe();
  const elements = useElements();

  const { control, handleSubmit, errors } = useForm();

  const createIntent = (amount) => {
    let result = url
      .post(`/topup_intent`, {
        amount,
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error;
      });

    return result;
  };

  const handleCard = event => {
    setEmptyCard(event.empty || event.error);
    setCardError(event.error ? event.error.message : '');
  };

  const handleEpiry = event => {
    setEmptyExpiry(event.empty || event.error);
    setCardError(event.error ? event.error.message : '');
  };

  const handleCvc = event => {
    setEmptyCvc(event.empty || event.error);
    setCardError(event.error ? event.error.message : '');
  };

  const updateBalance = (current) => {
    const newUserData = {
      ...usuario,
      balance: {
        current,
        minumim: usuario.balance.minimum
      }
    }
    setUsuario(newUserData);
  }

  const onSubmit = async data => {
    setError(null);
    setCardError(null);
    setProcessing(true);

    const { name, amount } = data;
    let intent = await createIntent(amount*100);

    if (!stripe || !elements || !intent.clientSecret) {
      setProcessing(false);
      return;
    }

    const purchaseData = {
      amount: parseFloat(data.amount),
      balance: parseFloat(usuario.balance?.current) + parseFloat(data.amount),
      description: 'Compra realizada con TDC.',
      payment: {
        reference: '',
        status: 'completed',
        date: new Date(),
      },
      type: 'tdc',
      created_by: {
        admin: false,
        name: usuario.name,
      },
      shop: {
        name: usuario.name,
        id: usuario.uid,
        location: usuario.location.state,
      },
    };

    const payload = await stripe.confirmCardPayment(intent.clientSecret, {
      receipt_email: usuario.email,
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: name,
        },
      },
    });

    if (payload.error) {
      setError(`El pago falló, por favor verifica e inténtalo de nuevo`);
      setProcessing(false);
    } else {
      setError(null);
      setSucceeded(true);
      purchaseData.payment.reference = payload.paymentIntent.id;
      await createSaldo(purchaseData);
      setMessage('Se ha realizado la compra exitosamente.');
      updateBalance(parseFloat(purchaseData.amount) + parseFloat(usuario?.balance.current));
    }
  };

  return (
    <ComprarSaldoFormContainer>
      <SaldoContainer>
        <p>Saldo Actual</p>
        <p>${parseInt(usuario?.balance?.current).toFixed(2)}</p>
      </SaldoContainer>
      <FormContainer>
        <FormHeader>Compra de Saldo</FormHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <FormInputContainer>
              {errors.name && (
                <ErrorElement>
                  Ingresa el nombre del titular de la tarjeta
                </ErrorElement>
              )}
              <FormLabel>Nombre del titular</FormLabel>
              <Controller
                as={<InputText placeholder="Nombre que aparece en la tarjeta" />}
                name="name"
                id="name"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </FormInputContainer>
            <CardInfoContainer>
              <FormInputContainer>
                <FormLabel>Número de tarjeta</FormLabel>
                {cardError && (
                  <ErrorElement>Por favor ingresa una tarjeta válida</ErrorElement>
                )}
                <CardNumberElement
                  options={{
                    ...CARD_ELEMENT_OPTIONS, placeholder: '' }}
                  onChange={handleCard}
                />
              </FormInputContainer>
              <FormInputContainer width={'133px'}>
                <FormLabel>CVV</FormLabel>
                <CardCvcElement
                  options={{ ...CARD_ELEMENT_OPTIONS, placeholder: '' }}
                  onChange={handleCvc}
                />
              </FormInputContainer>
              <FormInputContainer>
                <FormLabel>Vencimiento</FormLabel>
                <CardExpiryElement
                  options={{ ...CARD_ELEMENT_OPTIONS, placeholder: '' }}
                  onChange={handleEpiry}
                />
              </FormInputContainer>
            </CardInfoContainer>

            <FormInputContainer width={'100%'}>
              {errors.amount && (
                <ErrorElement>
                  Ingresa el monto a recargar
                </ErrorElement>
              )}
              <FormLabel>Monto a Recargar</FormLabel>
              <InputFlex>
                <Controller
                  as={<InputText type={'number'} width={'247px'} />}
                  name="amount"
                  id="amount"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
                <Button
                  type="submit"
                  disabled={
                    !stripe ||
                    processing ||
                    emptyCard ||
                    emptyExpiry ||
                    emptyCvc
                    // succeeded
                  }
                >
                  Continuar
                </Button>
              </InputFlex>
              {<Message>{message}</Message>}
            </FormInputContainer>
          </InputContainer>
        </form>
      </FormContainer>
    </ComprarSaldoFormContainer>
  );
}
