import React, { useState } from 'react';
import firebase from '@firebase/app';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { navigate } from '@reach/router';
import {
  MenuWrapper,
  MenuContainer,
  MenuOptions,
  LogoContainer,
  Option,
  Divider,
  ArrowContainer,
  OptionContent,
} from './style';

export function LeftMenu({
  setPage,
  setInnerPage,
  selected,
  SetSelected,
  subMenuSelected,
  setSubMenuSelected,
  setCreatedShop,
}) {
  const [arrow, SetArrow] = useState(false);
  let arrowIcon = arrow ? 'menuLeft' : 'menuRight';
  const data = useStaticQuery(
    graphql`
      {
        logo: file(relativePath: { eq: "logo2v.png" }) {
          childImageSharp {
            fixed(height: 56, width: 56) {
              ...GatsbyImageSharpFixed
            }
          }
        }

        closeIcon: file(relativePath: { eq: "close-icon.png" }) {
          childImageSharp {
            fixed(height: 35, width: 32) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        settingsIcon: file(relativePath: { eq: "settings-icon.png" }) {
          childImageSharp {
            fixed(height: 35, width: 37) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        storeIcon: file(relativePath: { eq: "store-icon.png" }) {
          childImageSharp {
            fixed(height: 35, width: 40) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        historyIcon: file(relativePath: { eq: "history-icon.png" }) {
          childImageSharp {
            fixed(height: 35, width: 35) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        menuRight: file(relativePath: { eq: "menuRight.png" }) {
          childImageSharp {
            fixed(height: 19, width: 11) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        menuLeft: file(relativePath: { eq: "menuLeft.png" }) {
          childImageSharp {
            fixed(height: 19, width: 11) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        movilIcon: file(relativePath: { eq: "movilIcon.png" }) {
          childImageSharp {
            fixed(height: 31, width: 20) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        compraIcon: file(relativePath: { eq: "compraIcon.png" }) {
          childImageSharp {
            fixed(height: 29, width: 25) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        perfilIcon: file(relativePath: { eq: "cart.png" }) {
          childImageSharp {
            fixed(height: 29, width: 29) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const changeOption = (title, option, innerPage, flagFunction) => {
    if (flagFunction) {
      if (option === 1) {
        logOut();
      } else {
        navigate('/perfil');
      }
    }
    SetSelected(option);
    setPage(title);
    setInnerPage(innerPage);
    setCreatedShop(false);
  };

  const logOut = () => {
    firebase.auth().signOut();
    localStorage.clear();
  };

  const changeSubMenu = (title, option, innerPage) => {
    setSubMenuSelected(option);
    setPage(title);
    setInnerPage(innerPage);
  };

  let options = [
    {
      id: 0,
      img: data.perfilIcon?.childImageSharp.fixed,
      title: 'Perfil Tienda',
      innerPage: false,
      function: true,
    },
    {
      id: 1,
      img: data.closeIcon?.childImageSharp.fixed,
      title: 'Log Out',
      innerPage: false,
      function: true,
    },
    {
      id: 2,
      img: data.settingsIcon?.childImageSharp.fixed,
      title: 'CAMBIAR CONTRASEÑA',
      innerPage: true,
      function: false,
    },
    {
      id: 3,
      img: data.storeIcon?.childImageSharp.fixed,
      title: 'TIENDAS',
      innerPage: false,
      function: false,
    },
    {
      id: 4,
      img: data.historyIcon?.childImageSharp.fixed,
      title: 'HISTORIAL',
      innerPage: true,
      function: false,
      subMenu: [
        {
          id: 1,
          img: data.movilIcon?.childImageSharp.fixed,
          title: 'HISTORIAL DE RECARGAS',
          innerPage: true,
          function: false,
        },
        {
          id: 2,
          img: data.compraIcon?.childImageSharp.fixed,
          title: 'HISTORIAL DE CRÉDITO',
          innerPage: true,
          function: false,
        },
      ],
    },
  ];

  return (
    <MenuWrapper allMenu={arrow}>
      <ArrowContainer
        allMenu={arrow}
        onClick={() => {
          SetArrow(!arrow);
        }}
      >
        <Img fixed={data[arrowIcon]?.childImageSharp.fixed} loading="auto" />
      </ArrowContainer>
      <MenuContainer allMenu={arrow}>
        <LogoContainer allMenu={arrow}>
          <Img fixed={data.logo?.childImageSharp.fixed} loading="auto" />
        </LogoContainer>
        <MenuOptions>
          {options.map(value => (
            <Option
              key={value.id}
              selected={selected === value.id ? true : false}
              onClick={() =>
                changeOption(
                  value.title,
                  value.id,
                  value.innerPage,
                  value.function
                )
              }
              allMenu={arrow}
            >
              <OptionContent allMenu={arrow}>
                <Img fixed={value.img} loading="auto" />
                <p>{value.title}</p>
              </OptionContent>
            </Option>
          ))}
          {options.map(value => {
            if (selected === value.id) {
              return value?.subMenu?.map(menu => (
                <Option
                  key={menu.id}
                  subMenuSelected={subMenuSelected === menu.id ? true : false}
                  onClick={() =>
                    changeSubMenu(menu.title, menu.id, menu.innerPage)
                  }
                  allMenu={arrow}
                >
                  <OptionContent allMenu={arrow}>
                    <Img fixed={menu.img} loading="auto" />
                    <p>{menu.title}</p>
                  </OptionContent>
                </Option>
              ));
            }
          })}
        </MenuOptions>
        <Divider />
      </MenuContainer>
    </MenuWrapper>
  );
}
