import React, { useState } from 'react';
import { Button } from '../Button';
import { navigate } from '@reach/router';

import { StyleLink } from '../StyleLink';
import {
  CollectionTitleWrapper,
  CollectionTitleContent,
  CollectionPromoTitleWrapper,
  PromoTitleContent
} from './style';

export function CollectionTitle({ title, image, destination, hoverImage, double }) {
  if (image) {
    return (
      <CollectionTitleWrapper onClick={() => navigate(destination)}>
        <CollectionTitleContent>
          <img src={image} alt="category" />
          <div>
            <StyleLink to={destination}>
              <Button>{title}</Button>
            </StyleLink>
          </div>
        </CollectionTitleContent>
      </CollectionTitleWrapper>
    );  
  } else {
    return (
      <CollectionTitleWrapper onClick={() => navigate(destination)}>
        <PromoTitleContent>
          <div>
            <StyleLink to={destination}>
              <Button>{title}</Button>
            </StyleLink>
          </div>
        </PromoTitleContent>
      </CollectionTitleWrapper>
    )
  }
}
