import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "@reach/router";
import { FirestoreCollection } from "@react-firebase/firestore";

import Img from "gatsby-image";
import {
  SumaryContainer,
  InfoWrapper,
  Title,
  SubTitle,
  TitleToAction,
  TextContainer,
} from "./style";
import ProductContext from "context/ProductContext";
import { getUsedCredit } from "../../Recargas/requests";

export function Sumary() {
  const data = useStaticQuery(
    graphql`
      {
        Recargas: file(relativePath: { eq: "Recargas.png" }) {
          childImageSharp {
            fixed(height: 66) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        Recargasmovil: file(relativePath: { eq: "Recargasmovil.png" }) {
          childImageSharp {
            fixed(height: 66) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        Recargastiempo: file(relativePath: { eq: "Recargastiempo.png" }) {
          childImageSharp {
            fixed(height: 66) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        Recargasaldo: file(relativePath: { eq: "Recargasaldo.png" }) {
          childImageSharp {
            fixed(height: 66) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  );

  const { usuario, setUsuario, currentTranslate } = useContext(ProductContext);
  const [creditSpent, setCreditSpent] = useState(0);

  useEffect(() => {
    async function getCreditSpent(id) {
      const credit = await getUsedCredit(id);
      setCreditSpent(parseFloat(credit));
      const newUserData = {
        ...usuario,
        balance: {
          ...usuario.balance,
          spent: parseFloat(credit) || 0,
        },
      };
      setUsuario(newUserData);
    }

    getCreditSpent(usuario.uid);
  }, []);

  return (
    <SumaryContainer>
      <InfoWrapper>
        <FirestoreCollection
          path="/credits"
          where={{
            field: "shop.id",
            operator: "==",
            value: usuario.uid,
          }}
          // orderBy={{
          //   field: 'created_at'
          // }}
        >
          {(result) => {
            let mainData = result?.value;
            let totalCredit = 0;
            let totalCount = 0;

            if (mainData) {
              // order by date
              mainData.sort(
                (a, b) => b.created_at.toDate() - a.created_at.toDate()
              );
              mainData.forEach((v) => (totalCredit += parseFloat(v.amount)));
            }

            totalCount = mainData?.length;

            return result.isLoading ? (
              // <Loading />
              <></>
            ) : (
              <>
                <div>
                  <TextContainer>
                    <Img
                      fixed={data.Recargasaldo.childImageSharp.fixed}
                      loading="auto"
                    />
                    <div>
                      <Title>
                        ${parseFloat(usuario?.balance?.current || 0).toFixed(2)}
                      </Title>
                      <SubTitle>
                        {currentTranslate.topUpBtoB.currentBalance}
                      </SubTitle>
                    </div>
                    <TitleToAction onClick={() => navigate("/compraSaldo")}>
                      {currentTranslate.topUpBtoB.butBalance}
                    </TitleToAction>
                  </TextContainer>
                </div>
                <div>
                  <TextContainer>
                    <Img
                      fixed={data.Recargastiempo.childImageSharp.fixed}
                      loading="auto"
                    />
                    <Title>${totalCredit.toFixed(2)}</Title>
                    <SubTitle>
                      {currentTranslate.topUpBtoB.totalBalanceReloaded} <br />
                    </SubTitle>
                  </TextContainer>
                </div>
                <div>
                  <TextContainer>
                    <Img
                      fixed={data.Recargas.childImageSharp.fixed}
                      loading="auto"
                    />
                    <Title>{totalCount}</Title>
                    <SubTitle>
                      {currentTranslate.topUpBtoB.totalAmountofRefills} <br />
                    </SubTitle>
                  </TextContainer>
                </div>
                <div>
                  <TextContainer>
                    <Img
                      fixed={data.Recargasmovil.childImageSharp.fixed}
                      loading="auto"
                    />
                    <Title>${usuario?.balance?.spent?.toFixed(2)}</Title>
                    <SubTitle>
                      {currentTranslate.topUpBtoB.usedBalance} <br />
                    </SubTitle>
                  </TextContainer>
                </div>
              </>
            );
          }}
        </FirestoreCollection>
      </InfoWrapper>
    </SumaryContainer>
  );
}
