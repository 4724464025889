import styled, { css } from 'styled-components';

const formStyleMobile = ({ mobile }) => {
  if (mobile) {
    return css`
      justify-content: center;
      margin-left: 0px;
      input {
        width: 90%;
        height: 37px;
        margin-top: 6px;
        background: #ffffff;
        border-radius: 5px;
      }
    `;
  }
};

export const SearchForm = styled.form`
  display: flex;
  margin-left: 15px;
  height: 100%;

  input {
    height: 100%;
    width: 129px;

    border-radius: 5px;
    ::placeholder {
      color: #e2513a;
      opacity: 1; /* Firefox */
      font-weight: bold;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e2513a;
      color: rgba(183, 63, 127, 0.7);
      font-weight: bold;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #e2513a;
      font-weight: bold;
      color: rgba(183, 63, 127, 0.7);
    }
  }

  @media (max-width: 800px) {
    #desktop-search {
      display: none;
    }
  }
  ${formStyleMobile}
`;
