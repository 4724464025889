import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  padding: 30px;
  border-bottom: 1px solid #c4c4c4;
`;

export const Content = styled.div`
  padding: 60px 0px 60px 0px;
  background-color: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  form {
    width: 649px;
  }
`;

export const FormContainer = styled.div`
  grid-template-columns: 324px 324px;
  display: grid;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #656363;
`;

export const RowTemplate = styled.div`
  display: grid;
  grid-template-rows: 120px 120px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;
