import styled from 'styled-components';

export const VendedoresContainer = styled.div`
  width: 100%;
  > div:first-child {
    height: calc(100vh - 168px);
  }
`;

export const BtnWrapper = styled.div`
  padding: 30px;
  display: flex;
  justify-content: space-between;
  div {
    form {
      button {
        margin-left: 20px;
      }
    }
  }
`;

export const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  height: 30px;
  width: 100%;
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
`;
