import styled from 'styled-components';
export const CheckBoxWrapper = styled.div`
  position: relative;
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => (props.width ? props.width : '42px')};
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: ${props => (props.width ? props.width : '42px')};
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: ${props => (props.color ? props.color : '#e2513a')};
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: ${props => (props.marginLeft ? props.marginLeft : '21px')};
      transition: 0.2s;
    }
  }
`;
