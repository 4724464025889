import React from "react";
import { Header } from "../Header";
import { WaButton } from "../WaButton";
import { LayoutWrapper } from "./styles";
import { Footer, GotoTop } from "components";

const Layout = ({ children, home, cart }) => {
  return (
    <>
      <Header />
      <LayoutWrapper home={home} cart={cart}>
        <main>{children}</main>
      </LayoutWrapper>
      <GotoTop />
      <WaButton />
      <Footer />
    </>
  );
};

export { Layout };
