import styled, { css } from 'styled-components';

const inputStyle = () => {
  return css`
    width: 171px;
    height: 51px;
    background: #f3f3f3;
    border-radius: 5px;
    font-weight: bold;
    color: #656363;
    padding-left: 10px;
    border: none;
    ::placeholder {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #656363;
      padding-left: 10px;
    }
  `;
};

export const SelectWrapper = styled.select`
  ${inputStyle}
`;

export const RecargasSumaryWrapper = styled.div`
  background: #f4fdff;
  width: 100%;
  max-width: 1122px;
  margin: 0 auto;
  @media (max-width: 800px) {
    padding: 30px;
    background: #ffffff;
  }
`;
export const MainTable = styled.table`
  width: 100%;

  tr {
    border: 1.5px solid #e5e5e5;
  }

  @media (max-width: 800px) {
    td:last-child {
      border-right: none;
    }
    td:nth-of-type(7) {
      border-right: none;
    }
    td:nth-of-type(5) {
      height: 90px;
      width: 100%;
      border-right: none;
    }
    td:nth-of-type(6) {
      height: 90px;
      width: 100%;
      display: flex;
    }

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
      justify-content: center;
    }

    tr {
      margin: 0 0 1rem 0;
      display: grid;
      grid-template-columns: 50% 50%;
      border-radius: 5px;
    }

    td {
      border: none;
      border-bottom: 1px solid #eee;
      text-align: center;
      padding: 20px;
      position: relative;
      align-items: center;
      background: #f4fdff;
      border-right: 1px solid #eee;
      select {
        width: 100%;
        text-align-last: center;
        margin: 0 auto;
        text-align: center;
        background: #f4fdff;
        font-size: 14px;
      }
    }

    td:before {
      position: absolute;

      top: 0;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }
  }
`;

export const TableTd = styled.td`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  height: 60px;
  color: #656363;
  vertical-align: middle;
  padding-left: 20px;
  width: ${props => (props.width ? props.width : 'auto')};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 60px 30px 100px 0px;
  flex-wrap: wrap;

  > div:first-child {
    display: flex;
    align-items: center;
  }

  > div:last-child {
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }
  @media (max-width: 500px) {
    > div:first-child {
      flex-direction: column;
    }
    padding: 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }
`;

export const TotalTitle = styled.p`
  font-weight: bold;
  font-size: 26px;
  line-height: 30px;
  color: #322665;
  margin-right: 30px;

  @media (max-width: 500px) {
    margin-bottom: 30px;
  }
`;

export const MobileDeleteWrapper = styled.div`
  display: none;

  justify-content: space-between;
  color: #5c1565;
  padding: 30px;
  padding: 0px 0px 20px 0px;
  align-content: baseline;
  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-left: 5px;
  }
  @media (max-width: 800px) {
    display: flex;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const InfoContainer = styled.div`
  width: 52px;
  height: 51px;
  background: #f3f3f3;
  box-sizing: border-box;
  border-radius: 0px 5px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    background: none;
  }
`;

export const TooltipContent = styled.div`
  padding: 10px;
  text-align: initial;
  li {
    white-space: pre;
  }
`;
