import React, { useState, useContext } from 'react';
import ProductContext from 'context/ProductContext';
import { PagarSaldoForm, PagarSaldoEfectivoForm } from 'components';
import { TabsContainer, TabLabel } from './style';

export function PagarSaldoContainer() {
  const { selectedCredit } = useContext(ProductContext);
  const { mainTab, mode = false } = selectedCredit;
  const [tab, setTab] = useState(mainTab || 1);
  return (
    <div>
      <TabsContainer>
        {!mode && (
          <TabLabel onClick={() => setTab(1)} active={tab === 1 ? true : false}>
            Pago con TDC
          </TabLabel>
        )}
        <TabLabel onClick={() => setTab(2)} active={tab === 2 ? true : false}>
          Pago en Efectivo
        </TabLabel>
      </TabsContainer>
      {tab === 1 ? <PagarSaldoForm /> : <PagarSaldoEfectivoForm />}
    </div>
  );
}
